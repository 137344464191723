import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    doppeExternalEventProviderResolveBusinessEntityReference,
    doppeHideableValueCreateHidden,
    DoppeListActionSettings
} from '../../client-server-common';
import {
    convertDoppeWixEventToEventSettings,
    DoppeActionTypeEvent
} from '../doppe-action-type-event/doppe-action-type-event';
import {DoppeDtoEvent} from '../../client-server-common/types/doppe-dto-event';
import {
    DoppeEventsExternalDataSource,
    doppeEventsExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-events-external-data-source';


export namespace DoppeActionTypeEvents {

    export interface EventSettings extends DoppeActionTypeEvent.ActionSettings {
        id: string;
        eventTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeEventsExternalDataSource> {
        events: EventSettings[];
        itemShowDate: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowCoupon: boolean;
        previewShowPrice: boolean;
        previewEventButtonText: string;
    }
}


export const doppeActionTypeEvents = doppeActionTypeDefineMetadata<DoppeActionTypeEvents.ActionSettings>({
    id: 'io.bymo.action/events',
    resolveActionSettings: action => {

        const {
            events = [],
            ...restSettings
        } = action.settings;

        return {
            events: events.map(event => {
                return {
                    eventProviderId: null,
                    eventProvider: null,
                    ribbon: doppeHideableValueCreateHidden('SALE'),
                    ...event as PartiallyOptional<DoppeActionTypeEvents.EventSettings, 'eventProvider' | 'eventProviderId' | 'ribbon'>
                }
            }),
            layout: DoppeActionLayout.Grid,
            showButton: false,
            buttonText: 'See all events',
            buttonUrl: 'https://www.website.com',
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
            externalDataSource: null,
            externalDataSourceItemsCount: 4,
            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowDate: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewEventButtonText: 'RSVP',
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : actionSettings.events.length;
    },
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.events.map(event => {
            return event.eventImages ?? []
        }));
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeEventsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.events, (event, skip) => {
                return event.eventProvider !== null && event.eventProviderId !== null ?
                    doppeExternalEventProviderResolveBusinessEntityReference(
                        event.eventProvider,
                        event.eventProviderId
                    ) : skip
            });
        }
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const event of actionSettings.events) {
                if (event.eventImages) {
                    event.eventImages = event.eventImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
});

export function convertDoppeWixEventToEventsSettings(
    event: DoppeDtoEvent,
    settings?: {
        eventButtonText?: string,
    }
): DoppeActionTypeEvents.EventSettings {
    return {
        ...convertDoppeWixEventToEventSettings(event),
        id: event.id,
        couponCode: doppeHideableValueCreateHidden('CouponCode'),
        ribbon: doppeHideableValueCreateHidden('SALE'),
        eventButtonText: settings?.eventButtonText ?? 'RSVP',
    }
}