import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    immerProduce,
    iterableMapToArray,
    Nullable,
    PartiallyOptional,
    uuidCreate
} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    DoppeDtoWebPageMetadata,
    DoppeDtoWebPageMetadataType,
    doppeExternalMembershipPlanProviderResolveBusinessEntityReference,
    doppeHideableValueCreateHidden,
    DoppeListActionSettings
} from '../../client-server-common';
import {
    convertDoppeWixMembershipPlanToPlanSettings,
    DoppeActionTypeMembership,
    doppeActionTypeMembershipResolveDefaultSettingByType,
    doppeActionTypeMembershipResolveTypeFromUrl
} from '../doppe-action-type-membership/doppe-action-type-membership';
import {DoppeDtoMembershipPlan} from '../../client-server-common/types/doppe-dto-membership-plan';
import {
    DoppeMembershipPlansExternalDataSource,
    doppeMembershipPlansExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-membership-plans-external-data-source';


export namespace DoppeActionTypeMembershipList {

    export interface PlanSettings extends DoppeActionTypeMembership.ActionSettings {
        id: string;
        membershipTitle: string;
    }

    export interface ActionSettings extends DoppeListActionSettings,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeMembershipPlansExternalDataSource> {
        plans: PlanSettings[];
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowCoupon: boolean;
        previewPlanButtonText: string;
    }
}


export const doppeActionTypeMembershipList = doppeActionTypeDefineMetadata<DoppeActionTypeMembershipList.ActionSettings>({
    id: 'io.bymo.action/membership-list',
    resolveActionSettings: action => {

        const {
            plans = [],
            ...restSettings
        } = action.settings;

        return {
            plans: plans.map(plan => {
                return {
                    membershipProvider: null,
                    membershipProviderId: null,
                    ...plan as PartiallyOptional<DoppeActionTypeMembershipList.PlanSettings, 'membershipProvider' | 'membershipProviderId'>
                }
            }),
            layout: DoppeActionLayout.Swiper,
            showButton: false,
            buttonText: 'Visit My Website',
            buttonUrl: 'https://www.website.com',
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,

            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowPrice: true,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewPlanButtonText: 'Subscribe Now',

            externalDataSource: null,
            externalDataSourceItemsCount: 4,

            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.plans.map(plan => {
            return plan.membershipImages ?? []
        }));
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeMembershipPlansExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.plans, (plan, skip) => {
                return plan.membershipProvider !== null && plan.membershipProviderId !== null ?
                    doppeExternalMembershipPlanProviderResolveBusinessEntityReference(
                        plan.membershipProvider,
                        plan.membershipProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : actionSettings.plans.length;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const plan of actionSettings.plans) {
                if (plan.membershipImages) {
                    plan.membershipImages = plan.membershipImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
});

export function doppeActionTypeMembershipPlansGetDefaultLinkSettings(
    url: string,
    webPageMetadata: Nullable<DoppeDtoWebPageMetadata.ByType<typeof DoppeDtoWebPageMetadataType.Service>>
): DoppeActionTypeMembershipList.PlanSettings {
    const metadata = webPageMetadata?.metadata

    const membershipType = doppeActionTypeMembershipResolveTypeFromUrl(url);
    const membershipDetails = doppeActionTypeMembershipResolveDefaultSettingByType(DoppeActionTypeMembership.MembershipType.Other);

    return {
        id: uuidCreate(),
        membershipImages: metadata?.imageUrl ? [metadata.imageUrl] : null,
        membershipPageLink: url,
        linkButtonText: doppeHideableValueCreateHidden(membershipDetails.linkButtonText ?? 'Subscribe Now'),
        priceData: metadata?.displayPrice ? {
            actualPrice: metadata.displayPrice
        } : doppeHideableValueCreateHidden({
            actualPrice: '',
            discountPrice: ''
        }),
        membershipTitle: metadata?.name ?? membershipDetails.title,
        membershipDescription: metadata?.description ?? doppeHideableValueCreateHidden(''),
        couponCode: doppeHideableValueCreateHidden('CouponCode'),
        ribbon: doppeHideableValueCreateHidden('SALE'),
        membershipBenefits: membershipDetails.membershipBenefits,
        membershipProvider: null,
        membershipProviderId: null,
        membershipType: membershipType,
    }
}

export function convertDoppeWixMembershipPlanToPlansSettings(
    plan: DoppeDtoMembershipPlan,
    websiteUrl?: string,
): DoppeActionTypeMembershipList.PlanSettings {
    return {
        ...convertDoppeWixMembershipPlanToPlanSettings(plan, websiteUrl),
        id: plan.id,
        membershipTitle: plan?.name ?? 'Membership name',
        linkButtonText: doppeHideableValueCreateHidden('Subscribe Now'),
    }
}