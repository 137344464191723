import {useEffect, useMemo} from 'react';
import {DynamicRef, FloatingWindowOpener} from '@wix/devzai-utils-react';
import {assertNotNullable, EventEmitter} from '@wix/devzai-utils-common';
import {DoppeViewerAppClientServiceBase} from './doppe-viewer-app-client-service-base';
import {useDoppeViewerClientServices} from '../doppe-user-app/doppe-viewer-client-services';
import {
    DoppeViewerAutoRedirectSettings
} from '../../components/doppe-viewer-auto-redirect-settings/doppe-viewer-auto-redirect-settings';
import {
    DoppeViewerDefaultNotificationSettings
} from '../../components/doppe-viewer-default-notification-settings/doppe-viewer-default-notification-settings';
import {DoppeViewerActionsList} from "../../components/doppe-viewer-actions-list/doppe-viewer-actions-list";

export interface DoppeViewerAppUiServiceComponents {
    autoRedirectNotificationOpener: FloatingWindowOpener<DoppeViewerAutoRedirectSettings>;
    defaultNotificationOpener: FloatingWindowOpener<DoppeViewerDefaultNotificationSettings>;
    pageActionsList: DoppeViewerActionsList;
}

export class DoppeViewerAppUiService extends DoppeViewerAppClientServiceBase {

    private componentsInstances: Partial<DoppeViewerAppUiServiceComponents> = {};

    public setComponentInstance<COMPONENT extends keyof DoppeViewerAppUiServiceComponents>(
        componentName: COMPONENT,
        component: DoppeViewerAppUiServiceComponents[COMPONENT] | null
    ) {
        this.componentsInstances[componentName] = component ?? undefined;
    }

    public getComponentInstance<COMPONENT extends keyof DoppeViewerAppUiServiceComponents>(
        componentName: COMPONENT
    ) {
        return this.componentsInstances[componentName];
    }

    public assertComponentInstance<COMPONENT extends keyof DoppeViewerAppUiServiceComponents>(
        componentName: COMPONENT
    ) {
        return assertNotNullable(this.componentsInstances[componentName] as DoppeViewerAppUiServiceComponents[COMPONENT] | undefined, `Component '${componentName}' isn't registered`);
    }

    public get autoRedirectNotificationOpener() {
        return this.assertComponentInstance('autoRedirectNotificationOpener')
    }

    public get defaultNotificationOpener() {
        return this.assertComponentInstance('defaultNotificationOpener')
    }

    public get pageActionsList () {
        return this.assertComponentInstance('pageActionsList')
    }
}

export function useDoppeViewerAppUiServiceComponentRef<COMPONENT extends keyof DoppeViewerAppUiServiceComponents>(
    componentName: COMPONENT
) {

    const clientServices = useDoppeViewerClientServices();

    const instanceData = useMemo(() => {

        const instanceDynamicRef = DynamicRef.create<DoppeViewerAppUiServiceComponents[COMPONENT]>();
        const dynamicRefChangedEventBinding = EventEmitter.on(instanceDynamicRef.eventChanged, (componentInstance) => {
            clientServices.uiService.setComponentInstance(componentName, componentInstance);
        })

        return {
            instanceDynamicRef: instanceDynamicRef,
            destructor: () => {
                dynamicRefChangedEventBinding.dispose();
                clientServices.uiService.setComponentInstance(componentName, null);
            }
        }
    }, [clientServices, componentName])

    useEffect(() => {
        return () => {
            instanceData.destructor();
        }
    }, [instanceData]);

    return instanceData.instanceDynamicRef;
}