import {Color, colorToRgb} from "../color-utils/color-utils";

export function cssResolveRgbColorComponentsForFunctionalNotationFormat (
    color: Color | string
) {
    const rgbColor = colorToRgb(color);

    return rgbColor ? `${rgbColor.r} ${rgbColor.g} ${rgbColor.b}` : null;
}

export function cssAddUnitsToNumericValue (value: number | undefined, units: 'px' | '%') {
    return value !== undefined ? `${value}${units}` : undefined;
}