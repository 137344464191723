import React, {PropsWithChildren} from 'react';

type ComponentProps<T> = T extends React.ComponentType<infer P> ? PropsWithChildren<P> : never;

export function reactCreateMemo<T extends React.ComponentType<any>>(
    Comp: T,
    propsAreEqual?: (prevProps: Readonly<ComponentProps<T>>, nextProps: Readonly<ComponentProps<T>>) => boolean
) : T {
    return (React.memo(Comp, propsAreEqual) as unknown) as T;
}

export function reactForwardRef<COMP extends React.FC<any>>(
    comp: COMP
) : COMP {
    return React.forwardRef((props: any, ref) => {
        return comp({
            ...props,
            ref: ref
        })
    }) as unknown as COMP;
}

// export function reactForwardRef<COMP extends ForwardRefExoticComponent<any>>(
//     render: COMP extends ForwardRefExoticComponent<PropsWithoutRef<infer P> & RefAttributes<infer T>> ?
//         ForwardRefRenderFunction<T, P> : never
// ) : COMP {
//     return (React.forwardRef(render) as unknown) as COMP;
// }

// export function reactForwardRef<RENDER extends ForwardRefRenderFunction<any, any>>(
//     render: RENDER
// ) : COMP {
//     return (React.forwardRef(render) as unknown) as COMP;
// }

export function reactIsRefObject<R>(value: unknown) : value is React.RefObject<R> {
    return (value as React.RefObject<R>).current !== undefined;
}

export function reactWrapNode<T extends React.ReactNode, R extends React.ReactNode> (reactNode: T, wrappingFunc: (reactNode: T) => R) {
    return wrappingFunc(reactNode);
}

export function reactProvideContextValue<T> (reactNode: React.ReactNode, context: React.Context<T>, value: T) {
    const ContextProvider = context.Provider;

    return (
        <ContextProvider value={value}>
            {reactNode}
        </ContextProvider>
    )
}

export function reactSetInnerHtml (html: string) {
    return {
        [['dangerously', 'SetInnerHTML'].join('')]: {
            __html: html
        }
    }
}