import {hashFunctionCreate, Values} from '@wix/devzai-utils-common';
import {DoppeDtoStoreProduct} from './doppe-dto-store-product';
import {DoppeDtoBlogPost} from './doppe-dto-blog-post';
import {DoppeDtoBookingService} from './doppe-dto-booking-service';
import {DoppeDtoVideo} from './doppe-dto-video';
import {DoppeDtoCoupon} from './doppe-dto-coupon';
import {DoppeDtoEvent} from './doppe-dto-event';
import {DoppeDtoMembershipPlan} from './doppe-dto-membership-plan';

export const DoppeBusinessEntity = {
    WixProduct: 'WixProduct',
    WixAutoProductsList: 'WixAutoProductsList',
    WixBlogPost: 'WixBlogPost',
    WixAutoBlogPost: 'WixAutoBlogPost',
    WixAutoBlogPostsList: 'WixAutoBlogPostsList',
    WixService: 'WixService',
    WixAutoServicesList: 'WixAutoServicesList',
    WixCoupon: 'WixCoupon',
    WixVideo: 'WixVideo',
    WixEvent: 'WixEvent',
    WixAutoEventsList: 'WixAutoEventsList',
    WixAutoMembershipPlansList: 'WixAutoMembershipPlansList',
    WixMembershipPlan: 'WixMembershipPlan',
} as const;

export type DoppeBusinessEntity = Values<typeof DoppeBusinessEntity>;

export const DoppeAutoBlogPostsListMaxItems = 20;
export const DoppeAutoProductsListMaxItems = 20;
export const DoppeAutoMembershipPlansListMaxItems = 20;
export const DoppeAutoBookingServicesListMaxItems = 20;
export const DoppeAutoEventsListMaxItems = 20;

export const DoppeBusinessEntityDtoUnavailableReason = {
    FailedLoading: 'FailedLoading',
    NotFound: 'NotFound'
} as const;

export type DoppeBusinessEntityDtoUnavailableReason = Values<typeof DoppeBusinessEntityDtoUnavailableReason>;

export type DoppeBusinessEntityReference<E extends DoppeBusinessEntity = DoppeBusinessEntity> = {
    businessEntity: E;
    id: string;
}

export namespace DoppeBusinessEntityReference {
    export type ResolveBusinessEntity<R extends DoppeBusinessEntityReference> =
        R extends DoppeBusinessEntityReference<infer E> ? E : never;
}

type DoppeBusinessEntityDataBase<T extends DoppeBusinessEntity, DTO> = {
    id: string;
    type: T;
    dto: DTO;
}

export type DoppeBusinessEntityDtoNotFound = {
    __notFound: true;
}

type DoppeBusinessEntityTypeToDto = {
    [DoppeBusinessEntity.WixProduct]: DoppeDtoStoreProduct | DoppeBusinessEntityDtoNotFound;
    [DoppeBusinessEntity.WixAutoProductsList]: DoppeDtoStoreProduct[];
    [DoppeBusinessEntity.WixAutoBlogPost]: DoppeDtoBlogPost | DoppeBusinessEntityDtoNotFound;
    [DoppeBusinessEntity.WixAutoBlogPostsList]: DoppeDtoBlogPost[];
    [DoppeBusinessEntity.WixBlogPost]: DoppeDtoBlogPost | DoppeBusinessEntityDtoNotFound;
    [DoppeBusinessEntity.WixService]: DoppeDtoBookingService | DoppeBusinessEntityDtoNotFound;
    [DoppeBusinessEntity.WixAutoServicesList]: DoppeDtoBookingService[];
    [DoppeBusinessEntity.WixCoupon]: DoppeDtoCoupon | DoppeBusinessEntityDtoNotFound;
    [DoppeBusinessEntity.WixVideo]: DoppeDtoVideo | DoppeBusinessEntityDtoNotFound;
    [DoppeBusinessEntity.WixEvent]: DoppeDtoEvent | DoppeBusinessEntityDtoNotFound;
    [DoppeBusinessEntity.WixAutoEventsList]: DoppeDtoEvent[];
    [DoppeBusinessEntity.WixMembershipPlan]: DoppeDtoMembershipPlan | DoppeBusinessEntityDtoNotFound;
    [DoppeBusinessEntity.WixAutoMembershipPlansList]: DoppeDtoMembershipPlan[]

};

export type DoppeBusinessEntityDataByType = {
    [K in keyof DoppeBusinessEntityTypeToDto]: DoppeBusinessEntityDataBase<K, DoppeBusinessEntityTypeToDto[K]>
}

export type DoppeBusinessEntityData = Values<DoppeBusinessEntityDataByType>;

export namespace DoppeBusinessEntityData {
    export type ResolveDtoFromType<E extends DoppeBusinessEntity> = DoppeBusinessEntityTypeToDto[E];

    export type ResolveAvailableDtoFromType<E extends DoppeBusinessEntity> = Exclude<ResolveDtoFromType<E>, DoppeBusinessEntityDtoNotFound>

    export type ResolveFromType<E extends DoppeBusinessEntity> = DoppeBusinessEntityDataByType[E];
}

export type DoppeBusinessEntityDto = Values<DoppeBusinessEntityTypeToDto>;

export const doppeBusinessEntityReferenceHashFunction = hashFunctionCreate<DoppeBusinessEntityReference>({
    businessEntity: hashFunctionCreate(),
    id: hashFunctionCreate()
})

export function doppeBusinessEntityDataGetBusinessEntityReference(
    businessEntityData: DoppeBusinessEntityData
): DoppeBusinessEntityReference {
    return {
        businessEntity: businessEntityData.type,
        id: businessEntityData.id
    }
}

export function doppeBusinessEntityReferenceEqual(ref1: DoppeBusinessEntityReference, ref2: DoppeBusinessEntityReference) {
    return doppeBusinessEntityReferenceHashFunction(ref1) === doppeBusinessEntityReferenceHashFunction(ref2);
}

export function doppeBusinessEntityDtoCreateNotFound(): DoppeBusinessEntityDtoNotFound {
    return {
        __notFound: true
    }
}

export function doppeBusinessEntityDtoIsNotFound(businessEntityDto: DoppeBusinessEntityDto): businessEntityDto is DoppeBusinessEntityDtoNotFound {
    return (businessEntityDto as DoppeBusinessEntityDtoNotFound)?.__notFound === true;
}

export function doppeBusinessEntityDtoIsAvailable<E extends DoppeBusinessEntity>(businessEntityDto: DoppeBusinessEntityData.ResolveDtoFromType<E> | undefined): businessEntityDto is DoppeBusinessEntityData.ResolveAvailableDtoFromType<E> {
    return businessEntityDto !== undefined && !doppeBusinessEntityDtoIsNotFound(businessEntityDto);
}