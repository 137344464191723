import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeActionTypeEventViewerLang} from './doppe-action-type-event-viewer.lang';
import {arrayRemoveNullValues, immerProduce, stringIsNotNullableOrWhiteSpace} from '@wix/devzai-utils-common';
import {
    BymoPageProps,
    DoppeActionPriceData,
    DoppeExternalEventProvider,
    doppeExternalEventProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {DoppeDtoEvent} from '../../client-server-common/types/doppe-dto-event';
import {BymoPageResource} from "../../client/resources/bymo-page-resource/bymo-page-resource";

export namespace DoppeActionTypeEvent {
    export interface ActionSettings {
        eventLink: string;
        eventTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<string>;
        eventButtonText: string;
        eventImages: WixImageResource[] | null;
        eventDate: DoppeHideableValue<Date>;
        eventTime: DoppeHideableValue<string>;
        eventLocation: DoppeHideableValue<string>;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
        eventProvider: DoppeExternalEventProvider | null;
        eventProviderId: string | null,
    }
}

export const doppeActionTypeEvent = doppeActionTypeDefineMetadata<DoppeActionTypeEvent.ActionSettings>({
    id: 'io.bymo.action/event',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        eventLink: '',
        eventTitle: DoppeActionTypeEventViewerLang.defaultTexts.eventTitle,
        description: DoppeActionTypeEventViewerLang.defaultTexts.description,
        eventButtonText: DoppeActionTypeEventViewerLang.defaultTexts.eventButtonText,
        eventImages: null,
        eventDate: new Date(),
        eventLocation: '',
        priceData: {
            actualPrice: 'Starting from 50$'
        },
        eventTime: doppeHideableValueCreateHidden('00:00'),
        couponCode: doppeHideableValueCreateHidden('CouponCode'),
        ribbon: doppeHideableValueCreateHidden('SALE'),
        eventProvider: null,
        eventProviderId: null,
    }),
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.eventImages ?? [];
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.eventImages) {
                actionSettings.eventImages = actionSettings.eventImages.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.eventProvider !== null && actionSettings.eventProviderId !== null ?
                doppeExternalEventProviderResolveBusinessEntityReference(
                    actionSettings.eventProvider,
                    actionSettings.eventProviderId
                ) :
                null
        ])
    },
});

export function doppeActionTypeEventResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeEvent.ActionSettings,
    doppeDtoService: DoppeDtoEvent,
    _bymoPageProps: BymoPageProps,
): DoppeActionTypeEvent.ActionSettings {

    // const location = arrayFirst(doppeDtoService.locations);

    return {
        ...actionSettings,
        eventLink: stringIsNotNullableOrWhiteSpace(doppeDtoService.eventPageUrl) ?
            doppeDtoService.eventPageUrl : 'about:blank',
    }
}

export function convertDoppeWixEventToEventSettings(event: DoppeDtoEvent, _bymoPageResource?: BymoPageResource) {
    return {
        eventImages: event.media,
        eventLink: event.eventPageUrl ?? '',
        description: event.description ?? doppeHideableValueCreateHidden('Event description'),
        eventTitle: event?.name ?? 'Event title',
        eventLocation: event?.location ?? doppeHideableValueCreateHidden('Event location'),
        eventDate: event.eventDates?.eventStartDate ? new Date(event.eventDates?.eventStartDate) : doppeHideableValueCreateHidden(new Date(Date.now())),
        eventTime: doppeHideableValueCreateHidden('20:00'),
        priceData: calculateActualPriceForEvent(event),
        couponCode: doppeHideableValueCreateHidden('CouponCode'),
        ribbon: doppeHideableValueCreateHidden('SALE'),
        eventProviderId: event.id,
        eventProvider: DoppeExternalEventProvider.Wix,
    }
}

function calculateActualPriceForEvent(event: DoppeDtoEvent) {
    if (event.lowestPrice !== event.highestPrice) {
        return {
            actualPrice: `${event.lowestPrice} - ${event.highestPrice}`
        };
    }

    return event.lowestPrice ? {
        actualPrice: event.lowestPrice
    } : doppeHideableValueCreateHidden({
        actualPrice: 'Starting from 50$'
    });
}