import React from 'react';
import {classes, style} from './horizontal-button.st.css';
import {Icon, iconResolveDynamicSizeIconSrc} from '../icon/icon';
import {Button, ButtonType} from '../button/button';
import {reactCreateMemo} from "../react-utils/react-utils";

export const HorizontalButton = reactCreateMemo(function HorizontalButton<SIZE> (props: HorizontalButton.Props<SIZE>) {
    const {
        prefixIcon,
        suffixIcon,
        loader,
        disabled,
        contentOverlay,
        buttonType = ButtonType.NormalButton,
        size,

        children,
        className,
        ...htmlAttributes
    } = props;

    const inLoadingState = loader !== undefined;

    const iconOnly = (
        (prefixIcon !== undefined || suffixIcon !== undefined) &&
        !(prefixIcon !== undefined && suffixIcon !== undefined) &&
        !children
    ) ? (prefixIcon ?? suffixIcon) : undefined;

    return (
        <Button
            {...htmlAttributes}
            disabled={disabled || inLoadingState}
            className={style(
                classes.root,
                {
                    loading: inLoadingState,
                    hasContentOverlay: contentOverlay !== undefined,
                    iconOnly: iconOnly !== undefined
                },
                className)
            }
            buttonType={buttonType}
        >
            {prefixIcon ? (
                <Icon
                    className={style(classes.prefixIcon, {})}
                    icon={iconResolveDynamicSizeIconSrc(prefixIcon, size)}
                />
            ) : null}
            <div
                className={style(classes.buttonText, {})}
            >
                {children}
            </div>
            {suffixIcon ? (
                <Icon
                    className={style(classes.suffixIcon, {})}
                    icon={iconResolveDynamicSizeIconSrc(suffixIcon, size)}
                />
            ) : null}
            {loader ? (
                <div
                    className={style(classes.loaderContainer, {})}
                >
                    {loader}
                </div>
            ) : null}
            {contentOverlay}
        </Button>
    );
});

export namespace HorizontalButton {

    export interface Props<SIZE> extends Button.Props {
        prefixIcon?: Icon.Src | Icon.DynamicSizeIcon<SIZE>;
        suffixIcon?: Icon.Src | Icon.DynamicSizeIcon<SIZE>;
        size: SIZE;
        loader?: React.ReactElement;
        contentOverlay?: React.ReactElement;
        buttonType?: ButtonType;
    }
}
