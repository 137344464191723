import React from 'react';
import {style, classes} from './doppe-viewer-modal-panel-section.st.css';

export const DoppeViewerModalPanelSection = React.memo(function DoppeViewerModalPanelSection (props: DoppeViewerModalPanelSection.Props) {
    const {
        className,
        ...htmlAttributes
    } = props;

    return (
        <div
            {...htmlAttributes}
            className={style(classes.root, className)}
        />
    );
});

export namespace DoppeViewerModalPanelSection {

    export interface Props extends React.HTMLAttributes<any> {

    }
}
