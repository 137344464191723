import {
    asyncValueCreatePendingState,
    asyncValueCreateRejectedState,
    asyncValueCreateResolvedState,
    AsyncValueState,
    AsyncValueStates,
    Evaluable,
    evaluateWhenFunction,
    EventEmitter, immerProduce,
    IObservableValue
} from "@wix/devzai-utils-common";

export namespace AsyncValueResource {

    export interface ConstructorOptions<T> {

        fetchFunction: () => Promise<T>;

    }

    export interface Events {
        eventUpdated: void;
    }

}

export class AsyncValueResource<T> implements IObservableValue<AsyncValueState<T>> {

    private eventEmitter = new EventEmitter<AsyncValueResource.Events>();


    private fetchFunction;
    private asyncValue?: AsyncValueState<T> = undefined;

    constructor (
        options: AsyncValueResource.ConstructorOptions<T>
    ) {
        this.fetchFunction = options.fetchFunction;
    }

    public get eventUpdated () {
        return this.eventEmitter.createEventReference('eventUpdated');
    }

    private isCurrentFetchPromise (fetchPromise: Promise<T>) {
        return this.asyncValue?.state === AsyncValueStates.Pending && this.asyncValue.promise === fetchPromise;
    }

    private fetchValue () {

        const fetchPromise = this.fetchFunction();

        const asyncValue = this.asyncValue = asyncValueCreatePendingState(fetchPromise);

        fetchPromise.then(
            (result) => {
                if (this.isCurrentFetchPromise(fetchPromise)) {
                    this.asyncValue = asyncValueCreateResolvedState(result);

                    this.eventEmitter.emit('eventUpdated');
                }
            },
            (error) => {
                if (this.isCurrentFetchPromise(fetchPromise)) {
                    this.asyncValue = asyncValueCreateRejectedState(error);

                    this.eventEmitter.emit('eventUpdated');
                }
            }
        )

        this.eventEmitter.emit('eventUpdated');

        return asyncValue;
    }

    public getResolvedValue () : T | undefined {
        return this.asyncValue?.state === AsyncValueStates.Resolved ?
            this.asyncValue.value :
            undefined;
    }

    public getAsyncValue () {
        return this.asyncValue;
    }

    public async ensureFetched () {
        const asyncValueState = this.getValue();

        switch (asyncValueState.state) {
            case AsyncValueStates.Pending: return asyncValueState.promise as Promise<T>;
            case AsyncValueStates.Rejected: {
                throw asyncValueState.reason;
            }
            case AsyncValueStates.Resolved: {
                return asyncValueState.value;
            }
        }
    }

    public getValue () : AsyncValueState<T> {

        if (this.asyncValue !== undefined) {
            return this.asyncValue;
        } else {
            return this.fetchValue();
        }
    }

    public async ensureUpToDateValue () {
        const asyncValue = this.fetchValue();

        return await asyncValue.promise;
    }

    public async fetchUpToDateValue () {
        return this.fetchFunction();
    }

    /**
     * @deprecated use ensureUpToDateValue instead.
     */
    public async ensureValueUpToDate () {
        if (this.asyncValue !== undefined) {

            const upToDateValue = await this.fetchFunction();

            this.asyncValue = asyncValueCreateResolvedState(upToDateValue);
            this.eventEmitter.emit('eventUpdated');
        }
    }

    public invalidateValue () {

        this.asyncValue = undefined;

        this.eventEmitter.emit('eventUpdated');
    }

    public modifyResolvedValue (modificationFunc: (resolvedValue: T) => void) {
        this.updateResolvedValue(immerProduce(obj => {
            modificationFunc(obj);
        }))
    }

    public updateResolvedValue (updateFunction: (resolvedValue: T) => T) {

        const resolvedValue = this.getResolvedValue();

        if (resolvedValue) {
            const updatedValue = updateFunction(resolvedValue);

            if (updatedValue !== resolvedValue) {
                this.asyncValue = asyncValueCreateResolvedState(updatedValue);

                this.eventEmitter.emit('eventUpdated');
            }
        }
    }

    /**
     * You should call this method when you know what the async value's resolved value is.
     */
    public setResolvedValue (resolvedValue: Evaluable<(resolvedValue: T | undefined) => T | undefined>) {

        const prevResolvedValue = this.getResolvedValue();
        const newResolvedValue = evaluateWhenFunction(resolvedValue, prevResolvedValue);

        if (newResolvedValue !== undefined && newResolvedValue !== prevResolvedValue) {
            this.asyncValue = asyncValueCreateResolvedState(newResolvedValue);

            this.eventEmitter.emit('eventUpdated');
        }
    }

    /**
     * You should call this method when you know what that the async value's resolved value is out of date.
     */
    public reset () {
        this.asyncValue = undefined;

        this.eventEmitter.emit('eventUpdated');
    }
}