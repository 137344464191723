import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
} from '../../client-server-common/types/doppe-hideable-value';

export namespace DoppeActionTypeDonate {
    export interface ActionSettings {
        amountOptions: number[];
        amountSelectionButtonText: string;
        amountSelectionTitle: DoppeHideableValue<string>;
        showEmailInput: boolean;
        showAddressInput: boolean;
        showCustomAmount: boolean;
        showMessageInput: boolean;
        sendReceipt: boolean;
        withEmailNotification: boolean;
        showEmailSubscriptionConsent: boolean;
        showEmailSubscriptionConsentCheckbox: boolean;
        emailSubscriptionConsentCustomText: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeDonateMetadata = doppeActionTypeDefineMetadata<DoppeActionTypeDonate.ActionSettings>({
    id: 'co.hopp.action/donate',
    resolveActionSettings: (action) => {
        const { ...restSettings } = action.settings;

        return {
            amountOptions: [],
            amountSelectionButtonText: '',
            amountSelectionTitle: 'Choose an amount',
            showEmailInput: true,
            showAddressInput: false,
            showCustomAmount: false,
            showMessageInput: false,
            sendReceipt: true,
            withEmailNotification: true,
            showEmailSubscriptionConsent: false,
            showEmailSubscriptionConsentCheckbox: false,
            emailSubscriptionConsentCustomText: doppeHideableValueCreateHidden(''),
            ...restSettings,
        };
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: true,
});
