import {bymoTemplateMetadataFactoryCreate} from "../../../doppe-sdk";

export namespace BymoTemplateActionPreview {

    export interface TemplateProps {
        enableContentScrolling: boolean;
    }
}

export const BymoTemplateActionPreviewTemplateMetadata = bymoTemplateMetadataFactoryCreate<BymoTemplateActionPreview.TemplateProps>()({
    initialValues: {
        enableContentScrolling: true
    },
    features: ['hasActions', 'hasActionButton']
})