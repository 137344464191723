import {useCallback, useEffect, useRef} from 'react';
import {
    AsyncValueState,
    asyncValueCreatePendingState,
    asyncValueCreateRejectedState,
    asyncValueCreateResolvedState
} from '@wix/devzai-utils-common';
import {useForceUpdate} from './use-force-update';

export namespace useAsyncState {
    export type Result<T> = [AsyncValueState<T>, (value: T | Promise<T>) => void];
    export type VoidResult = [AsyncValueState<void>, (value: Promise<void>) => void];
}

export function useAsyncState(): useAsyncState.VoidResult
export function useAsyncState<T>(initialState: T | Promise<T>): useAsyncState.Result<T>
export function useAsyncState<T>(initialState?: T | Promise<T>): useAsyncState.Result<T>
{
    const forceUpdate = useForceUpdate();

    const asyncValueRef = useRef<AsyncValueState<unknown>>(
        initialState instanceof Promise ?
            asyncValueCreatePendingState(initialState) :
            asyncValueCreateResolvedState(initialState)
    );

    const pendingPromiseRef = useRef<Promise<T> | null>(
        initialState instanceof Promise ?
            initialState :
            null
    );

    const valueRef = useRef<T | Promise<T>>(initialState as T | Promise<T>);

    const setValue = useCallback((value: T | Promise<T>) => {

        if (value !== valueRef.current)
        {
            if (value instanceof Promise) {
                const promise = value;

                pendingPromiseRef.current = promise;

                asyncValueRef.current = asyncValueCreatePendingState(promise);

                promise
                    .then(value => {
                        if (pendingPromiseRef.current === promise) {
                            pendingPromiseRef.current = null;
                            asyncValueRef.current = asyncValueCreateResolvedState(value);
                            forceUpdate();
                        }
                    })
                    .catch(error => {
                        if (pendingPromiseRef.current === promise) {
                            pendingPromiseRef.current = null;
                            asyncValueRef.current = asyncValueCreateRejectedState(error);
                            forceUpdate();
                        }
                    });
            } else {
                pendingPromiseRef.current = null;

                asyncValueRef.current = asyncValueCreateResolvedState(value);
            }

            forceUpdate();
        }

        return value;
    }, [forceUpdate]);

    useEffect(() => {
        return () => {
            pendingPromiseRef.current = null;
        };
    }, []);

    return [asyncValueRef.current as AsyncValueState<T>, setValue];
}
