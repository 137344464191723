import {DoppeViewerAppClientServiceBase} from './doppe-viewer-app-client-service-base';
import {DoppeViewerAnalyticsEvents} from '../../client-server-common';
import {SessionStorageAccessor} from '@wix/devzai-utils-dom';
import {
    arrayOrderBy,
    asyncCachedValueCreate,
    compareStringsCaseInsensitive,
    evaluateFunction,
    OmitStrict,
    tsTypeAssert
} from '@wix/devzai-utils-common';
import type {DoppeViewerBiEvents} from '../doppe-viewer-wix-web-bi-logger/doppe-viewer-wix-web-bi-logger';

export class DoppeViewerAppAnalyticsClient extends DoppeViewerAppClientServiceBase {

    private asyncCachedWixBiWebLogger = asyncCachedValueCreate(async () => {
        const {
            doppeViewerWixWebBiLoggerCreate,
            doppeViewerBiEvents
        } = await import (/* webpackChunkName: "doppe-viewer-wix-web-bi-logger" */ '../doppe-viewer-wix-web-bi-logger/doppe-viewer-wix-web-bi-logger');

        return {
            logger: doppeViewerWixWebBiLoggerCreate(),
            doppeViewerBiEvents
        }
    })

    public flushLogs() {
        const cachedWixBiWebLogger = this.asyncCachedWixBiWebLogger.getCachedValue();
        if (cachedWixBiWebLogger) {
            cachedWixBiWebLogger.logger.flush().catch(() => {

            })
        }
    }

    public logEvent<EVENT_NAME extends DoppeViewerAnalyticsEvents.Events['eventName']>(
        eventName: EVENT_NAME,
        params: OmitStrict<DoppeViewerAnalyticsEvents.EventParams<EVENT_NAME>, keyof DoppeViewerAnalyticsEvents.ImplicitParams>
    ) {
        const sessionId = this.getSessionId();
        const clientId = this.getClientId();

        const paramsHash = JSON.stringify(arrayOrderBy(Object.entries(params), ([key]) => key, compareStringsCaseInsensitive))

        const uouIndexInSession = SessionStorageAccessor.updateItem<number>(
            `uouIndexInSession.${eventName}.${sessionId}.${paramsHash}`,
            counter => (counter ?? 0) + 1
        ) - 1;

        this.apiClient.reportAnalyticsEvent({
            eventName: eventName,
            eventParams: {
                ...params,
                ...tsTypeAssert<DoppeViewerAnalyticsEvents.DoppeServerImplicitParams>({
                    uouIndexInSession: uouIndexInSession,
                })
            } as DoppeViewerAnalyticsEvents.DoppeServerEventParams<EVENT_NAME>
        })
            .catch(() => {
                console.warn(`Failed reporting analytics event '${eventName}' to doppe analytics.`)
            });

        evaluateFunction(async () => {

            const {
                doppeViewerBiEvents,
                logger
            } = await this.asyncCachedWixBiWebLogger.getValue();

            const biEventLogObjectFactory = doppeViewerBiEvents[eventName as DoppeViewerBiEvents];

            if (biEventLogObjectFactory === undefined) {
                console.warn(`Event '${eventName}' isn't available on bi-logger`)
            } else {
                await logger.report(doppeViewerBiEvents[eventName as DoppeViewerBiEvents]?.({
                    ...params,
                    ...tsTypeAssert<DoppeViewerAnalyticsEvents.ImplicitParams>({
                        uouReferrer: document.referrer,
                        uouIndexInSession: uouIndexInSession,
                        uouSessionId: sessionId,
                        uouClientId: clientId,
                    })
                }));
            }
        })
            .catch(() => {
                console.warn(`Failed logging bi event '${eventName}'`)
            });
    }

}