import {DoppeViewerLangDictionaryEn} from './doppe-viewer-lang-dictionary.en';

export const DoppeViewerLangDictionaryHe: typeof DoppeViewerLangDictionaryEn = {
    contactFormNameFieldPlaceholder: `שם`,
    contactFormEmailFieldPlaceholder: `מייל`,
    contactFormPhoneFieldPlaceholder: `טלפון`,
    createdWithHopp: 'צור את הלינק בביו שלך',
    collectSubscribersEmailFieldPlaceholder: 'הכניסו כתובת מייל',
    collectSubscribersPhoneFieldPlaceholder: 'הכניסו מספר טלפון',
    contactFormMessageFieldPlaceholder: `הכניסו הודעה`,
    collectSubscribersEmailMenuItem: 'מייל',
    collectSubscribersPhoneFieldMenuItem: 'טלפון',
    autoRedirectBannerRedirectingText: (options: {
        normalizedHostName: string,
        countdown: number,
    }) => `עוברים הלאה ל ${options.normalizedHostName}  ${options.countdown > 0 ? ` בעוד ${options.countdown} שניות` : ''}  `,
    autoRedirectBannerRedirectingCancelText: 'ביטול',
    autoRedirectBannerRedirectingCloseText: 'סגור',
    termsOfServiceText: 'ע"י שימוש ב Hopp אתם מאשרים שקראתם והסכמתם ל',
    termsOfServiceTextAnd: 'וגם ל',
    termsOfServiceTextOf: 'של',
    termsOfServiceLabel: 'תנאי השימוש',
    termsOfServicePrivacyLabel: 'מדיניות הפרטיות',
    subscribeCheckBoxText: (options: {
        username: string,
    }) => `על ידי סימון התיבה ושליחת הטופס אתם מספקים מידע ל ${options.username}, אשר יכול לפנות אלייכם עבור מטרות שיווק.`,
    subscribeConsentText: (options: {
        username: string,
    }) => `על ידי שליחת הטופס אתם מספקים מידע ל ${options.username}, אשר יכול לפנות אלייך עבור מטרות שיווק.`,
    subscribeThankYou: 'תודה שנרשמתם!',
    formThankYou: 'תודה ששלחתם!',
    requiredField: 'שדה חובה',
    requiredConsent: 'אישור חובה',
    enterValidPhone: 'הכניסו מסםר טלפון תקין',
    enterValidEmail: 'הכניסו כתובת מייל תקינה',
    youMustAgree: 'אתם חייבים לאשר את הסכם ההרשמה כדי להמשיך',
    skip: 'דלגו לאתר',
    beforeYouMoveOn: 'לפני שאתם ממשיכים הלאה ל...',
    redirectingIn: (seconds: number) => `עובר בעוד ${seconds}`,
    connectedLink: 'לינק מחובר',
    claim: `צרו חשבון Hopp היום`,
    claimYourAccount: 'צרו חשבון',
    tryForFree: 'נסה את Hopp',
    goToLink: `עברו ללינק`,
    enterACode: `חפשו או הכניסו מילת מפתח`,
    noLinksMatching: `אין לינקים אשר מתאימים למילת המפתח שהכנסתם`,
    accessSharedLink: `עברו ללינק המשותף ע"י הכנסת מילת מפתח המצויינת באחד הפוסטים שלי`,
    copiedToClipboard: 'הועתק לקלפיבורד',
    recentLinks: `לינקים אחרונים`,
    links: `לינקים`,
    recentCodes: `מילות מפתח אחרונות`,
    codes: `מילות מפתח`,
    includesCoupon: 'קיים קופון',
    continue: 'המשיכו לאתר',
    backToSearch: 'חזרו לחיפוש',
    seeMore: 'קראו עוד',
    back: 'אחורה',
    code: 'מילת מפתח',
    contactFormZipCodeFieldPlaceholder: 'מיקוד',
    contactFormAddressFieldPlaceholder: 'כתובת',
    enterValidAddress: 'הכניסו כתובת חוקית',
    enterValidZip: 'הכניסו מיקוד חוקי',
    contentLoadingErrorTitle: `נכשל בטעינת התוכן`,
    contentLoadingErrorDescription: `אנא טען את הדף מחדש כדי לנסות שוב`,
    contentUnavailableTitleDefault: `תוכן זה אינו זמין כרגע`,
    contentUnavailableTitleBlog: 'טעינת הפוסט נכשלה',
    contentUnavailableTitleService: 'טעינת השירות נכשלה',
    contentUnavailableTitleProduct: 'טעינת המוצר נכשלה',
    contentUnavailableTitlePlan: 'טעינת התוכנית נכשלה',
    coupon: 'קופון',
    continueToWebsite: 'המשיכו לאתר',
    couponDescription: 'העתיקו את הקופון והמשיכו לאתר',
    checkoutFormAdditionalInformationFieldLabel: 'מידע נוסף',
    checkoutFormAdditionalInformationFieldPlaceholder: 'הערות מיוחדות עבור המוכר',
    checkoutFormMessageFieldLabel: 'הודעה',
    checkoutFormMessageFieldPlaceholder: 'הודעה למוכר',
    fulfillmentDetails: 'פרטי משלוח',
    cardNumber: 'מספר כרטיס',
    cardExpirationyPlaceholder: 'MM/YY',
    cardExpiration: 'תוקף',
    cardCVC: '3 ספרות',
    cardCountry: 'מדינה',
    checkoutAddressLine: 'כתובת',
    securedPaymentByStripe: 'תשלום מאובטח ע"י Stripe',
    requestsInstructions: 'הוראות מיוחדות',
    checkoutAddressStreetAddress: 'עיר ורחוב',
    fullNamePlaceholder: 'שם פרטי ושם משפחה',
    fullName: 'שם מלא',
    email: 'אימייל',
    checkoutPhone: 'טלפון',
    paymentError: 'אנו נתקלים בבעיות בעיבוד התשלום שלך. אנא נסה שוב מאוחר יותר.',
    checkoutContactInformation: 'פרטי יצירת קשר',
    paymentDetails: 'פרטי תשלום',
    billingDetails: 'פרטי חיוב',
    cookieConsentAccept: 'Accept',
    cookieConsentDeclineAll: 'Decline All',
    cookieConsentSettings: 'Settings',
    cookieConsentDescription: 'We use cookies on our website to see how you interact with it. By accepting, you agree to our use of such cookies',
    cookieConsentModalTitle: 'Privacy Settings',
    cookieConsentEssentialTitle: 'Essential cookies',
    cookieConsentEssentialContent: 'These cookies enable core functionality such as security, verification of identity and network management. These cookies can’t be disabled.',
    cookieConsentMarketingTitle: 'Enable Marketing Cookies',
    cookieConsentMarketingContent: 'These cookies are used to track advertising effectiveness to provide a more relevant service and deliver better ads to suit your interests.',
    cookieConsentFunctionalTitle: 'Enable Functional Cookies',
    cookieConsentFunctionalContent: 'These cookies collect data to remember choices users make to improve and give a more personalised experience.',
    cookieConsentSettingsSaveButton: 'Save',
    cookieConsentAnalyticsTitle: 'Enable Analytics Cookies',
    cookieConsentAnalyticsContent: 'These cookies help us to understand how visitors interact with our website, discover errors and provide a better overall analytics.'
}