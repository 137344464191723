import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';


export namespace DoppeActionTypeCalendly {

    export interface ActionSettings {
        calenderUrl: string;
        showCookieBanner: boolean;
        showPageDetails: boolean;
        showEventDetails: boolean;
    }


}

export const doppeActionTypeCalendly = doppeActionTypeDefineMetadata<DoppeActionTypeCalendly.ActionSettings>({
    id: 'io.bymo.action/calendly',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        calenderUrl: '',
        showCookieBanner: false,
        showPageDetails: true,
        showEventDetails: true,
    }),
    hasNoGoalMeasuring: false,
    isWidgetOpener: false,
    isModalOpener: true,
    canRenderInPage: false,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
})

