import React, {useEffect} from 'react';
import {classes, style} from './doppe-action-type-calendly-widget.st.css';
import {AsyncValueStates, urlModifyQueryParams} from '@wix/devzai-utils-common';
import {
    useAsyncState,
    useDynamicRef,
    useDynamicRefCurrentInstance,
    useRenderState,
    useRootWindow
} from '@wix/devzai-utils-react';
import {DoppeActionType} from '../../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeActionTypeCalendly} from '../doppe-action-type-calendly';
import {DomEventListener, domLoadScript} from '@wix/devzai-utils-dom';
import {
    DoppePageAsyncValueRenderer
} from '../../../components/doppe-page-async-value-renderer/doppe-page-async-value-renderer';

export const DoppeActionTypeCalendlyWidget = React.memo(function DoppeActionTypeSubscribeEmbedWidget(
    props: DoppeActionType.WidgetProps<DoppeActionTypeCalendly.ActionSettings>
) {
    const {
        actionContext,

        className,
        ...htmlAttributes
    } = props;

    const actionSettings = actionContext.action.settings;

    const calenderUrl = urlModifyQueryParams(actionSettings.calenderUrl, {
        back: null,
        hide_landing_page_details: actionSettings.showPageDetails ? null : '1',
        hide_event_type_details: actionSettings.showEventDetails ? null : '1',
        hide_gdpr_banner: actionSettings.showCookieBanner ? null : '1',
    }) ;

    const rootWindow = useRootWindow();

    const [loadCalenderState, setLoadCalenderState] = useAsyncState(false);

    const renderState = useRenderState({
        actionContext: actionContext
    });

    const containerElementRef = useDynamicRef<HTMLDivElement>();
    const containerElement = useDynamicRefCurrentInstance(containerElementRef);

    useEffect(() => {
        if(loadCalenderState.state === AsyncValueStates.Resolved && loadCalenderState.value && containerElement) {
            (rootWindow ?? window as any).Calendly.initInlineWidget({
                url: calenderUrl,
                parentElement: containerElement,
                prefill: {},
                utm: {}
            });

            const eventBinding = DomEventListener.bind<MessageEvent>((rootWindow ?? window), 'message', event => {
                if (isCalendlyEvent(event)) {
                    renderState.current.actionContext.reportGoalAchieved();
                }
            })
            return () => {
                eventBinding.dispose();
            }
        }

        return () => {}
    }, [loadCalenderState, containerElement, rootWindow, calenderUrl, renderState])

    useEffect(() => {
        setLoadCalenderState(
            domLoadScript('https://assets.calendly.com/assets/external/widget.js', {
                rootWindow: rootWindow,
                skipIfAlreadyLoaded: true
            }).then(() => true)
        );
    }, [rootWindow])

    return (
        <div
            {...htmlAttributes}
            className={style(classes.root, className)}
        >
            <DoppePageAsyncValueRenderer
                asyncValue={loadCalenderState}
                className={style(classes.loaderContent, {})}
            >
                {
                    () => {
                        return (
                            <div
                                id={actionContext.action.id}
                                key={calenderUrl}
                                ref={containerElementRef}
                                className={style(classes.content, {})}
                            />
                        )
                    }
                }
            </DoppePageAsyncValueRenderer>
        </div>
    );
});

function isCalendlyEvent(e: MessageEvent) {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
};