import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeRequests} from './doppe-action-type-requests';

export const doppeActionTypeRequestsViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeRequests, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeRequestsWidget: (await import('./doppe-action-type-requests-widget/doppe-action-type-requests-widget')).DoppeActionTypeRequestsWidget
        }
    },
    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeRequestsWidget
        } = viewerComponents;

        return <DoppeActionTypeRequestsWidget {...renderProps} />;
    }
})