import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeDtoBymoSubscribeListId} from '../../client-server-common/types/doppe-dto-bymo-subscribe-list';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeActionTypeSubscribeViewerLang} from './doppe-action-type-subscribe-viewer.lang';
import {Values} from "@wix/devzai-utils-common";

export const SubscribeActionFormSubscriptionOptions = {
    Email: 'Email',
    Phone: 'Phone'
} as const;

export type SubscribeActionFormSubscriptionOptions = Values<typeof SubscribeActionFormSubscriptionOptions>;

export namespace DoppeActionTypeSubscribe {
    export interface ActionSettings {
        enableEmailSubscribeOption: boolean;
        enablePhoneSubscribeOption: boolean;
        defaultSubscribeOption: SubscribeActionFormSubscriptionOptions;
        listId: DoppeDtoBymoSubscribeListId | null;
        showConsentCheckbox: boolean;
        subscribeButtonText: string;
        customConsentText: DoppeHideableValue<string>;
        withEmailNotification: boolean;
        customThankYouMessage: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeSubscribeMetaData = doppeActionTypeDefineMetadata<DoppeActionTypeSubscribe.ActionSettings>({
    id: 'io.bymo.action/subscribe',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        enableEmailSubscribeOption: true,
        enablePhoneSubscribeOption: false,
        defaultSubscribeOption: SubscribeActionFormSubscriptionOptions.Email,
        listId: null,
        showConsentCheckbox: false,
        subscribeButtonText: DoppeActionTypeSubscribeViewerLang.defaultTexts.subscribeButtonText,
        customConsentText: doppeHideableValueCreateHidden(''),
        withEmailNotification: false,
        customThankYouMessage: doppeHideableValueCreateHidden(''),
    }),
    isWidgetOpener: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    canRenderInPage: true,
})