import {
    assertDefined,
    cachedValueCreate,
    evaluateFunction,
    FontWeight,
    mapCreateFromIterable,
    objectMapValues,
    PartiallyOptional,
    TypefaceClassification,
    Values
} from "@wix/devzai-utils-common";
import {wixFontResolveGoogleFontStyleSheetUrl} from "@wix/devzai-common-wix";
import {DoppeViewerLang} from "../../client-server-common/doppe-viewer/doppe-viewer-lang";

export const DoppePageFontSource = {
    GoogleFont: 'GoogleFont'
} as const;

export type DoppePageFontSource = Values<typeof DoppePageFontSource>;

export type DoppePageFontSpec = {
    fontFamily: string;
    addedPixels: number;
    source: DoppePageFontSource;
    textFontWeight: FontWeight;
    headingsFontWeight: FontWeight;
    typefaceSpecification: TypefaceClassification;
    supportedLangs: DoppeViewerLang[];
    isPremium: boolean;
}

function defineFontSpec (fontSpec: PartiallyOptional<DoppePageFontSpec, 'isPremium' | 'textFontWeight' | 'headingsFontWeight' | 'addedPixels' | 'supportedLangs'>) : DoppePageFontSpec {
    return {
        addedPixels: 0,
        textFontWeight: '400',
        headingsFontWeight: '600',
        supportedLangs: [DoppeViewerLang.English],
        isPremium: false,
        ...fontSpec
    }
}

const DoppePageSupportedFontFamiliesSpecs = {
    Poppins: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Poppins',
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    Rubik: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Rubik',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLangs: [DoppeViewerLang.English, DoppeViewerLang.Hebrew]
    }),
    Assistant: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Assistant',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLangs: [DoppeViewerLang.English, DoppeViewerLang.Hebrew]
    }),
    Alef: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Alef',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLangs: [DoppeViewerLang.English, DoppeViewerLang.Hebrew],
        headingsFontWeight: '700',
    }),
    PlayfairDisplay: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Playfair Display',
        headingsFontWeight: '700',
        typefaceSpecification: TypefaceClassification.serif
    }),
    Merriweather: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Merriweather',
        headingsFontWeight: '700',
        typefaceSpecification: TypefaceClassification.serif
    }),
    RobotoSlab: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Roboto Slab',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.serif
    }),
    Roboto: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Roboto',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    Lora: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Lora',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.serif
    }),
    OpenSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Open Sans',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    SpaceMono: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Space Mono',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.monospace,
        textFontWeight: '400'
    }),
    Montserrat: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Montserrat',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    Oswald: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Oswald',
        headingsFontWeight: '500',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    Cormorant: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Cormorant',
        headingsFontWeight: '700',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.serif
    }),
    JosefinSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Josefin Sans',
        headingsFontWeight: '700',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    Kalam: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Kalam',
        headingsFontWeight: '700',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.cursive
    }),
    Lato: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Lato',
        headingsFontWeight: '700',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    Caveat: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Caveat',
        headingsFontWeight: '700',
        addedPixels: 7,
        typefaceSpecification: TypefaceClassification.cursive
    }),
    Raleway: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Raleway',
        headingsFontWeight: '700',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    Ubuntu: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Ubuntu',
        headingsFontWeight: '700',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    KiteOne: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Kite One',
        headingsFontWeight: '400',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    NovaRound: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Nova Round',
        headingsFontWeight: '400',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.cursive
    }),
    Quicksand: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Quicksand',
        headingsFontWeight: '600',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    HeptaSlab: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Hepta Slab',
        headingsFontWeight: '600',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.serif
    }),
    LeagueSpartan: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'League Spartan',
        headingsFontWeight: '600',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    NunitoSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Nunito Sans',
        headingsFontWeight: '700',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    Baskervville: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Baskervville',
        headingsFontWeight: '400',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.serif
    }),
    Marcellus: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Marcellus',
        headingsFontWeight: '400',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.serif
    }),
    SourceSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Source Sans 3',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        isPremium: true
    }),
    PTSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'PT Sans',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        isPremium: true
    }),
    Prompt: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Prompt',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        isPremium: true
    }),
    WorkSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Work Sans',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        isPremium: true
    }),
    Kanit: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Kanit',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        isPremium: true
    }),
    Gluten: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Gluten',
        headingsFontWeight: '600',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.cursive,
        isPremium: true
    }),
    Orbitron: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Orbitron',
        headingsFontWeight: '800',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.cursive,
        isPremium: true
    }),
    Rowdies: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Rowdies',
        textFontWeight: '300',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.cursive,
        isPremium: true
    }),
    Corben: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Corben',
        textFontWeight: '400',
        headingsFontWeight: '700',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.cursive,
        isPremium: true
    }),
    Coda: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Coda',
        textFontWeight: '400',
        headingsFontWeight: '800',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.cursive,
        isPremium: true
    }),
    // BodoniModa: defineFontSpec({
    //     source: DoppePageFontSource.GoogleFont,
    //     fontFamily: 'Bodoni Moda',
    //     textFontWeight: '400',
    //     headingsFontWeight: '700', // TODO: Requires ITALIC
    //     addedPixels: 1,
    //     typefaceSpecification: TypefaceClassification.serif,
    //     isPremium: true
    // }),
    // DMSerifDisplay: defineFontSpec({
    //     source: DoppePageFontSource.GoogleFont,
    //     fontFamily: 'DM Serif Display',
    //     textFontWeight: '400', // TODO: Requires ITALIC
    //     headingsFontWeight: '400',
    //     addedPixels: 1,
    //     typefaceSpecification: TypefaceClassification.serif,
    //     isPremium: true
    // }),
} as const satisfies Record<string, DoppePageFontSpec>;

type DoppePageSupportedFontFamiliesSpecs = typeof DoppePageSupportedFontFamiliesSpecs;

const DoppePageSupportedFontFamilies = objectMapValues(DoppePageSupportedFontFamiliesSpecs, spec => spec.fontFamily) as {
    [K in keyof DoppePageSupportedFontFamiliesSpecs]: DoppePageSupportedFontFamiliesSpecs[K]['fontFamily']
};

const lazyFontFamilyToFontSpecMap = cachedValueCreate(() => {
    return mapCreateFromIterable(
        Object.values(DoppePageSupportedFontFamiliesSpecs),
        pageFontSpec => pageFontSpec.fontFamily
    )
})

function doppePageFontResolveFontSpecFromFontFamily (fontFamily: string) {
    return lazyFontFamilyToFontSpecMap.getValue().get(fontFamily);
}

export function doppePageFontFamilyIsPremium (fontFamily: string) {

    const fontSpec = doppePageFontResolveFontSpecFromFontFamily(fontFamily);

    return fontSpec?.isPremium ?? false;
}

export function doppePageFontGetSupportedFontFamiliesSpecs (lang: DoppeViewerLang) {
    return Object.values(DoppePageSupportedFontFamiliesSpecs).filter(spec => {
        return spec.supportedLangs.includes(lang) && spec.addedPixels < 2
    })
}


export function doppePageFontGetSupportedFontFamilies (lang: DoppeViewerLang) {
    return doppePageFontGetSupportedFontFamiliesSpecs(lang).map(spec => spec.fontFamily);
}

export function doppePageFontEnsureSupportedFontSpec (
    fontFamily: string,
    doppeViewerLang: DoppeViewerLang
) {
    const fontSpec = doppePageFontResolveFontSpecFromFontFamily(fontFamily);

    if (fontSpec === undefined || !fontSpec.supportedLangs.includes(doppeViewerLang)) {
        return assertDefined(doppePageFontResolveFontSpecFromFontFamily(doppePageFontGetDefaultFontFamily(doppeViewerLang)))
    } else {
        return fontSpec
    }
}

export type DoppePageSupportedFontFamilies = Values<typeof DoppePageSupportedFontFamilies>;

export function doppePageFontGetDefaultFontFamily (doppeViewerLang: DoppeViewerLang) : DoppePageSupportedFontFamilies {
    switch (doppeViewerLang) {
        case DoppeViewerLang.English: return 'Poppins';
        case DoppeViewerLang.Hebrew: return 'Rubik';
    }
}

export function doppePageFontResolveStyleSheetUrl<F extends string> (
    options: {
        fontFamily: F;
        fontWeights: FontWeight[];
        text?: string;
    }
) {

    const {
        fontFamily,
        fontWeights,
        text
    } = options;

    const fontSpec = doppePageFontResolveFontSpecFromFontFamily(fontFamily);

    const result = evaluateFunction(() => {
        if (fontSpec) {
            switch (fontSpec.source) {
                case DoppePageFontSource.GoogleFont:
                    return wixFontResolveGoogleFontStyleSheetUrl({
                        fontFamily: fontSpec.fontFamily,
                        fontWeights: fontWeights,
                        text: text
                    })
            }
        } else {
            return undefined;
        }
    })

    return result as F extends DoppePageSupportedFontFamilies ? string : (string | undefined)
}