import {DoppeDtoBymoLink, linkSocialShareSettingsResolveFromUrlMetadata} from "./doppe-dto-bymo-link";
import {DoppeDtoBymoPage, DoppeDtoBymoPageOwnerData} from "./doppe-dto-bymo-page";
import {DoppeDtoUser} from "./doppe-dto-user";
import {BymoPageStatus} from "./bymo-page-status";
import {DoppeDtoUserSettings} from "./doppe-dto-user-settings";
import {OmitStrict, PartiallyRequired} from "@wix/devzai-utils-common";
import {BymoPageContextProps} from "../../doppe-sdk";
import {BymoTemplatesIds} from "../doppe-templates/doppe-templates";
import {DoppeDtoViewerFeatures} from "./doppe-dto-viewer-features";
import {LinkSocialShareSettings} from "./link-social-share-settings";
import {DoppeBlockingStatus} from "./doppe-blocking-reason";
import {DoppePremiumFeaturesStatus} from "../doppe-premium";
import {BymoPagePropsForViewer} from "../bymo-pages/bymo-page-props";

export type DoppeDtoBymoLinkViewerData = {
    bymoPagePropsOverrides: DoppeDtoBymoLink['bymoPageProps'];
    targetUrl: string | null;
    targetUrlMetadata: DoppeDtoBymoLink['targetUrlMetadata'];
    openGraphTitle: DoppeDtoBymoLink['openGraphTitle'];
    openGraphImage: DoppeDtoBymoLink['openGraphImage'];
    socialShareSettings: DoppeDtoBymoLink['socialShareSettings'];
    pageData?: DoppeDtoBymoLinkViewerData.PageData;
    userAccountName: DoppeDtoUser['subdomain'];
    userDisplayName: DoppeDtoUser['displayName'];
    userProfilePicture: DoppeDtoUser['profilePicture'];
    userPrivacyPolicyUrl?: DoppeDtoUserSettings['privacyPolicyUrl'];
    userTermsOfServiceUrl?: DoppeDtoUserSettings['termsOfServiceUrl'];
    userCookieBannerSettings?: DoppeDtoUserSettings['cookieBannerSettings'];
    bymoPageStatus: BymoPageStatus;
    linkId: DoppeDtoBymoLink['id'];
    bymoPageId?: DoppeDtoBymoPage.Id;
    doppeUserId: DoppeDtoUser['id'];
    bymoPageContextProps: Partial<BymoPageContextProps>;
    viewerFeatures: Required<DoppeDtoViewerFeatures>;
    pageCachingEnabled: boolean;
    isBlocked: DoppeBlockingStatus;
};

export namespace DoppeDtoBymoLinkViewerData {

    export type WithTargetUrl = OmitStrict<DoppeDtoBymoLinkViewerData, 'targetUrl'> & {
        targetUrl: Exclude<DoppeDtoBymoLinkViewerData['targetUrl'], null>
    }

    export type WithConnectedPage = PartiallyRequired<DoppeDtoBymoLinkViewerData, 'bymoPageId' | 'pageData'>;

    export type WithoutConnectedPage = OmitStrict<DoppeDtoBymoLinkViewerData, 'bymoPageId' | 'pageData' | 'bymoPageStatus'> & {
        bymoPageStatus: typeof BymoPageStatus.Disabled
    };

    export type LivePageData = Pick<WithConnectedPage, 'linkId' | 'bymoPageId' | 'doppeUserId'> & {
        pageType: 'pre-roll' | 'speed-dial' | 'action-page'
    }

    export interface PageData {
        bymoPageProps: BymoPagePropsForViewer;
        bymoTemplateId: DoppeDtoBymoPage['bymoTemplateId'];
        ownerData: DoppeDtoBymoPageOwnerData;
        premiumFeaturesStatus: DoppePremiumFeaturesStatus | null;
    }
}

type NormalizedLinkSocialShareSettings = OmitStrict<LinkSocialShareSettings, 'socialImage' | 'twitterSocialImage' | 'twitterViewType'> & {
    socialImage?: Exclude<LinkSocialShareSettings['socialImage'], null>;
    twitterSocialImage?: Exclude<LinkSocialShareSettings['twitterSocialImage'], null>;
    twitterViewType: Exclude<LinkSocialShareSettings['twitterViewType'], undefined>
}

export function doppeDtoBymoLinkViewerDataResolvePageType (bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithConnectedPage) {
    if (bymoLinkViewerData.targetUrl) {
        return 'pre-roll' as const;
    } else {
        if (bymoLinkViewerData.pageData.bymoTemplateId === BymoTemplatesIds.SpeedDial) {
            return 'speed-dial' as const;
        } else {
            return 'action-page' as const;
        }
    }
}

export function doppeDtoBymoLinkViewerDataResolveSocialSettings (bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithConnectedPage) : NormalizedLinkSocialShareSettings {
    const targetUrlMetadata = bymoLinkViewerData.targetUrlMetadata;

    const socialShareSettingsFromUrlMetadata = targetUrlMetadata ? linkSocialShareSettingsResolveFromUrlMetadata(targetUrlMetadata) : {};

    return linkSocialShareSettingsNormalizeSettingsValues({
        ...socialShareSettingsFromUrlMetadata,
        ...bymoLinkViewerData.socialShareSettings
    })
}

export function linkSocialShareSettingsNormalizeSettingsValues (linkSocialShareSettings: LinkSocialShareSettings) : NormalizedLinkSocialShareSettings {
    return {
        twitterViewType: 'large',
        ...linkSocialShareSettings,
        title: linkSocialShareSettingsNormalizeStringSettingValue(linkSocialShareSettings.title),
        twitterTitle: linkSocialShareSettingsNormalizeStringSettingValue(linkSocialShareSettings.twitterTitle),
        description: linkSocialShareSettingsNormalizeStringSettingValue(linkSocialShareSettings.description),
        twitterDescription: linkSocialShareSettingsNormalizeStringSettingValue(linkSocialShareSettings.twitterDescription),
        socialImage: linkSocialShareSettings.socialImage === null ? undefined : linkSocialShareSettings.socialImage,
        twitterSocialImage: linkSocialShareSettings.twitterSocialImage === null ? undefined : linkSocialShareSettings.twitterSocialImage,
    }
}

function linkSocialShareSettingsNormalizeStringSettingValue (value: string | undefined) {
    if (value !== undefined) {
        const normalizedValue = value.trim();

        return normalizedValue === '' ? undefined : normalizedValue;
    } else {
        return undefined;
    }
}

export function doppeDtoBymoLinkViewerDataIsWithConnectedPage (bymoLinkViewerData: DoppeDtoBymoLinkViewerData) : bymoLinkViewerData is DoppeDtoBymoLinkViewerData.WithConnectedPage {
    return bymoLinkViewerData.bymoPageId !== undefined;
}

export function doppeDtoBymoLinkViewerDataIsWithTargetUrl (bymoLinkViewerData: DoppeDtoBymoLinkViewerData) : bymoLinkViewerData is DoppeDtoBymoLinkViewerData.WithTargetUrl {
    return bymoLinkViewerData.targetUrl !== null;
}