import {arrayRemoveNullValues, OmitStrict, VideoServices} from '@wix/devzai-utils-common';
import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeDtoVideo} from '../../client-server-common/types/doppe-dto-video';
import {
    DoppeExternalVideoProvider,
    doppeExternalVideoProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {WixImageResource} from '@wix/devzai-common-wix';

interface DoppeActionTypeVideoSettingsExternalServiceSettingsBase<TYPE extends VideoServices> {
    videoService: TYPE;
    url: string;
}

export type DoppeActionTypeVideoExternalServiceSettings =
    | OmitStrict<DoppeActionTypeVideoSettingsExternalServiceSettingsBase<any>, 'videoService'> & { videoService: null }
    | DoppeActionTypeVideoSettingsExternalServiceSettingsBase<typeof VideoServices.TikTok>
    | DoppeActionTypeVideoSettingsExternalServiceSettingsBase<typeof VideoServices.Youtube> & {
    autoplay: boolean;
    muteVideo: boolean;
}

export namespace DoppeActionTypeVideo {
    export interface ActionSettings {
        externalVideoUrl: string;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<string>;
        videoButtonText: DoppeHideableValue<string>;
        videoPlayerSettings: {
            autoplay: boolean;
            muteVideo: boolean;
        },
        videoImage: WixImageResource | null;
        videoProvider: DoppeExternalVideoProvider | null,
        videoProviderId: string | null,

    }
}

export const doppeActionTypeVideo = doppeActionTypeDefineMetadata<DoppeActionTypeVideo.ActionSettings>({
    id: 'io.bymo.action/video',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        externalVideoUrl: '',
        videoPlayerSettings: {
            autoplay: true,
            muteVideo: false
        },
        title: doppeHideableValueCreateHidden('Video Title'),
        description: doppeHideableValueCreateHidden('Video Description'),
        videoButtonText: doppeHideableValueCreateHidden('Watch on YouTube'),
        videoImage: null,
        videoProvider: null,
        videoProviderId: null,
    }),
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.videoProvider !== null && actionSettings.videoProviderId !== null ?
                doppeExternalVideoProviderResolveBusinessEntityReference(
                    actionSettings.videoProvider,
                    actionSettings.videoProviderId
                ) :
                null
        ])
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
})

export function doppeActionTypeVideoResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeVideo.ActionSettings,
    _doppeDtoCoupon: DoppeDtoVideo
): DoppeActionTypeVideo.ActionSettings {
    return actionSettings
}