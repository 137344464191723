export function jsonTryParse<T> (
    str: string | null | undefined,
    errorCallback?: (error: any) => void
) : T | undefined {

    if (str === null || str === undefined) {
        return undefined;
    }

    try {
        return JSON.parse(str);
    } catch (error) {

        if (errorCallback) {
            try {
                errorCallback(error)
            } catch (_error) {
                // Ignore
            }
        }

        return undefined;
    }
}

export function jsonStringify<T> (obj: T) : string {
    return JSON.stringify(obj);
}