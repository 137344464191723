import {classes, vars} from './bymo-page-style.st.css';
import {
    doppeColorPaletteResolveColor,
    doppePageFontEnsureSupportedFontSpec,
    DoppePaletteColorType
} from '../../doppe-sdk';
import {
    ArrayValues,
    assertNotNullable,
    colorGetRgba,
    colorResolveLegibleForegroundColor,
    colorWhiten,
    cssResolveRgbColorComponentsForFunctionalNotationFormat,
    evaluateFunction, objectCreateFromEntries,
    objectPick,
    referencesEqualityComparer,
    Values
} from '@wix/devzai-utils-common';
import {useLayoutBinding, useRootWindow} from '@wix/devzai-utils-react';
import React, {useCallback, useContext} from 'react';
import {BymoPageProps} from '../../client-server-common';

const BymoPageStyleVarsNamesWithoutNamespace = [
    'actionWidgetRoundness',
    'mainColor',
    'mainColor10',
    'mainColor20',
    'backgroundColor',
    'pageContentInnerBackground',
    'textLegibleBackgroundColor',
    'whitenBackgroundColor',
    'whitenBackgroundColorLegibleText',
    'backgroundColorRgb',
    'mainTextColor',
    'skipButtonBackgroundColor',
    'skipButtonTextColor',
    'separator',
    'actionButtonTextColor',
    'actionButtonTextColor80',
    'widgetBackgroundColor',
    'widgetInnerSeparator',
    'widgetInnerBackground',
    'widgetMainColor',
    'widgetMainColor60',
    'widgetMainColor80',
    'widgetMainColorLegibleForeground',
    'widgetTextColor',
    'widgetTextColor10',
    'widgetTextColor20',
    'widgetTextColor60',
    'widgetTextColor80',
    'inputBackgroundColor',
    'inputBorderColor',
    'inputTextColor',
    'inputTextColor80',
    'inputPlaceholderColor',
    'errorColor',
    'successColor',
    'topLevelInputBackgroundColor',
    'topLevelInputTextColor',
    'topLevelInputPlaceholderColor',
    'textFontFamily',
    'textFontWeight',
    'headingsFontWeight',
    'doppeViewerColorPopupBackground',
    'doppeViewerColorPopupInnerBackground',
    'doppeViewerColorPopupText',
    'doppeViewerColorPopupTextSecondary',
    'doppeViewerColorPopupMain',
    'doppeViewerColorPopupMain30',
    'doppeViewerColorPopupMain10',
    'doppeViewerColorPopupMainLegibleForeground',
    'doppeViewerColorPopupMain30LegibleForeground',
    'doppeViewerColorPopupMain10LegibleForeground',
    'doppeViewerColorPopupInnerSeparator',
    'doppeViewerColorPopupInputBackgroundColor',
    'doppeViewerColorPopupInputBorderColor',
    'doppeViewerColorPopupInputTextColor',
    'doppeViewerColorPopupInputPlaceholderColor',
] as const;

export const BymoPageStyleVarsNames = objectCreateFromEntries(BymoPageStyleVarsNamesWithoutNamespace.map(name => ([name, name]))) as {
    [name in ArrayValues<typeof BymoPageStyleVarsNamesWithoutNamespace>]: name;
};

export type BymoPageStyleVarsNames = Values<typeof BymoPageStyleVarsNames>;

const BymoPageStyleScopeVarsNamesWithoutNamespace = [
    'bymoPageStyleScopeColorInnerBackground',
    // 'bymoPageStyleScopeColorText',
    'bymoPageStyleScopeMainColor',
    // 'bymoPageStyleScopeMainColorLegibleForeground',
    // 'bymoPageStyleScopeInputBackgroundColor',
    // 'bymoPageStyleScopeInputBorderColor',
    // 'bymoPageStyleScopeInputTextColor',
    // 'bymoPageStyleScopeInputPlaceholderColor',
] as const;

export const BymoPageStyleScopeVarsNames = objectCreateFromEntries(BymoPageStyleScopeVarsNamesWithoutNamespace.map(name => ([name, name]))) as {
    [name in ArrayValues<typeof BymoPageStyleScopeVarsNamesWithoutNamespace>]: name;
};

export type BymoPageStyleScopeVarsNames = Values<typeof BymoPageStyleScopeVarsNames>;

export const bymoPageStyleVars = objectPick(vars, BymoPageStyleVarsNamesWithoutNamespace);

export function bymoPageStyleResolveVars(bymoPageProps: Pick<BymoPageProps, 'colorPalette' | 'textFontFamily' | 'lang'>) : Record<string, string> {
    const {
        colorPalette,
        textFontFamily,
        lang
    } = bymoPageProps;

    const mainColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Main);
    const backgroundColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Background);
    const textColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Text);
    const secondaryColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Secondary);
    const widgetBackgroundColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.WidgetBackground);
    const widgetTextColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.WidgetText);
    const widgetMainColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.WidgetMain);
    const widgetMainColorLegibleForeground = colorResolveLegibleForegroundColor(widgetMainColor);
    const inputBackgroundColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.InputBackground);
    const errorColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Error);
    const successColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Success);
    const inputTextColor = colorResolveLegibleForegroundColor(inputBackgroundColor);
    const mainColorLegible = colorResolveLegibleForegroundColor(mainColor);
    const secondaryColorLegible = colorResolveLegibleForegroundColor(secondaryColor);
    const widgetBackgroundColorLegible = colorResolveLegibleForegroundColor(widgetBackgroundColor);
    const backgroundColorLegible = colorResolveLegibleForegroundColor(backgroundColor);
    const textLegibleBackgroundColor = colorResolveLegibleForegroundColor(textColor);

    const textFontFamilySpec = doppePageFontEnsureSupportedFontSpec(textFontFamily, lang);

    const lightMainColor = colorGetRgba(mainColor, 0.2) ?? mainColor;

    const whitenBackgroundColor = colorWhiten(mainColor, 0.3) ?? lightMainColor;
    const whitenBackgroundColorLegibleText = colorResolveLegibleForegroundColor(whitenBackgroundColor);

    return {
        [bymoPageStyleVars.mainColor]: mainColor,
        [bymoPageStyleVars.mainColor10]: colorGetRgba(mainColor, 0.1) ?? mainColor,
        [bymoPageStyleVars.mainColor20]: colorGetRgba(mainColor, 0.2) ?? mainColor,
        [bymoPageStyleVars.backgroundColor]: backgroundColor,
        [bymoPageStyleVars.pageContentInnerBackground]: assertNotNullable(backgroundColorLegible === 'black' ? colorGetRgba('#000000', 0.05) : colorGetRgba('#ffffff', 0.1)),
        [bymoPageStyleVars.textLegibleBackgroundColor]: textLegibleBackgroundColor,
        [bymoPageStyleVars.whitenBackgroundColor]: whitenBackgroundColor,
        [bymoPageStyleVars.whitenBackgroundColorLegibleText]: whitenBackgroundColorLegibleText,
        [bymoPageStyleVars.backgroundColorRgb]: cssResolveRgbColorComponentsForFunctionalNotationFormat(backgroundColor) ?? `255 255 255`,
        [bymoPageStyleVars.mainTextColor]: textColor,
        [bymoPageStyleVars.skipButtonBackgroundColor]: secondaryColor,
        [bymoPageStyleVars.skipButtonTextColor]: secondaryColorLegible,
        [bymoPageStyleVars.separator]: assertNotNullable(backgroundColorLegible === 'black' ? colorGetRgba('#000000', 0.1) : colorGetRgba('#ffffff', 0.2)),
        [bymoPageStyleVars.actionButtonTextColor]: mainColorLegible,
        [bymoPageStyleVars.actionButtonTextColor80]: colorGetRgba(mainColorLegible, 0.8) ?? mainColorLegible,
        [bymoPageStyleVars.widgetBackgroundColor]: widgetBackgroundColor,
        [bymoPageStyleVars.widgetInnerSeparator]: assertNotNullable(widgetBackgroundColorLegible === 'black' ? colorGetRgba('#000000', 0.1) : colorGetRgba('#ffffff', 0.2)),
        [bymoPageStyleVars.widgetInnerBackground]: assertNotNullable(widgetBackgroundColorLegible === 'black' ? colorGetRgba('#000000', 0.05) : colorGetRgba('#ffffff', 0.1)),
        [bymoPageStyleVars.widgetMainColor]: widgetMainColor,
        [bymoPageStyleVars.widgetMainColor60]: colorGetRgba(widgetMainColor, 0.6) ?? widgetMainColor,
        [bymoPageStyleVars.widgetMainColor80]: colorGetRgba(widgetMainColor, 0.8) ?? widgetMainColor,
        [bymoPageStyleVars.widgetMainColorLegibleForeground]: widgetMainColorLegibleForeground,
        [bymoPageStyleVars.widgetTextColor]: widgetTextColor,
        [bymoPageStyleVars.widgetTextColor10]: colorGetRgba(widgetTextColor, 0.1) ?? widgetTextColor,
        [bymoPageStyleVars.widgetTextColor20]: colorGetRgba(widgetTextColor, 0.2) ?? widgetTextColor,
        [bymoPageStyleVars.widgetTextColor60]: colorGetRgba(widgetTextColor, 0.6) ?? widgetTextColor,
        [bymoPageStyleVars.widgetTextColor80]: colorGetRgba(widgetTextColor, 0.8) ?? widgetTextColor,
        [bymoPageStyleVars.inputBackgroundColor]: inputBackgroundColor,
        [bymoPageStyleVars.inputBorderColor]: widgetTextColor,
        [bymoPageStyleVars.inputTextColor]: inputTextColor,
        [bymoPageStyleVars.inputTextColor80]: colorGetRgba(inputTextColor, 0.8) ?? inputTextColor,
        [bymoPageStyleVars.inputPlaceholderColor]: colorGetRgba(inputTextColor, 0.95) ?? inputTextColor,
        [bymoPageStyleVars.errorColor]: errorColor,
        [bymoPageStyleVars.successColor]: successColor,
        [bymoPageStyleVars.topLevelInputBackgroundColor]: '#fff',
        [bymoPageStyleVars.topLevelInputTextColor]: '#20303C',
        [bymoPageStyleVars.topLevelInputPlaceholderColor]: '#6B6B6B',
        [bymoPageStyleVars.textFontFamily]: `'${textFontFamilySpec.fontFamily}', ${textFontFamilySpec.typefaceSpecification}`,
        [bymoPageStyleVars.textFontWeight]: textFontFamilySpec.textFontWeight,
        [bymoPageStyleVars.headingsFontWeight]: textFontFamilySpec.headingsFontWeight,
        /* Popup Colors */
        ...evaluateFunction(() => {

            // const popupMainColor = mainColor;
            const popupMainColor = '#116DFF'; /* #9271FF */
            const popupMainColor30 = colorWhiten(popupMainColor, 0.3) ?? popupMainColor;
            const popupMainColor10 = colorWhiten(popupMainColor, 0.1) ?? popupMainColor;
            const popupBackgroundColor = '#fff';
            const popupBackgroundColorLegible = colorResolveLegibleForegroundColor(popupBackgroundColor);

            return {
                [bymoPageStyleVars.doppeViewerColorPopupBackground]: popupBackgroundColor,
                [bymoPageStyleVars.doppeViewerColorPopupInnerBackground]: 'rgba(0, 0, 0, 0.05)', /* TODO: Should be calculated from doppeViewerColorPopupBackground */
                [bymoPageStyleVars.doppeViewerColorPopupText]: '#333',
                [bymoPageStyleVars.doppeViewerColorPopupTextSecondary]: '#868AA5',
                [bymoPageStyleVars.doppeViewerColorPopupMain]: popupMainColor,
                [bymoPageStyleVars.doppeViewerColorPopupMain30]: popupMainColor30,
                [bymoPageStyleVars.doppeViewerColorPopupMain10]: popupMainColor10,
                [bymoPageStyleVars.doppeViewerColorPopupMainLegibleForeground]: colorResolveLegibleForegroundColor(popupMainColor, '#000', '#fff'),
                [bymoPageStyleVars.doppeViewerColorPopupMain30LegibleForeground]: colorResolveLegibleForegroundColor(popupMainColor30, '#000', '#fff'),
                [bymoPageStyleVars.doppeViewerColorPopupMain10LegibleForeground]: colorResolveLegibleForegroundColor(popupMainColor10, '#000', '#fff'),
                [bymoPageStyleVars.doppeViewerColorPopupInnerSeparator]: assertNotNullable(popupBackgroundColorLegible === 'black' ? colorGetRgba('#000000', 0.1) : colorGetRgba('#ffffff', 0.2)),
                [bymoPageStyleVars.doppeViewerColorPopupInputBackgroundColor]: '#F2F2F2',
                [bymoPageStyleVars.doppeViewerColorPopupInputBorderColor]: '#F2F2F2',
                [bymoPageStyleVars.doppeViewerColorPopupInputTextColor]: '#20303C',
                [bymoPageStyleVars.doppeViewerColorPopupInputPlaceholderColor]: '#6B6B6B',
            }
        }),
    }

}

export const BymoPageLayout = {
    Narrow: 'narrow',
    Wide: 'wide'
} as const;

export type BymoPageLayout = Values<typeof BymoPageLayout>;

export const BymoPageLayoutSizes = {
    WideLayoutContentWidth: 500,
    WideLayoutMinWidth: 750
} as const;

export type BymoPageLayoutSizes = Values<typeof BymoPageLayoutSizes>;

export function useBymoPageLayout() {

    const rootWindow = useRootWindow();

    const windowWidth = useLayoutBinding(
        useCallback(() => (rootWindow ?? window).innerWidth, [rootWindow]),
        referencesEqualityComparer
    )

    return windowWidth !== null ? bymoPageLayoutResolveFromViewportWidth(windowWidth) : BymoPageLayout.Wide;
}

export function bymoPageLayoutResolveFromViewportWidth(viewportWidth: number) {
    if (viewportWidth < BymoPageLayoutSizes.WideLayoutMinWidth) {
        return BymoPageLayout.Narrow;
    } else {
        return BymoPageLayout.Wide;
    }
}

export const BymoPageStyleScope = {
    Popup: 'popup',
    Document: 'document',
    PageContent: 'pageContent',
    Card: 'card'
} as const;

export type BymoPageStyleScope = Values<typeof BymoPageStyleScope>;

export const BymoPageStyleScopeContext = React.createContext<BymoPageStyleScope>(BymoPageStyleScope.Document);

export function useBymoPageStyleScope () {
    return useContext(BymoPageStyleScopeContext)
}

export function bymoPageStyleGetStyleScopeClassName (styleScope: BymoPageStyleScope) {
    switch (styleScope) {
        case BymoPageStyleScope.PageContent: {
            return classes.pageContentScope
        }
        case BymoPageStyleScope.Card: {
            return classes.cardScope;
        }
        case BymoPageStyleScope.Popup: {
            return classes.popupScope
        }
        case BymoPageStyleScope.Document: {
            return classes.documentScope
        }
    }
}

export const BymoPageStyleContext = React.createContext<Record<string, string> | null>(null);

export function useBymoPageStyleVarValue (varName: BymoPageStyleVarsNames) {
    const bymoPageStyle = assertNotNullable(useContext(BymoPageStyleContext), `BymoPageStyleContext isn't provided`);

    return assertNotNullable(bymoPageStyle[bymoPageStyleVars[varName]])
}

export function useBymoPageStyleScopeVarValue (scopeVarName: BymoPageStyleScopeVarsNames) {
    return useBymoPageStyleVarValue(
        bymoPageStyleResolveVarNameInScope(useBymoPageStyleScope(), scopeVarName)
    )
}

export function bymoPageStyleResolveVarNameInScope (styleScope: BymoPageStyleScope, scopeVarName: BymoPageStyleScopeVarsNames) : BymoPageStyleVarsNames {

    switch (scopeVarName) {
        case BymoPageStyleScopeVarsNames.bymoPageStyleScopeColorInnerBackground: {
            switch (styleScope) {
                case BymoPageStyleScope.Card: {
                    return BymoPageStyleVarsNames.widgetInnerBackground;
                }
                case BymoPageStyleScope.Popup: {
                    return BymoPageStyleVarsNames.doppeViewerColorPopupInnerBackground;
                }
                case BymoPageStyleScope.PageContent:
                case BymoPageStyleScope.Document: {
                    return BymoPageStyleVarsNames.pageContentInnerBackground;
                }
            }
            break;
        }
        // case BymoPageStyleScopeVarsNames.bymoPageStyleScopeColorText: {
        //
        // }
        case BymoPageStyleScopeVarsNames.bymoPageStyleScopeMainColor: {
            switch (styleScope) {
                case BymoPageStyleScope.Card: {
                    return BymoPageStyleVarsNames.widgetMainColor;
                }
                case BymoPageStyleScope.Popup: {
                    return BymoPageStyleVarsNames.doppeViewerColorPopupMain;
                }
                case BymoPageStyleScope.PageContent:
                case BymoPageStyleScope.Document: {
                    return BymoPageStyleVarsNames.mainColor;
                }
            }
            break;
        }
        // case BymoPageStyleScopeVarsNames.bymoPageStyleScopeMainColorLegibleForeground: {
        //
        // }
        // case BymoPageStyleScopeVarsNames.bymoPageStyleScopeInputBackgroundColor: {
        //
        // }
        // case BymoPageStyleScopeVarsNames.bymoPageStyleScopeInputBorderColor: {
        //
        // }
        // case BymoPageStyleScopeVarsNames.bymoPageStyleScopeInputTextColor: {
        //
        // }
        // case BymoPageStyleScopeVarsNames.bymoPageStyleScopeInputPlaceholderColor: {
        //
        // }
    }
}