import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {DoppeActionTypeBlogPost} from '../doppe-action-type-blog-post/doppe-action-type-blog-post';
import {wixMediaResourceReplaceExternalImage} from "@wix/devzai-common-wix";
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    DoppeBlogPostsExternalDataSource,
    doppeBlogPostsExternalDataSourceResolveBusinessEntityReferences,
    DoppeExternalBlogPostProvider,
    doppeExternalBlogPostProviderResolveBusinessEntityReference,
    DoppeListActionSettings
} from "../../client-server-common";
import {DoppeDtoBlogPost, doppeDtoBlogPostGetMedia} from "../../client-server-common/types/doppe-dto-blog-post";
import {doppeHideableValueCreateHidden} from "../../client-server-common/types/doppe-hideable-value";


export namespace DoppeActionTypeArticlesList {

    export interface ArticleSettings extends DoppeActionTypeBlogPost.ActionSettings {
        id: string;
        blogPostTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeBlogPostsExternalDataSource>
    {
        articles: ArticleSettings[];
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowBlogPostDate: boolean;
        previewShowBlogWebsite: boolean;
        previewBlogPostButtonText: string;
    }
}


export const doppeActionTypeArticlesList = doppeActionTypeDefineMetadata<DoppeActionTypeArticlesList.ActionSettings>({
    id: 'io.bymo.action/articles-list',
    resolveActionSettings: action => {

        const {
            articles = [],
            ...restSettings
        } = action.settings;

        return {
            articles: articles.map(article => {
                return {
                    blogPostProvider: null,
                    blogPostProviderId: null,
                    ...article as PartiallyOptional<DoppeActionTypeArticlesList.ArticleSettings, 'blogPostProvider' | 'blogPostProviderId'>
                }
            }) ?? [],
            layout: DoppeActionLayout.Column,
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
            showButton: false,
            buttonText: 'Visit My Blog',
            buttonUrl: 'https://www.website.com',
            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            previewShowBlogPostDate: true,
            previewShowBlogWebsite: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewBlogPostButtonText: 'Read Now',
            externalDataSource: null,
            externalDataSourceItemsCount: 4,
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.articles.map(article => {
            return article.blogPostImages ?? []
        }));
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeBlogPostsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.articles, (article, skip) => {
                return article.blogPostProvider !== null && article.blogPostProviderId !== null ?
                    doppeExternalBlogPostProviderResolveBusinessEntityReference(
                        article.blogPostProvider,
                        article.blogPostProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : actionSettings.articles.length;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const article of actionSettings.articles) {
                if (article.blogPostImages) {
                    article.blogPostImages = article.blogPostImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
});

export function convertDoppeWixBlogPostToBlogPostSettings(blogPost: DoppeDtoBlogPost): DoppeActionTypeArticlesList.ArticleSettings {

    return {
        blogPostImages: doppeDtoBlogPostGetMedia(blogPost),
        blogPostLink: blogPost.blogPostPageUrl ?? '',
        // TODO: @amirga - is the default title necessary? It seems that it shouldn't be undefined, and in case
        // TODO: it does, I guess that an empty title is better in this case, since otherwise the text
        // TODO: "Blog Post name" will appear and the user won't be able to modify it.
        blogPostTitle: blogPost.title ?? 'Blog Post name',
        description: blogPost.excerpt,
        blogPostProvider: DoppeExternalBlogPostProvider.Wix,
        blogPostProviderId: blogPost.id,
        // The following setting is hidden since in a list it stands for a custom button text,
        // and in case of external data source items, each item has no custom button text.
        blogPostButtonText: doppeHideableValueCreateHidden('Read Now'),
        blogPostDate: blogPost.firstPublishedDate ?? new Date(0),
        showBlogWebsite: true,
        id: blogPost.id,
    }
}