import {DoppeViewerAppClientServiceBase} from './doppe-viewer-app-client-service-base';
import {cachedValueCreate} from '@wix/devzai-utils-common';
import {cookieParse, cookieUpdate, jsonTryParse, validateType, Values} from "@wix/devzai-utils-common";

const CookieName = 'consent-policy';

export const ConsentPreferencesKeyValues = {
    ess: 'ess',
    func: 'func',
    anl: 'anl',
    adv: 'adv',
} as const;

export type ConsentPreferencesKeys = Values<typeof ConsentPreferencesKeyValues>;
export type ConsentPreferences = Record<ConsentPreferencesKeys, 0 | 1>;

interface ConsentCookie extends ConsentPreferences {
    ts: number;
}

export const approveAllConsentPreferences: ConsentPreferences= {
    ess: 1,
    func: 1,
    anl: 1,
    adv: 1,
};

export const denyAllConsentPreferences: ConsentPreferences = {
    ess: 1,
    func: 0,
    anl: 0,
    adv: 0,
};

export const defaultConsentPreferences = denyAllConsentPreferences;

interface ConsentConfigParams {
    path: string;
}
const defaultConsentConfigParams: ConsentConfigParams = { path: '/'};

export class DoppeViewerAppCookieConsentService extends DoppeViewerAppClientServiceBase {
    private cachedCookieConsent = cachedValueCreate<ConsentCookie | null>(
        () => this.loadConsentFromCookieStore());

    private setConsentCookie(consentCookiePreferences: ConsentPreferences, config: ConsentConfigParams) {
        const consentCookie = {
            ...consentCookiePreferences,
            ts: new Date().getTime()
        };

        this.storeConsentInCookieStore(consentCookie, config);
        this.cachedCookieConsent.setValue(consentCookie);
    }

    private storeConsentInCookieStore(consent: ConsentCookie, config: ConsentConfigParams) {
        document.cookie = cookieUpdate(CookieName, JSON.stringify(consent), { path: config.path || '/' });
    }

    private loadConsentFromCookieStore(): ConsentCookie | null {
        try {
            const consentCookieString = cookieParse(document.cookie)?.[CookieName];
            const cookieConsent = jsonTryParse<ConsentCookie>(consentCookieString);

            if (cookieConsent && this.validateConsentCookie(cookieConsent)) {
                return cookieConsent;
            }
        } catch (error) {
            console.error('Error parsing cookie consent:', error);
        }

        return null;
    }

    private validateConsentCookie(consentCookie: any): consentCookie is ConsentCookie {
        return validateType<ConsentCookie>(consentCookie, assert => {
            assert.isObject({
                ess: assert => assert.isOneOfValues([0, 1]),
                func: assert => assert.isOneOfValues([0, 1]),
                anl: assert => assert.isOneOfValues([0, 1]),
                adv: assert => assert.isOneOfValues([0, 1]),
                ts: assert => assert.isNumber(),
            })
        })
    }

    public get cookieConsent () { return this.cachedCookieConsent.getValue() }

    public approveAll(config: ConsentConfigParams = defaultConsentConfigParams) {
        this.setConsentCookie(approveAllConsentPreferences, config);
    }

    public denyAll(config: ConsentConfigParams = defaultConsentConfigParams) {
        this.setConsentCookie(denyAllConsentPreferences, config);
    }

    public partialApprove(newConsent: ConsentPreferences, config: ConsentConfigParams = defaultConsentConfigParams) {
        this.setConsentCookie({
            ...denyAllConsentPreferences,
            ...newConsent,
        }, config);
    }
}
