import {keyCodeGetFromNativeEvent} from '@wix/devzai-utils-dom';

export function keyCodeGetFromReactEvent (event: React.KeyboardEvent) {
    return keyCodeGetFromNativeEvent(event.nativeEvent);
}

export type ReactPointerLikeEvent = React.MouseEvent | React.TouchEvent;

export function reactPointerLikeEventIsTouchEvent(event: ReactPointerLikeEvent): event is React.TouchEvent {
    return (event as React.TouchEvent).touches !== undefined;
}

export function reactPointerLikeEventIsMouseEvent(event: ReactPointerLikeEvent): event is React.MouseEvent {
    return !reactPointerLikeEventIsTouchEvent(event);
}