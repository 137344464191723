import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {classes, vars} from './bymo-page-viewer.st.css';
import {BymoPageContextProps, BymoPageContextPropsContext} from '../../doppe-sdk';
import {
    BymoPageProps,
    BymoPagePropsContext,
    BymoPagePropsForViewer,
    bymoPagePropsResolveTextFontFamilyStyleSheetUrl,
    DoppeBiEmptyTargetUrl
} from '../../client-server-common';
import {
    BymoPageViewerMode,
    bymoPageViewerModeIsPreview,
    useBymoPageViewerContext
} from '../bymo-page-viewer-context/bymo-page-viewer-context';
import {useDoppeViewerClientServices} from '../../client/doppe-user-app/doppe-viewer-client-services';
import {
    DoppeViewerLangDictionaryContext,
    doppeViewerLangDictionaryCreate
} from '../doppe-viewer-lang-dictionary/doppe-viewer-lang-dictionary';
import {
    ListViewIdsProvider,
    ModalsScope,
    SsrSafeHtmlIdsProvider,
    useLayoutBinding,
    useRootWindow,
    useWindowDependantMemoWithDefaultValue
} from "@wix/devzai-utils-react";
import {DomEventListener, domGetScrollbarSize, domLoadCSS} from "@wix/devzai-utils-dom";
import {referencesEqualityComparer} from "@wix/devzai-utils-common";
import {BymoPageStyleContext, bymoPageStyleResolveVars} from "../bymo-page-style/bymo-page-style";

export const BymoPageViewer = React.memo(function BymoPageViewer(props: BymoPageViewer.Props) {
    const {
        bymoPageComponentType,
        bymoPagePropsForViewer,
        bymoPageContextProps
    } = props;

    const pageViewerContext = useBymoPageViewerContext();
    const clientServices = useDoppeViewerClientServices();

    useEffect(() => {
        if (pageViewerContext.mode === BymoPageViewerMode.Live) {
            clientServices.viewerAnalyticsClient.logEvent('uouPageViewed', {
                pageId: pageViewerContext.bymoPageId,
                hypeUserId: pageViewerContext.doppeUserId,
                linkId: pageViewerContext.linkId,
                destinationUrl: bymoPageContextProps.targetUrl ?? DoppeBiEmptyTargetUrl,
                pageType: pageViewerContext.pageType
            });
        }
    }, []);

    const rootWindow = useRootWindow();

    const textFontFamilyStyleSheetUrl = useMemo(() => {
        return bymoPagePropsResolveTextFontFamilyStyleSheetUrl(bymoPagePropsForViewer);
    }, [bymoPagePropsForViewer]);

    useEffect(() => {
        if (pageViewerContext.mode !== BymoPageViewerMode.Live) {
            domLoadCSS(textFontFamilyStyleSheetUrl, {rootWindow: rootWindow})
                .catch(error => {
                    console.error(`Failed loading font css`, error)
                })
        }
    }, [textFontFamilyStyleSheetUrl])

    const hideBrowserScrollbar = bymoPageViewerModeIsPreview(pageViewerContext.mode);
    useEffect(() => {
        const currentWindow = (rootWindow ?? window);

        currentWindow.document.documentElement.classList.toggle(
            classes.hideBrowserScrollbar,
            hideBrowserScrollbar || (currentWindow.self !== currentWindow.top)
        );

    }, [hideBrowserScrollbar, rootWindow])

    const doppeViewerLangDictionary =
        useMemo(() => doppeViewerLangDictionaryCreate(bymoPagePropsForViewer.lang), [bymoPagePropsForViewer.lang]);

    const bymoPageNamespacedStyleVarsValues = useMemo(() => {
        return bymoPageStyleResolveVars({
            lang: bymoPagePropsForViewer.lang,
            colorPalette: bymoPagePropsForViewer.colorPalette,
            textFontFamily: bymoPagePropsForViewer.textFontFamily
        })
    }, [bymoPagePropsForViewer.lang, bymoPagePropsForViewer.colorPalette, bymoPagePropsForViewer.textFontFamily]);

    const lastScrollTopRef = useRef<number>(0);

    const isIos = useWindowDependantMemoWithDefaultValue(
        window => /iPhone|iPad|iPod/.test(window.navigator.userAgent) || true,
        false,
        []
    );

    const [modalIsShown, setModalIsShown] = useState(false);

    const visualViewportHeight = useLayoutBinding(
        useCallback(() => (rootWindow ?? window).visualViewport?.height, [rootWindow]),
        referencesEqualityComparer,
        isIos && modalIsShown
    );

    const isModalShownOnIos = isIos && modalIsShown;

    useEffect(() => {
        if (isModalShownOnIos) {
            const targetWindow = rootWindow ?? window;

            let ignoreScrolling = false;
            const touchStartBinding = DomEventListener.bind(targetWindow.document, 'touchstart', () => {
                ignoreScrolling = true;
            })

            let scheduledScroll: any | undefined = undefined;
            const scheduleScroll = () => {
                clearTimeout(scheduledScroll);
                scheduledScroll = setTimeout(() => {
                    targetWindow.scrollTo({
                        top: 0
                    })
                }, 16)
            }


            const touchEndBinding = DomEventListener.bind(targetWindow.document, 'touchend', () => {
                ignoreScrolling = false;
                scheduleScroll();
            })

            const binding = DomEventListener.bind(targetWindow, 'scroll', () => {
                if (!ignoreScrolling) {
                    scheduleScroll();
                }
            })

            return () => {
                clearTimeout(scheduledScroll);
                binding.dispose();
                touchStartBinding.dispose();
                touchEndBinding.dispose();
            }
        } else {
            return undefined;
        }
    }, [isModalShownOnIos, rootWindow])

    useLayoutEffect(() => {
        (rootWindow ?? window).document.documentElement.style.setProperty(
            vars.visualViewportHeight,
            isIos && visualViewportHeight ? `${visualViewportHeight}px` : `100%`
        );
    }, [visualViewportHeight, isIos])

    return (
        <BymoPageStyleContext.Provider value={bymoPageNamespacedStyleVarsValues}>
            <DoppeViewerLangDictionaryContext.Provider
                value={doppeViewerLangDictionary}
            >
                <BymoPageContextPropsContext.Provider
                    value={bymoPageContextProps}
                >
                    <BymoPagePropsContext.Provider
                        value={bymoPagePropsForViewer}
                    >
                        <ListViewIdsProvider>
                            <SsrSafeHtmlIdsProvider>
                                <ModalsScope
                                    targetContainer={() => (rootWindow ?? window).document.body}
                                    zIndex={999}
                                    modalsClassName={classes.modalView}
                                    onOpenModalsChange={(openModals) => {

                                        const currentWindow = (rootWindow ?? window);
                                        const document = currentWindow.document;
                                        const siteMain = document.getElementById('SITE_MAIN');

                                        if (openModals.length > 0) {
                                            const lastScrollTop = lastScrollTopRef.current = document.documentElement.scrollTop;

                                            document.documentElement.style.setProperty(vars.scrollbarSize, `${domGetScrollbarSize()}px`);
                                            document.documentElement.style.setProperty(vars.verticalShift, `-${lastScrollTop}px`);
                                            document.documentElement.classList.toggle(classes.modalShown, true);

                                            if (siteMain) {
                                                siteMain.scrollTop = lastScrollTop;
                                            }

                                            if (isIos) {
                                                const visualViewportHeight = currentWindow.visualViewport?.height
                                                document.documentElement.style.setProperty(
                                                    vars.visualViewportHeight,
                                                    isIos && visualViewportHeight ? `${visualViewportHeight}px` : `100%`
                                                )
                                            }

                                            setModalIsShown(true);
                                        } else {

                                            if (siteMain) {
                                                siteMain.scrollTop = 0;
                                            }

                                            document.documentElement.classList.toggle(classes.modalShown, false);
                                            document.documentElement.scrollTop = lastScrollTopRef.current;
                                            setModalIsShown(false);
                                        }

                                        // document.documentElement.style.setProperty(vars.scrollbarSize, `${domGetScrollbarSize()}px`);
                                        // document.documentElement.classList.toggle(classes.modalsShown, openModals.find(modalInfo => modalInfo.isFullScreen) !== undefined);
                                    }}
                                >
                                    {React.createElement(bymoPageComponentType, bymoPagePropsForViewer)}
                                </ModalsScope>
                            </SsrSafeHtmlIdsProvider>
                        </ListViewIdsProvider>
                    </BymoPagePropsContext.Provider>
                </BymoPageContextPropsContext.Provider>
            </DoppeViewerLangDictionaryContext.Provider>
        </BymoPageStyleContext.Provider>
    )
});

export namespace BymoPageViewer {

    export interface Props {
        bymoPageComponentType: React.ComponentType<BymoPageProps<any>>;
        bymoPagePropsForViewer: BymoPagePropsForViewer;
        bymoPageContextProps: BymoPageContextProps;
    }
}