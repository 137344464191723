import {useEffect, useLayoutEffect, useRef} from 'react';
import {useForceUpdate, useRenderState} from '@wix/devzai-utils-react';
import {
    arrayOrderByDesc,
    arrayRemove,
    assertNotNullable,
    compareNumbers,
    evaluateFunction,
    objectMapValues,
    objectShallowEqual,
    tsTypeAssert,
    urlGetBaseUrl,
    urlGetPathname,
    urlIsAbsolute,
    urlModifyQueryParams,
    urlParseQueryString, UrlQueryParamsSpec,
    Values
} from '@wix/devzai-utils-common';
import {browserHistoryCreate, DomEventListener, elementClosest, History} from '@wix/devzai-utils-dom';

const BrowserHistoryLinkActionAttributeName = 'data-history' as const;

export const BrowserHistoryLinkAction = {
    HistoryPush: 'push',
    HistoryReplace: 'replace',
    BrowserDefault: 'default',
    OpenInNewWindow: 'new-window'
} as const;

export namespace BrowserHistory {

    export type UserLocationState = unknown;
    export type LinkAction = Values<typeof BrowserHistoryLinkAction>;

    export type InterceptionFunction = (location: History.Location<LocationState>, action: History.Action, proceedCallback: () => void) => (false | BrowserHistoryInterceptionAction);

    export interface InterceptionEntry {
        interceptionFunction: InterceptionFunction;
        priority: number;
    }

    export interface LinkSpec {
        href: string;
        action: LinkAction;
    }

    export interface LocationState {
        userState?: UserLocationState;
        previousPath?: string;
    }

    export type InitialPathRewriteFunction =
        (location: History.Location<LocationState>) => string | false;

    export interface NavigationEvent {
        type: 'push' | 'replace';
        path: string;
        previousPath: string;
        preventNavigation () : void;
    }
}

export function linkSpecCreateOpenInNewWindow (href: string) {
    return linkSpecCreate(href, BrowserHistoryLinkAction.OpenInNewWindow)
}

export function linkSpecCreatePush (href: string) {
    return linkSpecCreate(href, BrowserHistoryLinkAction.HistoryPush)
}

export function linkSpecCreateReplace (href: string) {
    return linkSpecCreate(href, BrowserHistoryLinkAction.HistoryReplace)
}

export function linkSpecCreate (href: string, action: BrowserHistory.LinkAction) : BrowserHistory.LinkSpec {
    return {
        href: href,
        action: action
    }
}

export function browserHistoryGeneratePushLinkAttributes(href: string) {
    return browserHistoryGenerateLinkAttributes(linkSpecCreatePush(href))
}

export function browserHistoryGenerateLinkAttributes (linkSpec: BrowserHistory.LinkSpec | undefined) {

    if (linkSpec === undefined) {
        return undefined;
    } else {
        const {
            action,
            href
        } = linkSpec

        if (action === BrowserHistoryLinkAction.BrowserDefault) {
            return {
                href: href
            };
        } else if (action === BrowserHistoryLinkAction.OpenInNewWindow) {
            return {
                href: href,
                target: '_blank'
            }
        } else {
            return {
                href: href,
                [BrowserHistoryLinkActionAttributeName]: action
            }
        }
    }
}

class BrowserHistorySingleton {

    private _browserHistory: History<BrowserHistory.LocationState> | undefined = undefined;
    private initialPathRewriteFunction?: BrowserHistory.InitialPathRewriteFunction = undefined;
    private onPush?: (navigationEvent: BrowserHistory.NavigationEvent) => void = undefined;
    private onReplace?: (navigationEvent: BrowserHistory.NavigationEvent) => void = undefined;

    private activeInterceptions: BrowserHistory.InterceptionEntry[] = [];

    private get browserHistory () {
        return assertNotNullable(this._browserHistory, `BrowserHistory wasn't initialized`);
    }

    public initialize (
        options: {
            rewriteInitialPath?: BrowserHistory.InitialPathRewriteFunction;
            onPush?: (navigationEvent: BrowserHistory.NavigationEvent) => void;
            onReplace?: (navigationEvent: BrowserHistory.NavigationEvent) => void;
        } = {}
    ) {

        const {
            rewriteInitialPath,
            onPush,
            onReplace
        } = options;

        this.initialPathRewriteFunction = rewriteInitialPath;
        this.onPush = onPush;
        this.onReplace = onReplace;

        const browserHistory = this._browserHistory = browserHistoryCreate<BrowserHistory.LocationState>({
            getUserConfirmation: (_, callback) => {
                callback(false);
            }
        });

        if (!browserHistory.location.state) {

            const initialPathRewriteFunction = this.initialPathRewriteFunction;

            let pathToRewrite: string | undefined;

            if (initialPathRewriteFunction) {
                const rewrittenPath = initialPathRewriteFunction(browserHistory.location);

                if (rewrittenPath !== false) {
                    pathToRewrite = rewrittenPath;
                }
            }

            if (pathToRewrite) {
                browserHistory.push(pathToRewrite, {});
            } else {
                browserHistory.push(browserHistory.location.pathname + browserHistory.location.search, {});
            }
        }

        browserHistory.listen((location, action) => {
            if (action === 'POP' && location.state === undefined) {
                browserHistory.goBack();
            }
        })

        new DomEventListener(document, 'click', (event: MouseEvent) => {

            if (event.ctrlKey || event.metaKey) {
                return;
            }

            const eventTarget = event.target;
            if (eventTarget) {
                const linkElement = elementClosest(eventTarget as Element, 'a[href]', true);
                if (linkElement) {
                    const historyAttrValue = linkElement.getAttribute(BrowserHistoryLinkActionAttributeName);

                    if (historyAttrValue) {

                        const href = evaluateFunction(() => {
                            const hrefAttribute = assertNotNullable(linkElement.getAttribute('href'))

                            if (urlIsAbsolute(hrefAttribute) && urlGetBaseUrl(hrefAttribute) === urlGetBaseUrl(location.href)) {
                                return urlGetPathname(hrefAttribute);
                            } else {
                                return hrefAttribute;
                            }
                        });

                        if (historyAttrValue === BrowserHistoryLinkAction.HistoryPush) {
                            if (urlIsAbsolute(href)) {
                                location.href = href;
                            } else {
                                this.push(href);
                            }
                        } else if (historyAttrValue === BrowserHistoryLinkAction.HistoryReplace) {
                            if (urlIsAbsolute(href)) {
                                location.replace(href);
                            } else {
                                this.replace(href);
                            }
                        } else {
                            console.warn(`Unknown 'history' attribute value '${historyAttrValue}'`);
                        }

                        event.preventDefault();
                        event.stopPropagation();
                    }
                }
            }
        }).activate();

        this.browserHistory.block((location, action) => {

            const result = evaluateFunction(() => {

                const proceedCallback = () => {
                    if (action === 'POP') {
                        this.goBack();
                    } else if (action === 'PUSH') {
                        this.push(location.pathname)
                    } else if (action === 'REPLACE') {
                        this.replace(location.pathname)
                    }
                }

                const sortedEntries = arrayOrderByDesc(this.activeInterceptions, entry => entry.priority, compareNumbers);

                for (const entry of sortedEntries) {
                    const interceptionResult = entry.interceptionFunction(location, action, proceedCallback);

                    if (interceptionResult !== false) {
                        return interceptionResult;
                    }
                }

                return false;
            })

            if (result === false) {
                return undefined;
            } else {
                result.action();

                return 'block';
            }
        });
    }

    public handleLinkSpec (linkSpec: BrowserHistory.LinkSpec) {

        switch (linkSpec.action) {
            case BrowserHistoryLinkAction.HistoryPush: {
                this.push(linkSpec.href);
                break;
            }
            case BrowserHistoryLinkAction.HistoryReplace: {
                this.replace(linkSpec.href);
                break;
            }
            case BrowserHistoryLinkAction.BrowserDefault: {
                location.href = linkSpec.href;
                break;
            }
            case BrowserHistoryLinkAction.OpenInNewWindow: {
                window.open(linkSpec.href, '_blank', 'noopener,noreferrer');
                break;
            }
            default: {
                throw new Error(`Unknown action '${linkSpec.action}'`)
            }
        }
    }

    public get location () {
        return this.browserHistory.location;
    }

    goBack () : void {
        this.browserHistory.goBack();
    }

    push (path: string, userState?: BrowserHistory.UserLocationState): boolean {

        const previousPath = this.location.pathname;

        const navigationEvent = new BrowserHistoryNavigationEventInternal({
            path: path,
            previousPath: previousPath,
            type: 'push'
        });

        this.onPush?.(navigationEvent);

        if (!navigationEvent.wasNavigationPrevented()) {
            this.browserHistory.push(path, {
                previousPath: previousPath,
                userState: userState
            });

            return true;
        } else {
            return false;
        }
    }

    replacePath (path: string) : void {
        history.replaceState(history.state, '', path);
    }

    replace (path: string, userState?: BrowserHistory.UserLocationState): void {

        const previousPath = this.location.pathname;

        const navigationEvent = new BrowserHistoryNavigationEventInternal({
            path: path,
            previousPath: previousPath,
            type: 'replace'
        });

        this.onReplace?.(navigationEvent);

        if (!navigationEvent.wasNavigationPrevented()) {
            this.browserHistory.replace(path, {
                previousPath: previousPath,
                userState: userState
            });
        }
    }

    listen (listener: History.LocationListener<BrowserHistory.LocationState>): History.UnregisterCallback {
        return this.browserHistory.listen(listener);
    }

    interceptNavigation (
        interceptionFunction: BrowserHistory.InterceptionFunction,
        priority = 0
    ) {
        const entry = tsTypeAssert<BrowserHistory.InterceptionEntry>({
            interceptionFunction: (location, action, proceedCallback) => {
                return interceptionFunction(location, action, () => {
                    unregisterCallback();
                    proceedCallback();
                })
            },
            priority: priority
        });

        this.activeInterceptions.push(entry);

        const unregisterCallback = () => {
            arrayRemove(this.activeInterceptions, entry);
        };

        return unregisterCallback;
    }

    public interceptionAction (action: () => void) {
        return new BrowserHistoryInterceptionAction(action);
    }

    public createUseBrowserHistoryLocationHook () {
        return () => {

            const forceUpdate = useForceUpdate();

            useEffect(() => {

                const unlisten = this.listen(() => {
                    forceUpdate();
                });

                return () => {
                    unlisten();
                }

            }, [forceUpdate]);

            return this.location;
        }
    }
}

export const BrowserHistory = new BrowserHistorySingleton();

class BrowserHistoryInterceptionAction {

    public readonly action;

    constructor(action: () => void) {
        this.action = action;
    }

}

class BrowserHistoryNavigationEventInternal implements BrowserHistory.NavigationEvent {

    public readonly path;
    public readonly previousPath;
    public readonly type;
    private prevented = false;

    constructor (
        options: {
            path: BrowserHistory.NavigationEvent['path'];
            previousPath: BrowserHistory.NavigationEvent['previousPath'];
            type: BrowserHistory.NavigationEvent['type'];
        }
    ) {
        this.path = options.path;
        this.type = options.type;
        this.previousPath = options.previousPath;
    }

    public preventNavigation () {
        this.prevented = true;
    }

    public wasNavigationPrevented () {
        return this.prevented;
    }
}

export function useUnsavedChangesConfirmation (
    hasChanges: boolean,
    interceptionFunc: (proceedFunc: () => void) => void
) {
    const renderState = useRenderState({
        interceptionFunc: interceptionFunc
    })

    useLayoutEffect(() => {
        const disposeBrowserHistoryInterception = BrowserHistory.interceptNavigation((_location, _action, proceed) => {

            if (hasChanges) {
                return BrowserHistory.interceptionAction(() => {
                    renderState.current.interceptionFunc(proceed)
                });
            } else {
                return false;
            }
        })

        return () => {
            disposeBrowserHistoryInterception();
        }
    }, [hasChanges, renderState]);
}

export function useBrowserHistoryQueryParamsBinding<T extends string> (
    boundQueryParams: UrlQueryParamsSpec<T>,
    onParamsChanged: (params: Partial<Record<T, string>>) => void
) {

    const renderState = useRenderState({
        onParamsChanged
    })

    const initializedRef = useRef<boolean>(false);
    const queryParamsValuesRef = useRef<Record<T, string | null>>(boundQueryParams);

    if (!objectShallowEqual(queryParamsValuesRef.current, boundQueryParams)) {
        queryParamsValuesRef.current = boundQueryParams;
    }

    useEffect(() => {

        if (initializedRef.current) {
            const currentPathWithSearch = location.pathname + location.search;
            const updatedPath = urlModifyQueryParams(currentPathWithSearch, params => ({
                ...params,
                ...queryParamsValuesRef.current
            }), true);

            if (currentPathWithSearch !== updatedPath) {
                BrowserHistory.replacePath(updatedPath)
            }
        }

    }, [queryParamsValuesRef.current]);

    useLayoutEffect(() => {
        const parsedQueryString = urlParseQueryString(location.search) as Partial<Record<T, string>>;

        queryParamsValuesRef.current = objectMapValues(queryParamsValuesRef.current, (_value, key) => parsedQueryString[key] ?? null);
        initializedRef.current = true;

        renderState.current.onParamsChanged(parsedQueryString)
    }, [renderState]);
}