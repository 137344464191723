import {ArrayOrSingle, ArrayValues} from "../common-types";
import {evaluateFunction} from "../evaluable";

const localesArr = [
    'am-AM',
    'ar-AE',
    'ar-BH',
    'ar-DZ',
    'ar-LB',
    'ar-EG',
    'ar-IQ',
    'ar-JO',
    'ar-KW',
    'ar-LY',
    'ar-MA',
    'ar-OM',
    'ar-PS',
    'ar-SA',
    'ar-SY',
    'ar-TN',
    'az-AZ',
    'bs-BA',
    'be-BY',
    'bg-BG',
    'bn-BD',
    'ca-AD',
    'cs-CZ',
    'da-DK',
    'de-DE',
    'de-AT',
    'de-CH',
    'de-LU',
    'dv-MV',
    'el-GR',
    'en-AU',
    'en-BM',
    'en-GB',
    'en-GG',
    'en-GH',
    'en-GY',
    'en-HK',
    'en-MO',
    'en-IE',
    'en-IN',
    'en-KE',
    'en-KI',
    'en-MT',
    'en-MU',
    'en-NA',
    'en-NG',
    'en-NZ',
    'en-PK',
    'en-PH',
    'en-RW',
    'en-SG',
    'en-SL',
    'en-TZ',
    'en-UG',
    'en-US',
    'en-ZA',
    'en-ZM',
    'en-ZW',
    'en-BW',
    'es-AR',
    'es-BO',
    'es-CO',
    'es-CL',
    'es-CR',
    'es-CU',
    'es-DO',
    'es-HN',
    'es-EC',
    'es-ES',
    'es-PE',
    'es-MX',
    'es-PA',
    'es-PY',
    'es-SV',
    'es-UY',
    'es-VE',
    'et-EE',
    'fa-IR',
    'fi-FI',
    'fj-FJ',
    'fo-FO',
    'fr-BF',
    'fr-CM',
    'fr-FR',
    'fr-GF',
    'fr-GP',
    'fr-MQ',
    'fr-PF',
    'fr-RE',
    'he-IL',
    'hu-HU',
    'id-ID',
    'it-IT',
    'it-SM',
    'ja-JP',
    'ka-GE',
    'kk-KZ',
    'kl-GL',
    'ko-KR',
    'lt-LT',
    'lv-LV',
    'ms-MY',
    'mz-MZ',
    'nb-NO',
    'ne-NP',
    'nl-BE',
    'nl-NL',
    'nn-NO',
    'pl-PL',
    'pt-BR',
    'pt-PT',
    'pt-AO',
    'ro-RO',
    'ru-RU',
    'si-LK',
    'sl-SI',
    'sk-SK',
    'sq-AL',
    'sr-RS',
    'sv-SE',
    'tg-TJ',
    'th-TH',
    'tr-TR',
    'tk-TM',
    'uk-UA',
    'uz-UZ',
    'vi-VN',
    'zh-CN',
    'zh-TW',
    'dz-BT',

    'en-CA',
    'fr-CA',
    'fr-BE',
    'zh-HK',
    'zh-MO',
    'ga-IE',
    'fr-CH',
    'it-CH',
] as const;

type Locale = ArrayValues<typeof localesArr>;

const uniqueRegexesMap: Record<string, RegExp> = {
    'am-AM': /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
    'ar-AE': /^((\+?971)|0)?5[024568]\d{7}$/,
    'ar-BH': /^(\+?973)?(3|6)\d{7}$/,
    'ar-DZ': /^(\+?213|0)(5|6|7)\d{8}$/,
    'ar-LB': /^(\+?961)?((3|81)\d{6}|7\d{7})$/,
    'ar-EG': /^((\+?20)|0)?1[0125]\d{8}$/,
    'ar-IQ': /^(\+?964|0)?7[0-9]\d{8}$/,
    'ar-JO': /^(\+?962|0)?7[789]\d{7}$/,
    'ar-KW': /^(\+?965)[569]\d{7}$/,
    'ar-LY': /^((\+?218)|0)?(9[1-6]\d{7}|[1-8]\d{7,9})$/,
    'ar-MA': /^(?:(?:\+|00)212|0)[5-7]\d{8}$/,
    'ar-OM': /^((\+|00)968)?(9[1-9])\d{6}$/,
    'ar-PS': /^(\+?970|0)5[6|9](\d{7})$/,
    'ar-SA': /^(!?(\+?966)|0)?5\d{8}$/,
    'ar-SY': /^(!?(\+?963)|0)?9\d{8}$/,
    'ar-TN': /^(\+?216)?[2459]\d{7}$/,
    'az-AZ': /^(\+994|0)(5[015]|7[07]|99)\d{7}$/,
    'bs-BA': /^((((\+|00)3876)|06))((([0-3]|[5-6])\d{6})|(4\d{7}))$/,
    'be-BY': /^(\+?375)?(24|25|29|33|44)\d{7}$/,
    'bg-BG': /^(\+?359|0)?8[789]\d{7}$/,
    'bn-BD': /^(\+?880|0)1[13456789][0-9]{8}$/,
    'ca-AD': /^(\+376)?[346]\d{5}$/,
    'cs-CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'da-DK': /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'de-DE': /^((\+49|0)[1|3])([0|5][0-45-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7,9}$/,
    'de-AT': /^(\+43|0)\d{1,4}\d{3,12}$/,
    'de-CH': /^(\+41|0)([1-9])\d{1,9}$/,
    'de-LU': /^(\+352)?((6\d1)\d{6})$/,
    'dv-MV': /^(\+?960)?(7[2-9]|91|9[3-9])\d{7}$/,
    'el-GR': /^(\+?30|0)?(69\d{8})$/,
    'en-AU': /^(\+?61|0)4\d{8}$/,
    'en-BM': /^(\+?1)?441(((3|7)\d{6}$)|(5[0-3][0-9]\d{4}$)|(59\d{5}))/,
    'en-GB': /^(\+?44|0)7\d{9}$/,
    'en-GG': /^(\+?44|0)1481\d{6}$/,
    'en-GH': /^(\+233|0)(20|50|24|54|27|57|26|56|23|28|55|59)\d{7}$/,
    'en-GY': /^(\+592|0)6\d{6}$/,
    'en-HK': /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
    'en-MO': /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
    'en-IE': /^(\+?353|0)8[356789]\d{7}$/,
    'en-IN': /^(\+?91|0)?[6789]\d{9}$/,
    'en-KE': /^(\+?254|0)(7|1)\d{8}$/,
    'en-KI': /^((\+686|686)?)?( )?((6|7)(2|3|8)[0-9]{6})$/,
    'en-MT': /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
    'en-MU': /^(\+?230|0)?\d{8}$/,
    'en-NA': /^(\+?264|0)(6|8)\d{7}$/,
    'en-NG': /^(\+?234|0)?[789]\d{9}$/,
    'en-NZ': /^(\+?64|0)[28]\d{7,9}$/,
    'en-PK': /^((00|\+)?92|0)3[0-6]\d{8}$/,
    'en-PH': /^(09|\+639)\d{9}$/,
    'en-RW': /^(\+?250|0)?[7]\d{8}$/,
    'en-SG': /^(\+65)?[3689]\d{7}$/,
    'en-SL': /^(\+?232|0)\d{8}$/,
    'en-TZ': /^(\+?255|0)?[67]\d{8}$/,
    'en-UG': /^(\+?256|0)?[7]\d{8}$/,
    'en-US': /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
    'en-ZA': /^(\+?27|0)\d{9}$/,
    'en-ZM': /^(\+?26)?09[567]\d{7}$/,
    'en-ZW': /^(\+263)[0-9]{9}$/,
    'en-BW': /^(\+?267)?(7[1-8]{1})\d{6}$/,
    'es-AR': /^\+?549(11|[2368]\d)\d{8}$/,
    'es-BO': /^(\+?591)?(6|7)\d{7}$/,
    'es-CO': /^(\+?57)?3(0(0|1|2|4|5)|1\d|2[0-4]|5(0|1))\d{7}$/,
    'es-CL': /^(\+?56|0)[2-9]\d{1}\d{7}$/,
    'es-CR': /^(\+506)?[2-8]\d{7}$/,
    'es-CU': /^(\+53|0053)?5\d{7}/,
    'es-DO': /^(\+?1)?8[024]9\d{7}$/,
    'es-HN': /^(\+?504)?[9|8]\d{7}$/,
    'es-EC': /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
    'es-ES': /^(\+?34)?[6|7]\d{8}$/,
    'es-PE': /^(\+?51)?9\d{8}$/,
    'es-MX': /^(\+?52)?(1|01)?\d{10,11}$/,
    'es-PA': /^(\+?507)\d{7,8}$/,
    'es-PY': /^(\+?595|0)9[9876]\d{7}$/,
    'es-SV': /^(\+?503)?[67]\d{7}$/,
    'es-UY': /^(\+598|0)9[1-9][\d]{6}$/,
    'es-VE': /^(\+?58)?(2|4)\d{9}$/,
    'et-EE': /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
    'fa-IR': /^(\+?98[\-\s]?|0)9[0-39]\d[\-\s]?\d{3}[\-\s]?\d{4}$/,
    'fi-FI': /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
    'fj-FJ': /^(\+?679)?\s?\d{3}\s?\d{4}$/,
    'fo-FO': /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'fr-BF': /^(\+226|0)[67]\d{7}$/,
    'fr-CM': /^(\+?237)6[0-9]{8}$/,
    'fr-FR': /^(\+?33|0)[67]\d{8}$/,
    'fr-GF': /^(\+?594|0|00594)[67]\d{8}$/,
    'fr-GP': /^(\+?590|0|00590)[67]\d{8}$/,
    'fr-MQ': /^(\+?596|0|00596)[67]\d{8}$/,
    'fr-PF': /^(\+?689)?8[789]\d{6}$/,
    'fr-RE': /^(\+?262|0|00262)[67]\d{8}$/,
    'he-IL': /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/,
    'hu-HU': /^(\+?36|06)(20|30|31|50|70)\d{7}$/,
    'id-ID': /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
    'it-IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
    'it-SM': /^((\+378)|(0549)|(\+390549)|(\+3780549))?6\d{5,9}$/,
    'ja-JP': /^(\+81[ \-]?(\(0\))?|0)[6789]0[ \-]?\d{4}[ \-]?\d{4}$/,
    'ka-GE': /^(\+?995)?(5|79)\d{7}$/,
    'kk-KZ': /^(\+?7|8)?7\d{9}$/,
    'kl-GL': /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'ko-KR': /^((\+?82)[ \-]?)?0?1([0|1|6|7|8|9]{1})[ \-]?\d{3,4}[ \-]?\d{4}$/,
    'lt-LT': /^(\+370|8)\d{8}$/,
    'lv-LV': /^(\+?371)2\d{7}$/,
    'ms-MY': /^(\+?6?01){1}(([0145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/,
    'mz-MZ': /^(\+?258)?8[234567]\d{7}$/,
    'nb-NO': /^(\+?47)?[49]\d{7}$/,
    'ne-NP': /^(\+?977)?9[78]\d{8}$/,
    'nl-BE': /^(\+?32|0)4\d{8}$/,
    'nl-NL': /^(((\+|00)?31\(0\))|((\+|00)?31)|0)6{1}\d{8}$/,
    'nn-NO': /^(\+?47)?[49]\d{7}$/,
    'pl-PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
    'pt-BR': /^((\+?55\ ?[1-9]{2}\ ?)|(\+?55\ ?\([1-9]{2}\)\ ?)|(0[1-9]{2}\ ?)|(\([1-9]{2}\)\ ?)|([1-9]{2}\ ?))((\d{4}\-?\d{4})|(9[2-9]{1}\d{3}\-?\d{4}))$/,
    'pt-PT': /^(\+?351)?9[1236]\d{7}$/,
    'pt-AO': /^(\+244)\d{9}$/,
    'ro-RO': /^(\+?4?0)\s?7\d{2}(\/|\s|\.|\-)?\d{3}(\s|\.|\-)?\d{3}$/,
    'ru-RU': /^(\+?7|8)?9\d{9}$/,
    'si-LK': /^(?:0|94|\+94)?(7(0|1|2|4|5|6|7|8)( |-)?)\d{7}$/,
    'sl-SI': /^(\+386\s?|0)(\d{1}\s?\d{3}\s?\d{2}\s?\d{2}|\d{2}\s?\d{3}\s?\d{3})$/,
    'sk-SK': /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'sq-AL': /^(\+355|0)6[789]\d{6}$/,
    'sr-RS': /^(\+3816|06)[- \d]{5,9}$/,
    'sv-SE': /^(\+?46|0)[\s\-]?7[\s\-]?[02369]([\s\-]?\d){7}$/,
    'tg-TJ': /^(\+?992)?[5][5]\d{7}$/,
    'th-TH': /^(\+66|66|0)\d{9}$/,
    'tr-TR': /^(\+?90|0)?5\d{9}$/,
    'tk-TM': /^(\+993|993|8)\d{8}$/,
    'uk-UA': /^(\+?38|8)?0\d{9}$/,
    'uz-UZ': /^(\+?998)?(6[125-79]|7[1-69]|88|9\d)\d{7}$/,
    'vi-VN': /^((\+?84)|0)((3([2-9]))|(5([25689]))|(7([0|6-9]))|(8([1-9]))|(9([0-9])))([0-9]{7})$/,
    'zh-CN': /^((\+|00)86)?(1[3-9]|9[28])\d{9}$/,
    'zh-TW': /^(\+?886\-?|0)?9\d{8}$/,
    'dz-BT': /^(\+?975|0)?(17|16|77|02)\d{6}$/
};

const regexesMap = evaluateFunction(() => {
    const regexesMap = {
        ...uniqueRegexesMap
    };

    regexesMap['en-CA'] = regexesMap['en-US'];
    regexesMap['fr-CA'] = regexesMap['en-CA'];
    regexesMap['fr-BE'] = regexesMap['nl-BE'];
    regexesMap['zh-HK'] = regexesMap['en-HK'];
    regexesMap['zh-MO'] = regexesMap['en-MO'];
    regexesMap['ga-IE'] = regexesMap['en-IE'];
    regexesMap['fr-CH'] = regexesMap['de-CH'];
    regexesMap['it-CH'] = regexesMap['fr-CH'];

    return regexesMap;
}) as Record<Locale, RegExp>;



export function validateIsMobilePhone (
    str: string,
    locale: ArrayOrSingle<Locale> | 'any' = 'any',
    strictMode = false
) {

    if (strictMode && !str.startsWith('+')) {
        return false;
    }

    if (str.startsWith('+')) {
        return validateIsMobilePhoneInternal(str, locale)
    } else {
        return validateIsMobilePhoneInternal(str, locale) || validateIsMobilePhoneInternal(`+${str}`, locale)
    }
}

function validateIsMobilePhoneInternal (
    str: string,
    locale: ArrayOrSingle<Locale> | 'any' = 'any',
    strictMode = false
) {

    if (strictMode && !str.startsWith('+')) {
        return false;
    }

    if (Array.isArray(locale)) {
        return locale.some(function (key) {
            const phone = regexesMap[key];

            return (phone.test(str));
        });
    } else if (locale === 'any') {

        const uniqueRegexes = Object.values(uniqueRegexesMap);
        for (const regex of uniqueRegexes) {
            if (regex.test(str)) {
                return true;
            }
        }

        return false;
    } else {
        return regexesMap[locale].test(str); // alias falsey locale as 'any'
    }
}
