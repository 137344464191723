import React from 'react';
import {classes, style} from './doppe-viewer-modal-panel-header.st.css';
import {DoppeViewerModalPanelSection} from "../doppe-viewer-modal-panel-section/doppe-viewer-modal-panel-section";
import {FlexLayout} from "@wix/devzai-common-client";
import {DoppeViewerHorizontalButton} from "../doppe-viewer-horizontal-button/doppe-viewer-horizontal-button";
import {
    DoppeViewerAppIconArrowLeftMedium,
    DoppeViewerAppIconArrowRightMedium, DoppeViewerAppIconDismissMedium
} from "../doppe-viewer-icons/doppe-viewer-icons";
import {useDoppViewerIsRtl} from "../doppe-viewer-lang-dictionary/doppe-viewer-lang-dictionary";

export const DoppeViewerModalPanelHeader = React.memo(function DoppeViewerModalPanelHeader (props: DoppeViewerModalPanelHeader.Props) {
    const {
        title,
        backButtonCallback,
        dismissButtonCallback,

        className,
        ...htmlAttributes
    } = props;

    const isRtl = useDoppViewerIsRtl();


    return (
        <div
            {...htmlAttributes}
            className={style(classes.root, className, FlexLayout.column.default)}
        >
            <DoppeViewerModalPanelSection
                className={style(classes.content, FlexLayout.row.alignToCenter)}
            >
                <DoppeViewerHorizontalButton
                    className={style(classes.backButton, {hidden: backButtonCallback === undefined})}
                    prefixIcon={isRtl ? DoppeViewerAppIconArrowRightMedium : DoppeViewerAppIconArrowLeftMedium}
                    textual={true}
                    onClick={() => {
                        backButtonCallback?.();
                    }}
                />
                <div
                    className={style(classes.title, FlexLayout.fillRemainingHorizontalSpace)}
                >
                    {title}
                </div>
                <DoppeViewerHorizontalButton
                    className={style(classes.dismissButton, {hidden: dismissButtonCallback === undefined})}
                    prefixIcon={DoppeViewerAppIconDismissMedium}
                    textual={true}
                    onClick={() => {
                        dismissButtonCallback?.();
                    }}
                />
            </DoppeViewerModalPanelSection>
        </div>
    );
});

export namespace DoppeViewerModalPanelHeader {

    export interface Props extends React.HTMLAttributes<any> {
        title: string;
        backButtonCallback?: () => void;
        dismissButtonCallback?: () => void;
    }
}
