import React from 'react';
import {classes, style} from './doppe-action-type-iframe-widget.st.css';
import {DoppeActionType} from '../../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeActionTypeIframe} from '../doppe-action-type-iframe';

export const DoppeActionTypeIframeWidget = React.memo(function DoppeActionTypeSubscribeEmbedWidget(
    props: DoppeActionType.WidgetProps<DoppeActionTypeIframe.ActionSettings>
) {
    const {
        actionContext,

        ...htmlAttributes
    } = props;

    const actionSettings = actionContext.action.settings;

    return (
        <DoppeActionTypeIframeWidgetContent
            {...htmlAttributes}
            iframeUrl={actionSettings.iframeUrl}
        />
    );
});

export const DoppeActionTypeIframeWidgetContent = React.memo(function DoppeActionTypeSubscribeEmbedWidget(
    props: {
        iframeUrl: string;
    } & React.HTMLAttributes<any>
) {
    const {
        iframeUrl,

        className,
        ...htmlAttributes
    } = props;

    return (
        <div
            {...htmlAttributes}
            className={style(classes.root, className)}
        >
            <iframe
                title="Iframe Embed"
                src={iframeUrl}
                className={style(classes.content, {})}
            />
        </div>
    );
});

