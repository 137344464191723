import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {DoppeActionTypeCoupon} from '../doppe-action-type-coupon/doppe-action-type-coupon';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeActionLayout} from '../../client-server-common';
import {
    doppeExternalCouponProviderResolveBusinessEntityReference
} from '../../client-server-common/types/doppe-external-coupon';
import {doppeHideableValueCreateHidden} from '../../client-server-common/types/doppe-hideable-value';


export namespace DoppeActionTypeCouponsList {

    export interface CouponSettings extends DoppeActionTypeCoupon.ActionSettings {
        id: string;
        title: string;
    }

    export interface ActionSettings {
        coupons: CouponSettings[];
        layout: DoppeActionLayout;
        latestNonPremiumLayout?: DoppeActionLayout;
        showButton: boolean;
        buttonText: string;
        buttonUrl: string;
    }
}


export const doppeActionTypeCouponsList = doppeActionTypeDefineMetadata<DoppeActionTypeCouponsList.ActionSettings>({
    id: 'io.bymo.action/coupons-list',
    resolveActionSettings: action => {

        const {
            coupons = [],
            ...restSettings
        } = action.settings;

        return {
            coupons: coupons.map(coupon => {
                return {
                    couponProvider: null,
                    couponProviderId: null,
                    ribbon: doppeHideableValueCreateHidden(''),
                    ...coupon as PartiallyOptional<DoppeActionTypeCouponsList.CouponSettings, 'couponProvider' | 'couponProviderId' | 'ribbon'>
                }
            }),
            layout: DoppeActionLayout.Column,
            showButton: false,
            buttonText: 'See More Coupons',
            buttonUrl: 'https://www.website.com',
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return iterableMapToArray(actionSettings.coupons, (coupon, skip) => {
            return coupon.couponImage ?? skip
        });
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.coupons.length;
    },
    resolveUsedBusinessEntities: actionSettings => {
        return iterableMapToArray(actionSettings.coupons, (coupon, skip) => {
            return coupon.couponProvider !== null && coupon.couponProviderId !== null ?
                doppeExternalCouponProviderResolveBusinessEntityReference(
                    coupon.couponProvider,
                    coupon.couponProviderId
                ) : skip
        });
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.coupons) {
                if (item.couponImage) {
                    item.couponImage =
                        wixMediaResourceReplaceExternalImage(item.couponImage, externalImagesUrlToImageResourceMap)
                }
            }
        })
    },
});