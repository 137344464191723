import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeActionPriceData,
    DoppeDtoStoreProduct,
    doppeDtoStoreProductPriceDataConvertToActionPriceData,
    DoppeExternalProductProvider,
    doppeExternalProductProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueModifyValue
} from '../../client-server-common';
import {
    WixMediaResource,
    wixMediaResourceReplaceExternalImage,
    wixProductMediaTryConvertToWixMediaResource
} from '@wix/devzai-common-wix';
import {DoppeActionTypeProductViewerLang} from './doppe-action-type-product-viewer.lang';
import {arrayRemoveNullValues, asArray, immerProduce, stringIsNotNullableOrWhiteSpace} from '@wix/devzai-utils-common';

export namespace DoppeActionTypeProduct {

    export interface ActionSettings {
        productPageLink: string;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        productTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<string>;
        productButtonText: DoppeHideableValue<string>;
        productImage: WixMediaResource[] | WixMediaResource | null;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
        productProvider: DoppeExternalProductProvider | null;
        productProviderId: string | null;
    }
}

export const doppeActionTypeProduct = doppeActionTypeDefineMetadata<DoppeActionTypeProduct.ActionSettings>({
    id: 'io.bymo.action/product',
    resolveActionSettings: action => {

        const {
            ...restSettings
        } = action.settings;

        return {
            productPageLink: '',
            priceData: {
                actualPrice: DoppeActionTypeProductViewerLang.defaultTexts.price,
            },
            productTitle: doppeHideableValueCreateHidden(DoppeActionTypeProductViewerLang.defaultTexts.productTitle),
            description: DoppeActionTypeProductViewerLang.defaultTexts.description,
            productButtonText: DoppeActionTypeProductViewerLang.defaultTexts.productButtonText,
            productImage: null,
            couponCode: doppeHideableValueCreateHidden('CouponCode'),
            ribbon: doppeHideableValueCreateHidden('SALE'),
            productProvider: null,
            productProviderId: null,
            ...restSettings
        }
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues(asArray(actionSettings.productImage));
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.productProvider !== null && actionSettings.productProviderId !== null ?
                doppeExternalProductProviderResolveBusinessEntityReference(
                    actionSettings.productProvider,
                    actionSettings.productProviderId
                ) :
                null
        ])
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.productImage) {
                const images = asArray(actionSettings.productImage);
                actionSettings.productImage = images.map(image => {
                    return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                })
            }
        })
    },
});

export function doppeActionTypeProductResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeProduct.ActionSettings,
    storeProduct: DoppeDtoStoreProduct
): DoppeActionTypeProduct.ActionSettings {
    return {
        ...actionSettings,
        priceData: doppeHideableValueModifyValue(
            actionSettings.priceData,
            doppeDtoStoreProductPriceDataConvertToActionPriceData(storeProduct.price)
        ),
        productPageLink: stringIsNotNullableOrWhiteSpace(storeProduct.productPageUrl) ?
            storeProduct.productPageUrl : 'about:blank',
    }
}

export function convertDoppeWixProductToProductSettings(product: DoppeDtoStoreProduct) {

    const productImages = product.media?.items ?
        arrayRemoveNullValues(product.media?.items.map(item => wixProductMediaTryConvertToWixMediaResource(item))) : null;

    const productTitle = product?.name ?? 'Product name';

    return {
        productImage: productImages,
        productPageLink: product.productPageUrl ?? '',
        priceData: doppeDtoStoreProductPriceDataConvertToActionPriceData(product.price),
        productTitle: productTitle,
        description: product.descriptionPlainText,
        productProvider: DoppeExternalProductProvider.Wix,
        productProviderId: product.id,
        ribbon: doppeHideableValueCreateHidden('SALE'),
        couponCode: doppeHideableValueCreateHidden('CouponCode'),
        productButtonText: 'Buy Now',
    } satisfies DoppeActionTypeProduct.ActionSettings
}