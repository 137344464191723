export const DoppeViewerLangDictionaryEn = {
    contactFormNameFieldPlaceholder: 'Name',
    contactFormEmailFieldPlaceholder: 'Email',
    contactFormPhoneFieldPlaceholder: 'Phone',
    contactFormAddressFieldPlaceholder: 'Address',
    contactFormZipCodeFieldPlaceholder: 'Postal/Zip Code',
    contactFormMessageFieldPlaceholder: 'Message',
    createdWithHopp: 'Create your own Link in Bio',
    collectSubscribersEmailFieldPlaceholder: 'Type your email',
    collectSubscribersPhoneFieldPlaceholder: 'Type your phone',
    collectSubscribersEmailMenuItem: 'Email',
    collectSubscribersPhoneFieldMenuItem: 'Phone',
    autoRedirectBannerRedirectingText: (options: {
        normalizedHostName: string,
        countdown: number,
    }) => `Redirecting to ${options.normalizedHostName}${options.countdown > 0 ? ` in ${options.countdown} secs` : ''}...`,
    autoRedirectBannerRedirectingCancelText: 'cancel',
    autoRedirectBannerRedirectingCloseText: 'close',
    termsOfServiceText: `By using Hopp you acknowledge that you have read and agreed to the `,
    termsOfServiceTextAnd: 'and',
    termsOfServiceTextOf: 'of',
    termsOfServiceLabel: 'Terms of Use',
    termsOfServicePrivacyLabel: 'Privacy Policy',
    subscribeCheckBoxText: (options: {
        username: string,
    }) => `By checking the box and proceeding you are providing your data to ${options.username}, who may contact you for marketing purposes`,
    subscribeConsentText: (options: {
        username: string,
    }) => `By submitting your contact details, you are providing your data to ${options.username}, who may contact you for marketing purposes.`,
    subscribeThankYou: 'Thank you for subscribing!',
    formThankYou: 'Thank you for submitting!',
    requiredField: 'Field is required',
    requiredConsent: 'Consent is required',
    enterValidPhone: 'Enter valid phone',
    enterValidEmail: 'Enter valid email',
    enterValidAddress: 'Enter valid address',
    enterValidZip: 'Enter valid zip',
    youMustAgree: 'You must agree to the Subscriber Agreement to continue.',
    skip: 'Skip',
    beforeYouMoveOn: 'Before you move on to...',
    redirectingIn: (seconds: number) => `redirecting in ${seconds}`,
    connectedLink: `{Connected Link}`,
    claim: `Claim your Hopp account today.`,
    claimYourAccount: 'Claim your account',
    tryForFree: 'Try Hopp',
    goToLink: `Go to Link`,
    enterACode: `Search or type a keyword`,
    noLinksMatching: `There are no links matching the keyword`,
    accessSharedLink: `Access a shared link by entering a keyword mentioned in my posts.`,
    copiedToClipboard: 'Copied to clipboard!',
    recentCodes: `Recent Keywords`,
    recentLinks: `Recent Links`,
    links: `Links`,
    codes: `Keywords`,
    includesCoupon: 'Includes a coupon',
    continue: 'Continue',
    backToSearch: 'Back to search',
    seeMore: 'See more',
    back: 'Back',
    code: 'Keyword',
    contentLoadingErrorTitle: `This content couldn't be loaded`,
    contentLoadingErrorDescription: `Please reload the page to try again`,
    contentUnavailableTitleDefault: `This content isn't available right now`,
    contentUnavailableTitleService: `This service couldn't be found`,
    contentUnavailableTitleBlog: `This blog post couldn't be found`,
    contentUnavailableTitleProduct: `This product couldn't be found`,
    contentUnavailableTitlePlan: `This product couldn't be found`,
    coupon: 'Coupon',
    continueToWebsite: 'Continue to website',
    couponDescription: "Copy the coupon and continue to the website",
    checkoutFormAdditionalInformationFieldLabel: 'Notes',
    checkoutFormAdditionalInformationFieldPlaceholder: 'Add notes for the seller',
    checkoutFormMessageFieldLabel: 'Message',
    checkoutFormMessageFieldPlaceholder: 'Type your message',
    fulfillmentDetails: 'Fulfillment Details',
    requestsInstructions: 'Instructions',
    cardNumber: 'Card Number',
    cardExpirationyPlaceholder: 'MM/YY',
    cardExpiration: 'Expiration',
    cardCVC: 'CVC',
    cardCountry: 'Country',
    fullName: 'Full Name',
    email: 'Email',
    fullNamePlaceholder: 'First and last Name',
    securedPaymentByStripe: 'Powered by Stripe',
    checkoutAddressLine: 'Address Line 1',
    checkoutAddressStreetAddress: 'Street Address',
    checkoutPhone: 'Phone',
    paymentError: 'We are having issues processing your payment. Please try again later.',
    checkoutContactInformation: 'Contact Information',
    paymentDetails: 'Payment Details',
    billingDetails: 'Billing Details',
    cookieConsentAccept: 'Accept',
    cookieConsentDeclineAll: 'Decline All',
    cookieConsentSettings: 'Settings',
    cookieConsentDescription: 'We use cookies on our website to see how you interact with it. By accepting, you agree to our use of such cookies',
    cookieConsentModalTitle: 'Privacy Settings',
    cookieConsentEssentialTitle: 'Essential cookies',
    cookieConsentEssentialContent: 'These cookies enable core functionality such as security, verification of identity and network management. These cookies can’t be disabled.',
    cookieConsentMarketingTitle: 'Enable Marketing Cookies',
    cookieConsentMarketingContent: 'These cookies are used to track advertising effectiveness to provide a more relevant service and deliver better ads to suit your interests.',
    cookieConsentFunctionalTitle: 'Enable Functional Cookies',
    cookieConsentFunctionalContent: 'These cookies collect data to remember choices users make to improve and give a more personalised experience.',
    cookieConsentSettingsSaveButton: 'Save',
    cookieConsentAnalyticsTitle: 'Enable Analytics Cookies',
    cookieConsentAnalyticsContent: 'These cookies help us to understand how visitors interact with our website, discover errors and provide a better overall analytics.'
}