import {asyncWithRetries} from "@wix/devzai-utils-common";

export async function doppeClientHttpClientCreate (
    options: {
        baseUrl?: string;
    } = {}
) {
    const {
        baseUrl
    } = options;

    const {
        HttpClient
    } = await asyncWithRetries(() => import('@wix/http-client'), {retries: 3, interval: 1000});

    return new HttpClient({
        artifactId: 'com.wixpress.doppe',
        baseURL: baseUrl
    });
}

export type DoppeHttpClient = Awaited<ReturnType<typeof doppeClientHttpClientCreate>>