import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeActionWidgetOpeningMode,
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {asArray, immerProduce} from '@wix/devzai-utils-common';


export namespace DoppeActionTypeLinkView {

    export interface ActionSettings {
        url: string;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<string>;
        image: WixImageResource[] | null;
        linkButtonText: DoppeHideableValue<string>;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeLinkView = doppeActionTypeDefineMetadata<DoppeActionTypeLinkView.ActionSettings>({
    id: 'io.bymo.action/link-view',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        url: '#',
        title: 'Link title',
        description: doppeHideableValueCreateHidden('Link description'),
        image: null,
        linkButtonText: 'Continue',
        couponCode: doppeHideableValueCreateHidden('Coupon code'),
        ribbon: doppeHideableValueCreateHidden('SALE'),
    }),
    isLinkOpener: true,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: (actionContext) => actionContext.action.widgetOpeningMode === DoppeActionWidgetOpeningMode.OpenCTA ? false : true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image) : [];
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.image) {
                actionSettings.image = asArray(actionSettings.image).map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
})