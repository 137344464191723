import { EqualityComparer, referencesEqualityComparer } from './equality-comparer';
import {assertDefined} from "./assertions";
import {Evaluable, evaluateWhenFunction} from "./evaluable";
import {
    OrderedListMoveOperation,
    OrderedListMoveOperationType
} from "./ordered-list-move-operation/ordered-list-move-operation";
import {iterableGenerateItemFrequencyMap} from "./iterable-utils/iterable-utils";

export function arrayCreateFromArrayLike<T> (arrayLike: {length: number; [index: number]: T}) {
    const length = arrayLike.length;

    const result = new Array(length);

    for (let i = 0; i < length; i++) {
        result[i] = arrayLike[i]
    }

    return result;
}

export function arrayReplaceEmptyValue<ARR extends unknown[], T> (arr: ARR, emptyValue: T) : ARR
export function arrayReplaceEmptyValue<ARR extends unknown[], T> (arr: undefined | null, emptyValue: T) : T
export function arrayReplaceEmptyValue<ARR extends unknown[], T> (arr: ARR | undefined | null, emptyValue: T) : ARR | T;
export function arrayReplaceEmptyValue<ARR extends unknown[], T> (arr: ARR | undefined | null, emptyValue: T) : ARR | T {
    return arr && arr.length > 0 ? arr : emptyValue;
}

export function arraySkipTake<T> (arr: T[], skip = 0, take = Infinity) {
    return arr.slice(skip, skip + take);
}

export function arrayIsNotNullableOrEmpty<T> (arr: T[] | null | undefined) : arr is T[] {
    return arr !== null && arr !== undefined && arr.length > 0;
}

export function arrayImmutableInsert<T> (arr: T[], index: number, ...values: T[]) : T[] {
    const clonedArr = [...arr];
    clonedArr.splice(index, 0, ...values);
    return clonedArr;
}

export function arrayImmutableReplaceAt<T> (arr: T[], index: number, value: T) : T[] {
    const clonedArr = [...arr];
    clonedArr[index] = value;
    return clonedArr;
}

export function arrayImmutableUpsert<T> (
    arr: T[],
    predicate: (item: T) => boolean,
    value: Evaluable<(prevValue: T | undefined) => T>,
    insertionFunc?: (arr: T[], value: T) => void
) {
    const clonedArr = [...arr];

    const valueIndex = arrayFindIndex(arr, predicate);

    if (valueIndex < 0) {

        const insertedValue = evaluateWhenFunction(value, undefined);

        if (insertionFunc) {
            insertionFunc(clonedArr, insertedValue);
        } else {
            clonedArr.push(insertedValue)
        }
    } else {
        clonedArr[valueIndex] = evaluateWhenFunction(value, clonedArr[valueIndex]);
    }

    return clonedArr;
}

export function arrayFind<T>(
    arr: Array<T>,
    predicate: (item: T) => boolean,
    startingIndex = 0,
    isCyclic = false
): [T | undefined, number] {
    const length = arr.length;

    if (startingIndex < length) {
        for (let i = startingIndex; i < length; i++) {
            const item = arr[i];
            if (predicate(item)) {
                return [item, i];
            }
        }

        if (isCyclic && startingIndex > 0) {
            for (let i = 0; i < startingIndex; i++) {
                const item = arr[i];
                if (predicate(item)) {
                    return [item, i];
                }
            }
        }
    }

    return [undefined, -1];
}

export function arrayFindFromEnd<T>(
    arr: Array<T>,
    predicate: (item: T) => boolean,
    startingIndex = arr.length - 1,
    isCyclic = false
): [T | undefined, number] {
    const length = arr.length;

    if (startingIndex < length) {
        for (let i = startingIndex; i >= 0; i--) {
            const item = arr[i];
            if (predicate(item)) {
                return [item, i];
            }
        }

        if (isCyclic && startingIndex > 0) {
            for (let i = arr.length - 1; i >= 0; i--) {
                const item = arr[i];
                if (predicate(item)) {
                    return [item, i];
                }
            }
        }
    }

    return [undefined, -1];
}

export function arrayFindValueFromEnd<T> (
    arr: Array<T>,
    predicate: (item: T) => boolean,
    startingIndex = arr.length - 1,
    isCyclic = false
) : T | undefined {
    return arrayFindFromEnd(arr, predicate, startingIndex, isCyclic)[0]
}

export function arrayMapInPlace<V> (arr: V[], mappingFunc: (value: V, index: number) => V) {

    for (let i = 0; i < arr.length; i++) {
        arr[i] = mappingFunc(arr[i], i);
    }

    return arr;
}

export function arraySubtract<V> (arr1: V[], ...subtractedArrays: V[][]) : V[] {

    if (subtractedArrays.length === 0) {
        return arr1;
    } else {
        const [arr2, ...restArrays] = subtractedArrays;

        const result = [];
        for (const value of arr1) {
            if (!arr2.includes(value)) {
                result.push(value);
            }
        }

        return arraySubtract(result, ...restArrays);
    }
}

export function arrayIncludesString (strArr: string[], str: string, caseInsensitive = false) {
    if (caseInsensitive) {
        return strArr.map(str => str.toLowerCase()).includes(str.toLowerCase());
    } else {
        return strArr.includes(str);
    }
}

export function arrayIntersect<V> (arr1: V[], ...otherArrays: V[][]) : V[] {

    if (otherArrays.length === 0) {
        return arr1;
    } else {
        const [arr2, ...restArrays] = otherArrays;

        const result = [];
        for (const value of arr1) {
            if (arr2.includes(value)) {
                result.push(value);
            }
        }

        return arrayIntersect(result, ...restArrays);
    }
}

export function arrayToggleValue<T> (arr: T[], value: T, isToggled?: boolean) : void {
    if (isToggled === undefined) {
        if (arr.includes(value)) {
            arrayRemove(arr, value);
        } else {
            arr.push(value);
        }
    } else if (isToggled) {
        if (!arr.includes(value)) {
            arr.push(value);
        }
    } else {
        arrayRemove(arr, value);
    }
}

export function arrayImmutableToggleValue<T> (arr: T[], value: T, isToggled?: boolean) {
    if (isToggled === undefined) {
        if (arr.includes(value)) {
            return arrayImmutableRemove(arr, value);
        } else {
            return [...arr, value];
        }
    } else if (isToggled) {
        if (!arr.includes(value)) {
            return [...arr, value];
        } else {
            return arr;
        }
    } else {
        return arrayImmutableRemove(arr, value);
    }
}

export function arrayRemoveAt<T> (arr: T[], index: number) : void {
    arr.splice(index, 1);
}

export function arrayRemove<T> (arr: T[], value: T) : void {

    const valueIndex = arr.indexOf(value);

    if (valueIndex >= 0) {
        arrayRemoveAt(arr, valueIndex)
    }
}

export function arrayRemoveNullValues<T> (arr: T[]) : Exclude<T, null>[] {
    return arr.filter(value => value !== null) as Exclude<T, null>[];
}

export function arrayRemoveUndefinedValues<T> (arr: (T | undefined)[]) : T[] {
    return arr.filter(value => value !== undefined) as T[];
}

export function arrayRemoveFirst<T> (arr: T[], predicate: (value: T) => boolean) : void {
    const index = arr.findIndex(predicate);

    if (index >= 0) {
        arr.splice(index, 1);
    }
}

export function arrayImmutableMoveValue<T> (arr: T[], oldIndex: number, newIndex: number) {

    if (newIndex >= arr.length || newIndex < 0) {
        throw new Error(`Value can be moved to indices in range [0, ${arr.length - 1}]`);
    }

    if (newIndex === oldIndex) {
        return arr;
    } else {
        const updatedArr = [...arr];

        const [value] = updatedArr.splice(oldIndex, 1);
        updatedArr.splice(newIndex, 0, value);

        return updatedArr;
    }

}

export function arrayImmutableMoveMultipleValues<T> (
    arr: T[],
    values: T[],
    moveOperation: OrderedListMoveOperation<T>
) {

    const [movedValues, restValues] = arraySplitByPredicate(arr, value => values.includes(value));

    switch (moveOperation.type) {
        case OrderedListMoveOperationType.MoveToStart: {
            return [...movedValues, ...restValues];
        }
        case OrderedListMoveOperationType.MoveToEnd: {
            return [...restValues, ...movedValues];
        }
        default: {
            throw new Error('Not Supported');
        }
    }
}

export function arrayImmutableRemoveAt<T> (arr: T[], index: number) : T[] {

    if (index >= 0 && index < arr.length) {
        const clonedArr = [...arr];
        clonedArr.splice(index, 1);
        return clonedArr;
    } else {
        return arr;
    }
}

export function arrayImmutableRemoveLast<T> (arr: T[]) : T[] {
    return arrayImmutableRemoveAt(arr, arr.length - 1)
}

export function arrayImmutableRemove<T> (arr: T[], value: T) : T[] {

    const valueIndex = arr.indexOf(value);

    return valueIndex >= 0 ?
        arrayImmutableRemoveAt(arr, valueIndex) :
        arr;
}

export function arrayImmutableRemoveAll<T> (arr: T[], predicate: (value: T) => boolean) : T[] {

    const filteredArr = arr.filter(value => !predicate(value));

    if (filteredArr.length !== arr.length) {
        return filteredArr;
    } else {
        return arr;
    }
}

export function arrayDistinct<V> (arr: V[]) : V[] {

    const result: V[] = [];
    
    for (let i = 0; i < arr.length; i++) {
        const value = arr[i];
        
        if (!arr.includes(value, i + 1)) {
            result.push(value)
        }
    }

    return result;
}

export function arrayUnion<V> (...unionArrays: V[][]) : V[] {

    if (unionArrays.length === 0) {
        return [];
    } else if (unionArrays.length === 1) {
        return unionArrays[0];
    } else {
        const [arr1, arr2, ...restArrays] = unionArrays;

        const result = [...arr1];
        for (const value of arr2) {
            if (!arr1.includes(value)) {
                result.push(value);
            }
        }

        return arrayUnion(result, ...restArrays);
    }
}

export function arrayXor<V> (arr1: V[], ...subtractedArrays: V[][]) : V[] {

    if (subtractedArrays.length === 0) {
        return arr1;
    } else {
        const [arr2, ...restArrays] = subtractedArrays;
        return arrayXor([...arraySubtract(arr1, arr2), ...arraySubtract(arr2, arr1)], ...restArrays);
    }
}


export function arrayFlatten<V = any>(arr: V[][]): V[] {
    return Array.prototype.concat.apply([], arr);
}

export function arrayLast<T> (arr: T[]) : T | undefined {
    if (arr.length == 0) {
        return undefined;
    }

    return arr[arr.length - 1];
}

export function arrayFirst<T> (arr: T[] | ReadonlyArray<T>) : T | undefined {
    return arr[0];
}

export function arrayAssertFirst<T> (arr: T[]) : T {
    return assertDefined(arr[0]);
}

export function arrayFindValue<T>(
    arr: Array<T>,
    predicate: (item: T) => boolean,
    startingIndex = 0,
    isCyclic = false
): T | undefined {
    const length = arr.length;

    if (startingIndex < length) {
        for (let i = startingIndex; i < length; i++) {
            const item = arr[i];
            if (predicate(item)) {
                return item;
            }
        }

        if (isCyclic && startingIndex > 0) {
            for (let i = 0; i < startingIndex; i++) {
                const item = arr[i];
                if (predicate(item)) {
                    return item;
                }
            }
        }
    }

    return undefined;
}

export function arrayFindIndex<T>(arr: Array<T>, predicate: (item: T) => boolean, startingIndex = 0): number {
    const length = arr.length;

    for (let i = startingIndex; i < length; i++) {
        const item = arr[i];
        if (predicate(item)) {
            return i;
        }
    }

    return -1;
}

export function arrayFindIndexCyclic<T>(
    arr: Array<T>,
    predicate: (item: T) => boolean,
    startingIndex = 0
): number {
    const length = arr.length;

    for (let i = startingIndex; i < length; i++) {
        const item = arr[i];
        if (predicate(item)) {
            return i;
        }
    }

    if (startingIndex > 0) {
        for (let i = 0; i < Math.min(startingIndex, length); i++) {
            const item = arr[i];
            if (predicate(item)) {
                return i;
            }
        }
    }

    return -1;
}

export function arrayFindIndexFromEnd<T>(
    arr: Array<T>,
    predicate: (item: T) => boolean,
    startingIndex?: number
): number {
    const length = arr.length;

    if (startingIndex === undefined) {
        startingIndex = length - 1;
    }

    if (startingIndex < length) {
        for (let i = startingIndex; i >= 0; i--) {
            const item = arr[i];
            if (predicate(item)) {
                return i;
            }
        }
    }

    return -1;
}

export function arrayFindIndexFromEndCyclic<T>(
    arr: Array<T>,
    predicate: (item: T) => boolean,
    startingIndex?: number
): number {
    const length = arr.length;

    if (startingIndex === undefined) {
        startingIndex = length - 1;
    }

    for (let i = startingIndex; i >= 0; i--) {
        const item = arr[i];
        if (predicate(item)) {
            return i;
        }
    }

    if (startingIndex < length - 1) {
        for (let i = length - 1; i > Math.max(startingIndex, -1); i--) {
            const item = arr[i];
            if (predicate(item)) {
                return i;
            }
        }
    }

    return -1;
}

export function arrayReverse<T>(arr: T[]) : T[] {
    return [...arr].reverse();
}

export function arrayImmutableOrderBy<T, KEY>(
    arr: Array<T>,
    valueSelector: (item: T, index: number) => KEY,
    comparer: (value1: KEY, value2: KEY) => number
): Array<T> {
    const sortedArray = arrayOrderBy(arr, valueSelector, comparer);
    if (arrayEqual(sortedArray, arr)) {
        return arr;
    } else {
        return sortedArray;
    }
}

export function arrayOrderBy<T, KEY>(
    arr: Array<T>,
    valueSelector: (item: T, index: number) => KEY,
    comparer: (value1: KEY, value2: KEY) => number
): Array<T> {
    const helperArr = arr.map((item, index) => [valueSelector(item, index), item]);

    helperArr.sort((value1: any, value2: any) => {
        return comparer(value1[0], value2[0]);
    });

    return helperArr.map((item: any) => item[1]);
}

export function arrayImmutableOrderByDesc<T, KEY>(
    arr: Array<T>,
    valueSelector: (item: T, index: number) => KEY,
    comparer: (value1: KEY, value2: KEY) => number
): Array<T> {
    const sortedArray = arrayOrderByDesc(arr, valueSelector, comparer);
    if (arrayEqual(sortedArray, arr)) {
        return arr;
    } else {
        return sortedArray;
    }
}

export function arrayOrderByDesc<T, KEY>(
    arr: Array<T>,
    valueSelector: (item: T, index: number) => KEY,
    comparer: (value1: KEY, value2: KEY) => number
): Array<T> {
    const helperArr = arr.map((item, index) => [valueSelector(item, index), item]);

    helperArr.sort((value1: any, value2: any) => {
        return -comparer(value1[0], value2[0]);
    });

    return helperArr.map((item: any) => item[1]);
}

export function arrayGroupBy<T, KEY, VALUE>(
    arr: T[],
    keySelector: (item: T) => KEY,
    valueSelector: (item: T) => VALUE
): Map<KEY, VALUE[]>;
export function arrayGroupBy<T, KEY>(
    arr: T[],
    keySelector: (item: T) => KEY
): Map<KEY, T[]>;
export function arrayGroupBy<T, KEY, VALUE>(
    arr: T[],
    keySelector: (item: T) => KEY,
    valueSelector?: (item: T) => VALUE
): Map<KEY, VALUE[]> {
    const map = new Map<KEY, VALUE[]>();

    for (const item of arr) {
        const key = keySelector(item);

        let group = map.get(key);
        if (group === undefined) {
            group = [];
            map.set(key, group);
        }

        group.push(valueSelector ? valueSelector(item) : item as unknown as VALUE);
    }

    return map;
}

export function arrayClone<T>(arr: Array<T>): Array<T> {
    return arr.slice(0);
}

export function arrayEqualInAnyOrder<T>(
    arr1: Array<T> | null | undefined,
    arr2: Array<T> | null | undefined,
) {
    if (arr1 === null || arr2 === null || arr1 === undefined || arr2 === undefined) {
        return arr1 === arr2;
    } else if (arr1 === arr2) {
        return true;
    } else {

        const arr1FrequencyMap = iterableGenerateItemFrequencyMap(arr1);
        const arr2FrequencyMap = iterableGenerateItemFrequencyMap(arr2);

        if (arr1FrequencyMap.size !== arr2FrequencyMap.size) {
            return false;
        }

        for (const [item, count] of arr1FrequencyMap) {
            if (arr2FrequencyMap.get(item) !== count) {
                return false;
            }
        }

        return true;
    }
}

export function arrayEqual<T>(
    arr1: Array<T> | null,
    arr2: Array<T> | null,
    equalityComparer: EqualityComparer<T> = referencesEqualityComparer
): boolean {
    if (arr1 === null || arr2 === null) {
        return arr1 === arr2;
    } else if (arr1 === arr2) {
        return true;
    } else {
        const arrLength = arr1.length;

        if (arrLength !== arr2.length) {
            return false;
        } else {
            for (let i = 0; i < arrLength; i++) {
                if (!equalityComparer(arr1[i], arr2[i])) {
                    return false;
                }
            }

            return true;
        }
    }
}

export function arrayGenerateIntervals(from: number, to: number, step: number): number[] {

    const result = [];
    let value = from;

    for (; value < to; value += step) {
        result.push(value);
    }

    if (value !== to) {
        result.push(to);
    }

    return result;
}

export function arrayGenerate(count: number): number[]
export function arrayGenerate<T>(count: number, generator: (index: number) => T): T[];
export function arrayGenerate<T>(count: number, generator?: (index: number) => T): T[] {

    if (count < 0 || count === Infinity) {
        throw new Error(`Invalid count`)
    }

    const result = [];

    for (let i = 0; i < count; i++) {
        result.push(generator ? generator(i) : i as unknown as T);
    }

    return result;
}

export function arrayMerge<T> (arr1: T[], arr2: T[]) : T[] {
    arr1.push(...arr2);
    return arr1;
}

export function arrayMergeToBeginning<T> (arr1: T[], arr2: T[]) : T[] {
    arr1.unshift(...arr2);
    return arr1;
}

export function arrayMergeNonExisting<T>(arr: T[], items: T[]): T[] {
    for (const item of items) {
        if (!arr.includes(item)) {
            arr.push(item);
        }
    }

    return arr;
}

export function asArray<T>(value: T | T[]): T[] {
    if (Array.isArray(value)) {
        return value;
    } else {
        return [value];
    }
}

export function arraySplit<T>(arr: T[], ...groupItemsCount: number[]): T[][] {
    const result = [];

    let cursor = 0;

    for (let i = 0; i < groupItemsCount.length; i++) {
        const itemsCount = groupItemsCount[i];
        result.push(arr.slice(cursor, cursor + itemsCount));
        cursor += itemsCount;
    }

    if (cursor < arr.length) {
        result.push(arr.slice(cursor));
    }

    return result;
}

export function arraySplitEqually<T>(arr: T[], itemsPerGroup: number): T[][] {

    if (itemsPerGroup <= 0) {
        throw new Error(`expected itemsPerGroup > 0 (actual ${itemsPerGroup})`)
    }

    const result = [];

    for (let i = 0; i < arr.length; i += itemsPerGroup) {
        result.push(arr.slice(i, i + itemsPerGroup))
    }

    return result;
}

export function arrayImmutableRemoveInnerValues<T> (arr: T[]) : T[] {

    if (arr.length > 2) {
        return [arr[0], arr[arr.length - 1]];
    } else {
        return arr;
    }

}

export function arraySplitByPredicate<T> (arr: T[], predicate: (value: T) => boolean) : [T[], T[]] {
    const trueValues: T[] = [];
    const falseValues: T[] = [];

    for (const value of arr) {
        if (predicate(value)) {
            trueValues.push(value);
        } else {
            falseValues.push(value);
        }
    }

    return [trueValues, falseValues];
}


export function arraySplitToGroups<T>(arr: T[], groupdIdFunction: (item: T) => string): T[][] {

    const map : {[id: string]: T[]} = {}

    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        const groupId = groupdIdFunction(item);
        if(!map[groupId]){
            map[groupId] = [item];
        }
        else
        {
            map[groupId].push(item);
        }
    }

    return Object.values(map);
}

export function arrayJoinByIndex<T1, T2, R>(
    leftArr: T1[],
    rightArr: T2[],
    matchingFunction: (value1: T1, value2: T2) => R
) : R[] {

    const result = [] as R[];

    const targetLength = Math.min(leftArr.length, rightArr.length);
    for (let i = 0; i < targetLength; i++) {
        result.push(matchingFunction(leftArr[i], rightArr[i]))
    }

    return result;
}

export function arrayLeftJoinByIndex<T1, T2, R>(
    leftArr: T1[],
    rightArr: T2[],
    matchingFunction: (value1: T1, value2: T2 | undefined) => R
) : R[] {

    const rightArrLength = rightArr.length;

    return leftArr.map((value1, index) => {
        return matchingFunction(value1, index < rightArrLength ? rightArr[index] : undefined);
    })
}

export function arrayShuffle<T>(array: T[]) : T[] {
    const length = array.length

    if (!length) {
        return []
    }

    let index = -1
    const lastIndex = length - 1
    const result = [...array]
    while (++index < length) {
        const rand = index + Math.floor(Math.random() * (lastIndex - index + 1))
        const value = result[rand]
        result[rand] = result[index]
        result[index] = value
    }
    return result
}

export function arrayGetItemCyclic<T> (arr: T[], index: number) : T {
    return arr[index % arr.length];
}

export function arrayGetItemCyclicWithCycleIndex<T> (arr: T[], index: number) : [T, number] {
    return [
        arr[index % arr.length],
        Math.floor(index / arr.length)
    ];
}