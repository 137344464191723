import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, asArray, immerProduce} from '@wix/devzai-utils-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour, DoppeHideableValue,
    DoppeListActionSettings
} from '../../client-server-common';
import {DoppeActionTypeIframe} from '../doppe-action-type-iframe/doppe-action-type-iframe';

export namespace DoppeActionTypeEmbedPageList {

    export interface EmbedPageSettings extends  DoppeActionTypeIframe.ActionSettings {
        id: string;
        title: string;
        image: WixMediaResource[] | null;
        description: DoppeHideableValue<string>;
    }

    export interface ActionSettings extends DoppeListActionSettings {
        links: EmbedPageSettings[];
    }
}


export const doppeActionTypeEmbedPageList = doppeActionTypeDefineMetadata<DoppeActionTypeEmbedPageList.ActionSettings>({
    id: 'io.bymo.action/embed-page-list',
    resolveActionSettings: action => {

        const {
            links = [],
            ...restSettings
        } = action.settings;

        return {
            links: links,
            itemClickBehaviour: DoppeActionListItemClickBehaviour.OpenLink,
            layout: DoppeActionLayout.Column,
            showButton: false,
            buttonText: 'See More Links',
            buttonUrl: 'https://www.website.com',
            previewLinkButtonText: 'Continue',
            itemShowTitle: true,
            itemShowImage: true,
            itemShowDescription: true,
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.links.map(link => {
            return link.image ? asArray(link.image) : []
        }));
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.links.length;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const link of actionSettings.links) {
                if (link.image) {
                    link.image = asArray(link.image).map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
});
