import React from 'react';
import {classes, style} from './doppe-viewer-action-view.st.css';
import {DoppeActionComponentActionButtonProps, DoppeActionView} from '../../doppe-sdk';
import {evaluateFunction, uiPartDefineGroups, uiPartsGroupCreateMarking} from '@wix/devzai-utils-common';
import {DoppeDtoActionViewType} from '../../client-server-common/types/doppe-dto-action';
import {CollapsibleCard} from '@wix/devzai-utils-react';
import {DoppeHideableValue} from '../../client-server-common/types/doppe-hideable-value';
import {
    bymoPageStyleGetStyleScopeClassName,
    BymoPageStyleScope, BymoPageStyleScopeContext,
    bymoPageStyleVars
} from "../bymo-page-style/bymo-page-style";
import {WixMediaResource} from '@wix/devzai-common-wix';
import {DoppePageMediaViewPreferencesScope} from "../doppe-page-media-view/doppe-page-media-view";
import {DoppeActionWidgetOpeningMode} from '../../client-server-common';

export const DoppeViewerActionViewUiPartsGroups = uiPartDefineGroups({
    ActionFrameElement: 'doppe-viewer-action-view-action-frame'
});

export const DoppeViewerActionView = React.memo(function DoppeViewerActionView (props: DoppeViewerActionView.Props) {
    const {
        label,
        actionImage,
        actionDescription,
        viewType,
        renderActionWidget,
        activateCTA,
        isWidgetOpener,
        actionContext,
        skipReportingOnGoalAchievedOnActionButtonClick,
        actionButtonComponentProps,
        isExpanded,

        actionViewRootHtmlAttributes,

        actionWidgetContainerClassName,
        actionButtonContainerClassName,
        actionWidgetFramelessContainerClassName,
        actionWidgetRoundness,
        renderActionButton,
        renderInlineContentCloseButton,

        className,
        style: styleProp
    } = props;



    return (
        <div
            {...actionViewRootHtmlAttributes}
            style={{
                ...styleProp,
                ...actionViewRootHtmlAttributes.style
            }}
            className={style(classes.root, className, actionViewRootHtmlAttributes.className)}
        >
            {evaluateFunction(() => {
                if (viewType === DoppeDtoActionViewType.ActionButton) {

                    return (
                        <CollapsibleCard
                            transitionDuration={300}
                            isOpen={isExpanded}
                            onBeforeTogglingAnimationStart={(element) => {
                                element.style.overflow = 'hidden';
                            }}
                            style={{
                                [bymoPageStyleVars.actionWidgetRoundness]: `${actionWidgetRoundness}px`
                            }}
                            onTogglingAnimationEnd={(element) => {
                                element.style.overflow = '';
                            }}
                            header={() => {
                                const {
                                    onClick,
                                    ...htmlAttributes
                                } = actionButtonComponentProps;

                                return (
                                    <div
                                        className={style(classes.actionButtonContainer, actionButtonContainerClassName)}
                                        {...uiPartsGroupCreateMarking(DoppeViewerActionViewUiPartsGroups.ActionFrameElement)}
                                    >
                                        {renderActionButton({
                                            actionButtonComponentProps: {
                                                onClick: (event) => {
                                                    if (isWidgetOpener) {
                                                        if (actionContext.canBeToggled()) {
                                                            actionContext.toggleActionView(true);
                                                        }
                                                    } else if(actionContext.action.widgetOpeningMode === DoppeActionWidgetOpeningMode.OpenCTA) {
                                                        activateCTA?.();
                                                    }

                                                    if (!skipReportingOnGoalAchievedOnActionButtonClick) {
                                                        actionContext.reportGoalAchieved();
                                                    }

                                                    onClick?.(event)
                                                },
                                                ...htmlAttributes
                                            },
                                            actionTitle: label,
                                            actionImage: actionImage,
                                            actionDescription: actionDescription,
                                            isWidgetOpener: isWidgetOpener,
                                        })}
                                    </div>
                                );
                            }}
                            subContent={() => {
                                return renderInlineContentCloseButton({
                                    closingFunction: () => {
                                        actionContext.toggleActionView(false);
                                    }
                                })
                            }}
                        >
                            <DoppePageMediaViewPreferencesScope
                                forcePreciseWidthForDesktop={true}
                                forcePreciseWidthForMobile={true}
                            >
                                <BymoPageStyleScopeContext.Provider value={BymoPageStyleScope.Card}>
                                    {renderActionWidget({
                                        className: style(
                                            classes.actionWidgetContainer,
                                            actionWidgetContainerClassName,
                                            bymoPageStyleGetStyleScopeClassName(BymoPageStyleScope.Card)
                                        ),
                                        ...uiPartsGroupCreateMarking(DoppeViewerActionViewUiPartsGroups.ActionFrameElement)
                                    })}
                                </BymoPageStyleScopeContext.Provider>
                            </DoppePageMediaViewPreferencesScope>
                        </CollapsibleCard>
                    )
                } else {

                    return (
                        <BymoPageStyleScopeContext.Provider
                            value={viewType === DoppeDtoActionViewType.InPageFrameless ? BymoPageStyleScope.PageContent : BymoPageStyleScope.Card}
                        >
                            {renderActionWidget({
                                className: style(
                                    classes.actionWidgetContainer,
                                    viewType === DoppeDtoActionViewType.InPage ? actionWidgetContainerClassName : actionWidgetFramelessContainerClassName,
                                    viewType === DoppeDtoActionViewType.InPageFrameless ?
                                        bymoPageStyleGetStyleScopeClassName(BymoPageStyleScope.PageContent) :
                                        bymoPageStyleGetStyleScopeClassName(BymoPageStyleScope.Card)
                                ),
                                style: {
                                    [bymoPageStyleVars.actionWidgetRoundness]: `${actionWidgetRoundness}px`
                                },
                                ...uiPartsGroupCreateMarking(DoppeViewerActionViewUiPartsGroups.ActionFrameElement)
                            })}
                        </BymoPageStyleScopeContext.Provider>
                    )
                }
            })}
        </div>
    )
});

export namespace DoppeViewerActionView {

    export interface ActionButtonRenderProps {
        actionButtonComponentProps: DoppeActionComponentActionButtonProps;
        isWidgetOpener: boolean;
        actionTitle: string;
        actionDescription: DoppeHideableValue<string>;
        actionImage: DoppeHideableValue<WixMediaResource | null>;
    }

    export interface Props extends DoppeActionView.ContextProps, Pick<React.HTMLAttributes<any>, 'className' | 'style'> {
        actionWidgetContainerClassName?: string;
        actionButtonContainerClassName?: string;
        actionWidgetRoundness: number;
        renderActionButton: (renderProps: ActionButtonRenderProps) => React.ReactElement;
    }
}
