import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';


export namespace DoppeActionTypeLink {

    export interface ActionSettings {
        url: string;
    }
}

export const doppeActionTypeLink = doppeActionTypeDefineMetadata<DoppeActionTypeLink.ActionSettings>({
    id: 'io.bymo.action/link',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        url: '#',
    }),
})