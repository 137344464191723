import {doppeActionTypeDefineViewerMetadata} from '../../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeCalendly} from '../doppe-action-type-calendly';
import {
    DoppeViewerModalPanel,
    DoppeViewerModalPanelLayout
} from '../../../components/doppe-viewer-modal-panel/doppe-viewer-modal-panel';
import {FlexLayout} from '@wix/devzai-common-client';
import {
    DoppeViewerModalPanelHeader
} from '../../../components/doppe-viewer-modal-panel-header/doppe-viewer-modal-panel-header';
import {classes, style} from './doppe-action-type-calendly-viewer.st.css';
import {DoppeActionTypeCalendlyWidget} from '../doppe-action-type-calendly-widget/doppe-action-type-calendly-widget';

export const doppeActionTypeCalendlyViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeCalendly, {

    getActionButtonProps: (actionContext, observableActionContext) => {
        return {
            onClick: () => {
                actionContext.modalsController.showInnerModal({
                    content: ({modalController}) => ({
                        render: () => (
                            <DoppeViewerModalPanel
                                className={style(classes.modal, FlexLayout.column.default)}
                                mobileLayout={DoppeViewerModalPanelLayout.FullScreen}
                                desktopLayout={DoppeViewerModalPanelLayout.InnerCentered}
                            >
                                <DoppeViewerModalPanelHeader
                                    title={actionContext.action.name}
                                    dismissButtonCallback={() => {
                                        modalController.closeWindow();
                                    }}
                                />
                                <DoppeActionTypeCalendlyWidget
                                    className={style(classes.widget, {}, FlexLayout.fillRemainingVerticalSpace)}
                                    actionContext={observableActionContext.getValue()}
                                />
                            </DoppeViewerModalPanel>
                        ),
                        dependencies: [observableActionContext]
                    })
                })
            }
        }
    }
})