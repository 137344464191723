import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    BymoTemplateSpeedDialAutoComplete
} from "../../components/bymo-templates/bymo-template-speed-dial/bymo-template-speed-dial.metadata";
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';

export namespace DoppeActionTypeSpeedDial {
    export interface ActionSettings {
        autoCompleteBehaviour: BymoTemplateSpeedDialAutoComplete;
        showFullScreenAutoCompleteOnMobile: boolean;
        searchPlaceholder: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeSpeedDial = doppeActionTypeDefineMetadata<DoppeActionTypeSpeedDial.ActionSettings>({
    id: 'io.bymo.action/speed-dial',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        showFullScreenAutoCompleteOnMobile: false,
        autoCompleteBehaviour: BymoTemplateSpeedDialAutoComplete.OnInputSearch,
        searchPlaceholder: doppeHideableValueCreateHidden(''),
    }),
    canRenderInPage: true
});