import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeActionTypeShareViewerLang} from './doppe-action-type-share-viewer.lang';
import {arrayRemoveNullValues, immerProduce} from "@wix/devzai-utils-common";

export namespace DoppeActionTypeShare {
    export interface ActionSettings {
        shareLink: string;
        shareTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<string>;
        shareButtonText: string;
        shareImage: WixImageResource | null;
    }
}

export const doppeActionTypeShare = doppeActionTypeDefineMetadata<DoppeActionTypeShare.ActionSettings>({
    id: 'io.bymo.action/share',

    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        shareLink: '',
        shareTitle: doppeHideableValueCreateHidden(DoppeActionTypeShareViewerLang.defaultTexts.shareTitle),
        description: DoppeActionTypeShareViewerLang.defaultTexts.description,
        shareButtonText: DoppeActionTypeShareViewerLang.defaultTexts.shareButtonText,
        shareImage: null,

    }),
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([actionSettings.shareImage]);
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.shareImage) {
                actionSettings.shareImage =
                    wixMediaResourceReplaceExternalImage(actionSettings.shareImage, externalImagesUrlToImageResourceMap)
            }
        })
    },

});