import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {
    arrayRemoveNullValues,
    immerProduce,
    stringTrimPrefix,
    urlGetHostname,
    urlNormalizeHttpUrl,
    Values
} from '@wix/devzai-utils-common';
import {DoppeActionTypeFollowViewerLang} from './doppe-action-type-follow-viewer.lang';

export namespace DoppeActionTypeFollow {
    export interface ActionSettings {
        followPageLink: string;
        images: WixImageResource[];
        profileImage: WixImageResource | null;
        followButtonText: string;
        platform: DoppeActionTypeFollow.Platforms,
        posts: DoppeHideableValue<string>,
        followers: DoppeHideableValue<string>,
        nickName: string,
    }

    export const Platforms = {
        Youtube: 'Youtube',
        Twitter: 'Twitter',
        Instagram: 'Instagram',
        Tiktok: 'Tiktok',
        Facebook: 'Facebook',
        Deviantart: 'Deviantart',
        Twitch: 'Twitch',
        Other: 'Other',
    } as const;

    export type Platforms = Values<typeof Platforms>;
}


export const doppeActionTypeFollow = doppeActionTypeDefineMetadata<DoppeActionTypeFollow.ActionSettings>({
    id: 'io.bymo.action/follow',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        followPageLink: '',
        followButtonText: DoppeActionTypeFollowViewerLang.defaultTexts.followButtonText,
        followers: doppeHideableValueCreateHidden(DoppeActionTypeFollowViewerLang.defaultTexts.followers),
        posts: doppeHideableValueCreateHidden(DoppeActionTypeFollowViewerLang.defaultTexts.posts),
        images: [],
        profileImage: '',
        nickName: DoppeActionTypeFollowViewerLang.defaultTexts.nickName,
        platform: DoppeActionTypeFollow.Platforms.Instagram,
    }),
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...actionSettings.images, actionSettings.profileImage]);
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.profileImage) {
                actionSettings.profileImage =
                    wixMediaResourceReplaceExternalImage(actionSettings.profileImage, externalImagesUrlToImageResourceMap)
            }

            actionSettings.images = actionSettings.images.map(
                image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
        })
    },
})

export function doppeActionTypeFollowResolvePlatformFromUrl(url: string) {

    const hostname = stringTrimPrefix(urlGetHostname(urlNormalizeHttpUrl(url)), 'www.')

    switch (hostname) {
        case 'tiktok.com':
            return DoppeActionTypeFollow.Platforms.Tiktok;
        case 'facebook.com':
            return DoppeActionTypeFollow.Platforms.Facebook;
        case 'twitter.com':
            return DoppeActionTypeFollow.Platforms.Twitter;
        case 'deviantart.com':
            return DoppeActionTypeFollow.Platforms.Deviantart;
        case 'twitch.tv':
            return DoppeActionTypeFollow.Platforms.Twitch;
        case 'youtube.com':
            return DoppeActionTypeFollow.Platforms.Youtube;
        case 'patreon.com':
            return DoppeActionTypeFollow.Platforms.Instagram;
        default:
            return DoppeActionTypeFollow.Platforms.Other;
    }

}