import {cachedValueCreate} from "@wix/devzai-utils-common";
import {LocalStorageAccessor, SessionStorageAccessor} from "@wix/devzai-utils-dom";
import {v4 as uuidv4} from "uuid";

export class DoppeViewerClientData {

    private cachedClientId = cachedValueCreate(() => {
        return LocalStorageAccessor.updateItem<string>(
            `clientId`,
            clientId => clientId ?? uuidv4()
        )
    })

    public get clientId () { return this.cachedClientId.getValue(); }

    private cachedSessionId = cachedValueCreate(() => {
        return SessionStorageAccessor.updateItem<string>(
            `sessionId`,
            clientId => clientId ?? uuidv4()
        )
    })

    public get sessionId () { return this.cachedSessionId.getValue(); }
}