import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {DoppeActionTypeVideo} from '../doppe-action-type-video/doppe-action-type-video';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeActionLayout, DoppeActionListItemClickBehaviour} from '../../client-server-common';


export namespace DoppeActionTypeVideosList {

    export interface VideoSettings extends DoppeActionTypeVideo.ActionSettings {
        id: string;
        title: string;
        description: string;

    }

    export interface ActionSettings {
        videos: VideoSettings[];
        layout: DoppeActionLayout;
        latestNonPremiumLayout?: DoppeActionLayout;
        showButton: boolean;
        buttonText: string;
        buttonUrl: string;
        itemClickBehaviour: DoppeActionListItemClickBehaviour
    }
}


export const doppeActionTypeVideosList = doppeActionTypeDefineMetadata<DoppeActionTypeVideosList.ActionSettings>({
    id: 'io.bymo.action/videos-list',
    resolveActionSettings: action => {

        const {
            videos = [],
            ...restSettings
        } = action.settings;

        return {
            videos: videos.map(video => {
                return {
                    videoProviderId: null,
                    videoProvider: null,
                    ...video as PartiallyOptional<DoppeActionTypeVideosList.VideoSettings, 'videoProvider' | 'videoProviderId'>
                }
            }),
            layout: DoppeActionLayout.Grid,
            showButton: false,
            buttonText: 'Visit My Channel',
            buttonUrl: 'https://www.website.com',
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return iterableMapToArray(actionSettings.videos, (video, skip) => {
            return video.videoImage ?? skip
        });
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.videos.length;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.videos) {
                if (item.videoImage) {
                    item.videoImage =
                        wixMediaResourceReplaceExternalImage(item.videoImage, externalImagesUrlToImageResourceMap)
                }
            }
        })
    },

});