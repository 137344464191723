import {DoppeActionLayout} from './doppe-action-layout';
import {DoppeActionListItemClickBehaviour} from './doppe-action-list-item-click-behaviour';

export interface DoppeListActionSettings {
    itemShowDescription: boolean;
    itemShowImage: boolean;
    itemShowTitle: boolean;
    layout: DoppeActionLayout;
    latestNonPremiumLayout?: DoppeActionLayout;
    itemClickBehaviour: DoppeActionListItemClickBehaviour;
    showButton: boolean;
    buttonText: string;
    buttonUrl: string;
}


export namespace DoppeListActionSettings {

    export interface WithExternalDataSourceSupport<T extends {}> {
        externalDataSource: null | T;
        externalDataSourceItemsCount: number;
    }
}


export function getDefaultDoppeListActionSettings() {
    return {
        itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
        layout: DoppeActionLayout.Column,
        showButton: false,
        buttonText: 'Visit My Store',
        buttonUrl: 'https://www.website.com',

        itemShowDescription: true,
        itemShowTitle: true,
        itemShowImage: true,
    }
}

