import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeDtoBymoSubscribeListId} from '../../client-server-common/types/doppe-dto-bymo-subscribe-list';
import {DoppeActionTypeContactFormViewerLang} from './doppe-action-type-contact-form-viewer.lang';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';

export namespace DoppeActionTypeContactForm {

    export interface ActionSettings {
        listId: DoppeDtoBymoSubscribeListId | null;
        showConsentCheckbox: boolean;
        subscribeButtonText: string;
        collectEmail: boolean;
        collectPhoneNumber: boolean;
        collectName: boolean;
        collectAddress: boolean;
        collectZip: boolean;
        collectMessage: boolean;
        customConsentText: DoppeHideableValue<string>;
        withEmailNotification: boolean;
        customThankYouMessage: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeContactFormMetadata = doppeActionTypeDefineMetadata<DoppeActionTypeContactForm.ActionSettings>({
    id: 'co.hopp.action/contact-form',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        collectEmail: true,
        collectPhoneNumber: true,
        collectName: true,
        collectAddress: true,
        collectZip: true,
        collectMessage: false,
        listId: null,
        showConsentCheckbox: false,
        subscribeButtonText: DoppeActionTypeContactFormViewerLang.defaultTexts.subscribeButtonText,
        customConsentText: doppeHideableValueCreateHidden(''),
        withEmailNotification: false,
        customThankYouMessage: doppeHideableValueCreateHidden(''),
    }),
    isWidgetOpener: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    canRenderInPage: true
})