import React from 'react';
import {style, classes, vars} from './image-view.st.css';
import {HtmlObjectResizeMode, Values} from '@wix/devzai-utils-common';

export const ImageViewResizeMode = HtmlObjectResizeMode;

export type ImageViewResizeMode = Values<typeof ImageViewResizeMode>;

export const ImageViewPositionVertical = {
    Top: 'top',
    Center: 'center',
    Bottom: 'bottom',
} as const;

export type ImageViewPositionVertical = Values<typeof ImageViewPositionVertical>;

export const ImageViewPositionHorizontal = {
    Left: 'left',
    Center: 'center',
    Right: 'right',
} as const;

export type ImageViewPositionHorizontal = Values<typeof ImageViewPositionHorizontal>;


export interface ImageViewProps extends React.ImgHTMLAttributes<HTMLImageElement>{
    resizeMode?: ImageViewResizeMode;
    positionVertical?: ImageViewPositionVertical;
    positionHorizontal?: ImageViewPositionHorizontal;
    imageElementRef?: React.Ref<HTMLImageElement>;
}

export const ImageView = React.memo(function ImageView(props: ImageViewProps) {

    const {
        resizeMode = ImageViewResizeMode.Contain,
        positionVertical = 'top',
        positionHorizontal = 'center',
        imageElementRef,

        // img HTML attributes default overrides
        decoding = 'async',

        style: cssProps,
        src,
        className,
        ...htmlAttributes

    } = props;

    return (
        <img
            ref={imageElementRef}
            {...htmlAttributes}
            className={style(classes.root, className)}
            style={{
                [vars.objectFit]: resizeMode,
                [vars.objectPositionVertical]: positionVertical,
                [vars.objectPositionHorizontal]: positionHorizontal,
                ...cssProps
            }}
            src={src}
            decoding={decoding}
        />
    )

});

export namespace ImageView {

    export type Props = ImageViewProps;

}