import {EventEmitter, EventReference, IObservable, IObservableValue} from '@wix/devzai-utils-common';

const defaultVersionKeySuffix = '---version---';

export type StorageItemVersion = number;

export interface VersionedStorageKey {
    key: string;
    version: StorageItemVersion;
    versionKeySuffix?: string;
}

export type StorageItemKey = string | VersionedStorageKey;

const NoValue: unique symbol = Symbol('NoValue');

export class BrowserStorageValue<VALUE> implements IObservableValue<VALUE | undefined> {

    private eventEmitter = new EventEmitter<IObservable.Events>();
    private lastValue: VALUE | undefined | typeof NoValue = NoValue;

    constructor(
        private storageAccessor: BrowserStorageAccessor,
        private key: StorageItemKey
    ) {

    }

    public get eventUpdated () : EventReference<IObservable.Events, 'eventUpdated'> {
        return this.eventEmitter.createEventReference('eventUpdated')
    }

    public remove () {
        this.storageAccessor.removeItem(this.key);
    }

    public getValue () : VALUE | undefined {

        const lastValue = this.lastValue;
        if (lastValue !== NoValue) {
            return lastValue;
        }

        return this.lastValue = this.storageAccessor.getItem<VALUE>(this.key);
    }

    public setValue (value: VALUE | ((prevValue?: VALUE) => VALUE)) {

        const updatedValue = typeof value === 'function' ?
            (value as (prevValue?: VALUE) => VALUE)(this.getValue()) :
            value;

        if (updatedValue !== this.lastValue) {
            this.lastValue = updatedValue;
            this.storageAccessor.setItem<VALUE>(this.key, updatedValue)

            this.eventEmitter.emit('eventUpdated');
        }

    }

}

export class BrowserStorageValueAccessor<
    VALUE,
    WITH_SANITIZER extends boolean,
    SANITIZED_VALUE = WITH_SANITIZER extends false ? VALUE | undefined : VALUE
> {

    constructor(
        private storageAccessor: BrowserStorageAccessor,
        private key: string,
        private valueSanitizer: WITH_SANITIZER extends false ? undefined : ((value: VALUE | undefined) => SANITIZED_VALUE)
    ) {}

    public getValue () : SANITIZED_VALUE {
        const value = this.storageAccessor.getItem<VALUE>(this.key);

        if (this.valueSanitizer !== undefined) {
            return this.valueSanitizer(value);
        } else {
            return value as SANITIZED_VALUE;
        }
    }

    public setValue (value: VALUE) {
        this.storageAccessor.setItem<VALUE>(this.key, value)
    }

    public updateValue (updateFunc: (value: SANITIZED_VALUE) => VALUE) {
        this.setValue(updateFunc(this.getValue()));
    }

    public clearValue () {
        this.storageAccessor.removeItem(this.key);
    }
}

export class BrowserStorageAccessor {

    private storage: Storage | undefined;

    private storageValuesCache = new Map<string, BrowserStorageValue<any>>();

    constructor (storageName: string) {
        this.storage = getBrowserStorage(storageName);
    }

    public get isSupported () {
        return this.storage !== undefined;
    }

    public removeItem (storageItemKey: StorageItemKey) {
        if (this.storage)
        {
            const {
                key
            } = normalizeStorageItemKey(storageItemKey)

            this.storage.removeItem(key);
        }
    }

    public getStorageKeys (keyPrefix?: string) {

        const storage = this.storage;

        if (!storage)
        {
            return [];
        }

        const keys = [];
        const storageLength = storage.length;
        for (let i = 0; i < storageLength; i++)
        {
            const storageKey = storage.key(i)!;

            if (!keyPrefix || storageKey.startsWith(keyPrefix))
            {
                keys.push(storageKey);
            }
        }
        return keys;
    }

    public removeItemsByKeyPrefix (keyPrefix: string) {

        const storage = this.storage;

        if (storage)
        {
            const keys = this.getStorageKeys(keyPrefix);

            for (const key of keys)
            {
                storage.removeItem(key);
            }
        }
    }

    public createValueAccessor<VALUE> (key: string) : BrowserStorageValueAccessor<VALUE, false>
    public createValueAccessor<VALUE> (key: string, valueSanitizer: (value: VALUE | undefined) => VALUE) : BrowserStorageValueAccessor<VALUE, true>
    public createValueAccessor<VALUE> (
        key: string,
        valueSanitizer?: (value: VALUE | undefined) => VALUE
    ) : BrowserStorageValueAccessor<VALUE, boolean> {
        return new BrowserStorageValueAccessor<VALUE, boolean>(this, key, valueSanitizer);
    }

    public createStorageValue<VALUE> (key: string) : BrowserStorageValue<VALUE> {

        const cachedStorageValue = this.storageValuesCache.get(key);

        if (cachedStorageValue) {
            return cachedStorageValue;
        } else {
            const storageValue = new BrowserStorageValue<VALUE>(this, key);

            this.storageValuesCache.set(key, storageValue);

            return storageValue;
        }
    }

    public getItem<T> (storageItemKey: StorageItemKey, defaultValue: T) : T;
    public getItem<T> (storageItemKey: StorageItemKey) : T | undefined;
    public getItem<T> (storageItemKey: StorageItemKey, defaultValue?: T) : T | undefined {

        const {
            key,
            version,
            versionKeySuffix
        } = normalizeStorageItemKey(storageItemKey);

        const storage = this.storage;

        if (!storage)
        {
            return defaultValue;
        }

        if (version !== undefined)
        {
            const currentVersionStringified = storage.getItem(key + versionKeySuffix);

            if (currentVersionStringified === null)
            {
                return defaultValue;
            }

            try
            {
                const currentVersion = JSON.parse(currentVersionStringified);

                if (currentVersion !== version)
                {
                    return defaultValue;
                }
            }
            catch (e)
            {
                return defaultValue;
            }
        }

        const item = storage.getItem(key);

        if (item !== null)
        {
            try
            {
                return JSON.parse(item);
            }
            catch (e)
            {
                return defaultValue;
            }
        }
        else
        {
            return defaultValue;
        }
    }

    public updateItem<T> (storageItemKey: StorageItemKey, updateFunction: (value: T | undefined) => T) {
        const item = this.getItem<T>(storageItemKey);

        const updatedValue = updateFunction(item);

        this.setItem(storageItemKey, updatedValue);

        return updatedValue;
    }

    public setItem<T> (storageItemKey: StorageItemKey, value: T) {

        const {
            key,
            version,
            versionKeySuffix
        } = normalizeStorageItemKey(storageItemKey);

        const storage = this.storage;

        if (storage)
        {
            storage.setItem(key, JSON.stringify(value));

            if (version !== undefined)
            {
                storage.setItem(key + versionKeySuffix, JSON.stringify(version));
            }
        }
    }
}

export const LocalStorageAccessor = new BrowserStorageAccessor('localStorage');
export const SessionStorageAccessor = new BrowserStorageAccessor('sessionStorage');

function getBrowserStorage (storageName: string) : Storage | undefined {
    try
    {
        // This will throw an exception in some browsers when cookies/localStorage are explicitly disabled (i.e. Chrome)
        const browserStorage = (window as any)[storageName];
        browserStorage.setItem('TEST', '1');
        browserStorage.removeItem('TEST');
        return browserStorage;
    }
    catch (e)
    {
        return undefined;
    }
}

function normalizeStorageItemKey (storageItemKey: StorageItemKey) {
    if (typeof storageItemKey === 'string')
    {
        return {
            key: storageItemKey,
            version: undefined,
            versionKeySuffix: undefined
        }
    }
    else
    {
        const {
            key,
            version,
            versionKeySuffix = defaultVersionKeySuffix
        } = storageItemKey;

        return {key, version, versionKeySuffix}
    }
}
