import {Color, colorNormalizeHexValue, colorToHex} from '../color-utils/color-utils';
import {arrayGetItemCyclic} from "../array-utils";

export type MaterialPrimaryColorPalette = {
    palette: string[];
    primaryColorIndex: number;
}

export async function colorPaletteGenerateMaterialPrimary (
    primaryColor: Color | string
) {
    // @ts-ignore
    const Matercolor = (await import('matercolors')).default;

    const baseHexColor = typeof primaryColor === 'string' ? colorNormalizeHexValue(primaryColor, null) : colorToHex(primaryColor);

    if (baseHexColor) {
        try {

            const palette = new Matercolor(baseHexColor).makePalette('primary', false);

            const normalizedPalette = ['900', '800', '700', '600', '500', '400', '300', '200', '100', '50'].map(level => {
                return palette[level].toLowerCase();
            });

            return {
                palette: normalizedPalette,
                primaryColorIndex: normalizedPalette.indexOf(baseHexColor.toLowerCase())
            } as MaterialPrimaryColorPalette
        } catch (error) {
            return null;
        }
    } else {
        return null;
    }
}

export function materialPrimaryColorPaletteResolvePrimaryColor (materialPrimaryColorPalette: MaterialPrimaryColorPalette) {
    return materialPrimaryColorPalette.palette[materialPrimaryColorPalette.primaryColorIndex];
}

export function materialPrimaryColorPaletteResolvePrimaryRelativeColor (
    materialPrimaryColorPalette: MaterialPrimaryColorPalette,
    relativeIndex: number
) {
    return arrayGetItemCyclic(materialPrimaryColorPalette.palette, materialPrimaryColorPalette.primaryColorIndex + relativeIndex)
}