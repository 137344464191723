import './bymo-page-viewer-page.st.css';
import {BymoTemplatesStore} from "../../../client-server-common/bymo-templates-store/bymo-templates-store";
import {
    DoppeDtoBymoLinkViewerData,
    doppeDtoBymoLinkViewerDataResolvePageType
} from "../../../client-server-common/types/doppe-dto-bymo-link-viewer-data";
import React from "react";
import {
    BymoPageViewerContext,
    BymoPageViewerLiveMode
} from "../../../components/bymo-page-viewer-context/bymo-page-viewer-context";
import {BymoPageViewer} from "../../../components/bymo-page-viewer/bymo-page-viewer";
import {
    DoppeActionTypesStore,
    doppeActionTypesStoreLoadUsedActionTypesViewerComponents
} from "../../common-client-services/doppe-action-types-store/doppe-action-types-store";
import {
    BymoPageViewerComponentsContext
} from "../../../components/bymo-page-viewer-components-context/bymo-page-viewer-components-context";
import {doppeDtoUserGetLinkedMetaSiteId} from "../../../client-server-common";

export async function bymoPageViewerPageAsyncCreate (
    options: {
        actionTypesStore: DoppeActionTypesStore;
        bymoTemplatesStore: BymoTemplatesStore;
        bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithConnectedPage;
        mode: BymoPageViewerLiveMode;
    }
) {
    const {
        actionTypesStore,
        bymoTemplatesStore,
        bymoLinkViewerData,
        mode
    } = options;

    const {
        pageData: {
            bymoPageProps,
            bymoTemplateId,
            ownerData,
            premiumFeaturesStatus
        },
        bymoPagePropsOverrides,
        targetUrl,
        userDisplayName,
        userProfilePicture,
        userTermsOfServiceUrl,
        userPrivacyPolicyUrl,
        userCookieBannerSettings,
        bymoPageContextProps,
        userAccountName,
        openGraphImage,
        viewerFeatures
    } = bymoLinkViewerData;

    const bymoTemplateData = await bymoTemplatesStore.getBymoTemplateData(bymoTemplateId);

    const actionTypesViewerComponents = await doppeActionTypesStoreLoadUsedActionTypesViewerComponents({
        bymoTemplateId: bymoTemplateId,
        actionTypesStore: actionTypesStore,
        bymoPageProps: bymoPageProps
    })

    return (
        <BymoPageViewerComponentsContext.Provider
            value={{
                actionTypesViewerComponents: actionTypesViewerComponents
            }}
        >
            <BymoPageViewerContext.Provider
                value={{
                    mode: mode,
                    linkId: bymoLinkViewerData.linkId,
                    bymoPageId: bymoLinkViewerData.bymoPageId,
                    doppeUserId: bymoLinkViewerData.doppeUserId,
                    pageType: doppeDtoBymoLinkViewerDataResolvePageType(bymoLinkViewerData)
                }}
            >
                <BymoPageViewer
                    bymoPageComponentType={bymoTemplateData.componentType}
                    bymoPageContextProps={{
                        ...viewerFeatures,
                        targetUrl: targetUrl,
                        targetUrlImage: openGraphImage ?? null,
                        userDisplayName: userDisplayName,
                        userAccountName: userAccountName,
                        userProfilePicture: userProfilePicture,
                        userTermsOfServiceUrl: userTermsOfServiceUrl,
                        userPrivacyPolicyUrl: userPrivacyPolicyUrl,
                        userCookieBannerSettings: userCookieBannerSettings,
                        linkedMetaSiteId: doppeDtoUserGetLinkedMetaSiteId(ownerData),
                        premiumFeaturesStatus: premiumFeaturesStatus,
                        ...bymoPageContextProps
                    }}
                    bymoPagePropsForViewer={{
                        ...bymoPageProps,
                        ...bymoPagePropsOverrides
                    }}
                />
            </BymoPageViewerContext.Provider>
        </BymoPageViewerComponentsContext.Provider>
    )
}