/**
 * Inspiration: https://github.com/jshttp/cookie/blob/master/index.js;
 */

const pairSplitRegExp = /; */;

type CookieAttributes = {
    path?: string;
    domain?: string;
    maxAge?: number;
    expires?: Date;
    secure?: boolean;
    sameSite?: 'strict' | 'lax' | 'none';
    [key: string]: string | number | Date | boolean | undefined;
};

export function cookieParse(str: string) {

    const obj: Record<string, string> = {};
    const pairs = str.split(pairSplitRegExp);

    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i];
        let eq_idx = pair.indexOf('=');

        // skip things that don't look like key=value
        if (eq_idx < 0) {
            continue;
        }

        const key = pair.substr(0, eq_idx).trim();
        let val = pair.substr(++eq_idx, pair.length).trim();

        // quoted values
        if ('"' == val[0]) {
            val = val.slice(1, -1);
        }

        // only assign once
        if (undefined == obj[key]) {
            obj[key] = tryDecode(val);
        }
    }

    return obj;
}


export function cookieUpdate(key: string, value: string, attributes?: CookieAttributes) {
    let updatedCookie = `${key}=${encodeURIComponent(value)}`;

    if (attributes) {

        if (attributes.path) {
            updatedCookie += `; path=${attributes.path}`;
        }

        if (attributes.domain) {
            updatedCookie += `; domain=${attributes.domain}`;
        }

        if (attributes.maxAge) {
            updatedCookie += `; max-age=${attributes.maxAge}`;
        }

        if (attributes.expires) {
            updatedCookie += `; expires=${attributes.expires.toUTCString()}`;
        }

        if (attributes.secure) {
            updatedCookie += `; secure`;
        }

        if (attributes.sameSite) {
            updatedCookie += `; samesite=${attributes.sameSite}`;
        }
    }

    return updatedCookie;
}


function tryDecode(str: string) {
    try {
        return decodeURIComponent(str);
    } catch (e) {
        return str;
    }
}
