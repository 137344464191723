import {bymoTemplateMetadataFactoryCreate} from "../../../doppe-sdk";
import {Values} from "@wix/devzai-utils-common";
import {BymoPageSocialLinksProps} from "../../../client-server-common";
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from "../../../client-server-common/types/doppe-hideable-value";

export const BymoTemplateSpeedDialInputType = {
    PinInput: 'PinInput',
    TextBox: 'TextBox'
} as const;

export type BymoTemplateSpeedDialInputType = Values<typeof BymoTemplateSpeedDialInputType>;

export const BymoTemplateSpeedDialAutoComplete = {
    Hidden: 'Hidden',
    OnInputFocus: 'OnInputFocus',
    OnInputSearch: 'OnInputSearch'
} as const;

export type BymoTemplateSpeedDialAutoComplete = Values<typeof BymoTemplateSpeedDialAutoComplete>;

export const BymoTemplateSpeedDialActionsListAppearance = {
    Visible: 'Visible',
    Hidden: 'Hidden',
    Collapsed: 'Collapsed'
} as const;

export type BymoTemplateSpeedDialActionsListAppearance = Values<typeof BymoTemplateSpeedDialActionsListAppearance>;

export const BymoTemplateSpeedDialSearchAppearance = {
    Default: 'Default',
    Hidden: 'Hidden',
} as const;

export type BymoTemplateSpeedDialSearchAppearance = Values<typeof BymoTemplateSpeedDialSearchAppearance>;

export namespace BymoTemplateSpeedDial {

    export interface TemplateProps extends BymoPageSocialLinksProps {
        autoCompleteBehaviour: BymoTemplateSpeedDialAutoComplete;
        showFullScreenAutoCompleteOnMobile: boolean;
        actionsHeading: DoppeHideableValue<string>;
        actionsListAppearance: BymoTemplateSpeedDialActionsListAppearance;
        bioText: DoppeHideableValue<string>;
        bioTextMaxLines: DoppeHideableValue<number>;
        searchAppearance: BymoTemplateSpeedDialSearchAppearance;
        searchPlaceholder: DoppeHideableValue<string>;
    }
}

export const BymoTemplateSpeedDialTemplateMetadata = bymoTemplateMetadataFactoryCreate<BymoTemplateSpeedDial.TemplateProps>()({
    initialValues: {
        autoCompleteBehaviour: BymoTemplateSpeedDialAutoComplete.OnInputSearch as BymoTemplateSpeedDialAutoComplete,
        showFullScreenAutoCompleteOnMobile: false,
        actionsHeading: '',
        bioText: '',
        actionsListAppearance: BymoTemplateSpeedDialActionsListAppearance.Visible as BymoTemplateSpeedDialActionsListAppearance,

        socialLinks: [],
        bioTextMaxLines: doppeHideableValueCreateHidden(3),
        searchAppearance: BymoTemplateSpeedDialSearchAppearance.Default as BymoTemplateSpeedDialSearchAppearance,
        searchPlaceholder: doppeHideableValueCreateHidden("")
    },
    features: [
        'hasActionButton',
        'hasProfileContent',
        'hasVisibleBackground',
        'hasCustomTextualContent',
        'hasActions',
        'canBeSkipped',
    ]
})