import {isDynamicRef, PositioningArea, reactIsRefObject, RenderFunction, useForceUpdate} from '../index';
import ReactDOM from 'react-dom';
import {Evaluable, evaluateWhenFunction, PartiallyRequired} from '@wix/devzai-utils-common';
import React, {useContext} from 'react';

export type FloatingWindowsTargetContainer = React.RefObject<HTMLElement> | null | Evaluable<() => Element>;

export interface FloatingWindowsContainerInfo {
    zIndex?: number;
    boundary?: PositioningArea;
    targetContainer?: FloatingWindowsTargetContainer;
}

export type FloatingWindowsContainerScope =
    () => PartiallyRequired<FloatingWindowsContainerInfo, 'targetContainer'>;

export const FloatingWindowsContainerScopeContext = React.createContext<FloatingWindowsContainerScope | null>(null);

export interface FloatingWindowRenderProps {
    zIndex?: number;
    boundary?: PositioningArea;
}

export type FloatingWindowRenderFunction = RenderFunction<FloatingWindowRenderProps, React.ReactElement | null>;

export interface FloatingWindowProps {
    containerInfo?: FloatingWindowsContainerInfo | null;
    children: Evaluable<FloatingWindowRenderFunction>;
}

export const FloatingWindow = React.memo(function FloatingWindow(props: FloatingWindowProps) {

    const {
        children,
        containerInfo = null
    } = props;

    const forceUpdate = useForceUpdate();

    if (containerInfo === null) {
        return evaluateWhenFunction(children, {
            zIndex: undefined
        });
    } else {
        const targetContainer = containerInfo.targetContainer ?? null;

        if (targetContainer == null) {
            return evaluateWhenFunction(children, {
                zIndex: containerInfo.zIndex,
                boundary: containerInfo.boundary
            });
        } else {
            const targetContainerElement =
                reactIsRefObject(targetContainer) ? targetContainer.current : evaluateWhenFunction(targetContainer);

            if (targetContainerElement) {
                return ReactDOM.createPortal(
                    evaluateWhenFunction(children, {
                        zIndex: containerInfo.zIndex,
                        boundary: containerInfo.boundary
                    }),
                    targetContainerElement
                );
            } else {
                if (typeof targetContainer !== 'function' && isDynamicRef(targetContainer)) {
                    targetContainer.onRefWasChanged(() => {
                        forceUpdate();
                    });
                }

                return null;
            }
        }
    }
});

export function useFloatingWindowsContainerScope () {
    return useContext(FloatingWindowsContainerScopeContext);
}