import {DoppeDtoAction, doppeDtoActionGetDefaultProps} from '../types/doppe-dto-action';
import React from 'react';
import {DoppeActionComponentActionButtonProps, IDoppeViewerActionContext} from '../../doppe-sdk';
import {
    DoppeActionTypeFullMetadata
} from '../../client/doppe-action-type-client-metadata/doppe-action-type-client-metadata';
import {Evaluable, objectMapValues, ObservableValue, OmitStrict, PartiallyRequired} from '@wix/devzai-utils-common';
import {v4 as uuidv4} from 'uuid';
import {doppeHideableValueCreateHidden} from '../types/doppe-hideable-value';
import {WixImageResource, WixMediaResource} from "@wix/devzai-common-wix";
import {DoppeBusinessEntityReference} from "../types";
import {DoppeActionWidgetPopupMode} from "../types/doppe-action-widget-popup-mode";

export namespace DoppeActionType {
    export type InferProps<ACTION_TYPE extends DoppeActionType<any>> =
        ACTION_TYPE extends DoppeActionType<infer PROPS> ? PROPS : never;

    export type InstantiationData<ACTION_TYPE extends DoppeActionType<any>> = {
        settings?: Partial<DoppeActionType.InferProps<ACTION_TYPE>>
    } & OmitStrict<
        PartiallyRequired<Partial<DoppeDtoAction<DoppeActionType.InferProps<ACTION_TYPE>>>, 'name'>,
        'settings' | 'isDummyAction' | 'actionTypeId'
    >


    export interface WidgetProps<PROPS extends {}> extends React.HTMLAttributes<any> {
        actionContext: IDoppeViewerActionContext<PROPS>;
    }

}

export type DoppeActionTypeViewerMetadata<PROPS extends {}, VIEWER_COMP = any> = {
    /**
     * An optional function that provides an in-page view for an instance of this action type, that's an
     * alternative for the standard action button.
     */
    renderWidget?: (renderProps: DoppeActionType.WidgetProps<PROPS>, viewerComponents: VIEWER_COMP) => React.ReactElement;

    /**
     * An action type can provide this function that returns the properties that should be used to render
     * the action component for it, when it's rendered as an action button.
     */
    getActionButtonProps?: (actionContext: IDoppeViewerActionContext<PROPS>, observableActionContext: ObservableValue<IDoppeViewerActionContext<PROPS>>) => DoppeActionComponentActionButtonProps;

    activateCTA?: (actionContext: IDoppeViewerActionContext<PROPS>, observableActionContext: ObservableValue<IDoppeViewerActionContext<PROPS>>) => void;

    loadViewerComponents?: () => Promise<VIEWER_COMP>;

    widgetPopupMode?: DoppeActionWidgetPopupMode;
}



export type DoppeActionTypeMetadata<PROPS extends {}> = {
    id: DoppeActionType.Id;

    /**
     * Even though the settings of an action of a specific type are stored on it, it's possible
     * that some properties were added to an action type since the action was previously saved.
     * This function will be called for any action that was instantiated from this action type to ensure
     * that the settings are as expected. Therefore, this function helps to ensure backward compatibility
     * along the way.
     */
    resolveActionSettings: (action: DoppeDtoAction<Partial<PROPS>>) => PROPS;

    canRenderInPage?: boolean;

    isWidgetOpener?: Evaluable<(actionContext: IDoppeViewerActionContext<PROPS>) => boolean>;

    isLinkOpener?: Evaluable<(actionContext: IDoppeViewerActionContext<PROPS>) => boolean>;

    isModalOpener?: Evaluable<(actionContext: IDoppeViewerActionContext<PROPS>) => boolean>;

    hasNoGoalMeasuring?: boolean;

    skipReportingOnGoalAchievedOnActionButtonClick?: Evaluable<(actionContext: IDoppeViewerActionContext<PROPS>) => boolean>;

    withoutClientMetadata?: boolean;

    isPaymentAction?: boolean;

    /**
     * Action that renders media should provide this method to resolve such media from its settings.
     * This can be used for:
     * - Background importing of external images that are used by the action.
     * - Used media suggestion when replacing a media in one of the page settings.
     */
    resolveUsedMedia?: (actionSettings: PROPS) => WixMediaResource[];

    replaceExternalImages?: (actionSettings: PROPS, externalImagesUrlToImageResourceMap: Map<string, WixImageResource>) => PROPS;

    resolveUsedBusinessEntities?: (actionSettings: PROPS) => DoppeBusinessEntityReference[];

    resolveListItemsCount?: (actionSettings: PROPS) => number;

    isConnectedToExternalDataSource?: (actionSettings: PROPS) => boolean;
}

export type DoppeActionType<PROPS extends {}> = DoppeActionTypeMetadata<PROPS> & DoppeActionTypeViewerMetadata<PROPS>;

export namespace DoppeActionType {
    export type Id = string;
}

export function doppeActionTypeSupportsInPageView(actionType: DoppeActionType<any>) {
    return actionType.canRenderInPage ?? false;
}

export function doppeActionTypeSupportsOpeningWidgetAsPopup (actionType: DoppeActionType<any>) {
    return actionType.widgetPopupMode !== undefined;
}

export function doppeActionTypeSupportsOpeningWidgetAsLink (actionType: DoppeActionType<any>) {
    return actionType.isLinkOpener ?? false;
}


export function doppeActionTypeCreateSettingsResolvingFunction<SETTINGS extends {}>(settings: SETTINGS) {
    return (action: DoppeDtoAction<any>) => {

        const actionSettings = action.settings;

        return objectMapValues(settings, (value, key) => {
            return actionSettings[key] ?? value;
        }) as SETTINGS;
    }
}

export function doppeActionTypeResolveSettingsFromAction<SETTINGS extends {}>(
    actionType: DoppeActionType<SETTINGS>,
    action: DoppeDtoAction<SETTINGS>
) {
    return actionType.resolveActionSettings(action)
}

/**
 * @deprecated You should not create a DoppeActionType object, but to register the metadata and the viewer metadata in
 * DoppeActionTypesStore and in DoppeActionTypesMetadataStore
 */
export function doppeActionTypeDefineNew<PROPS extends {} = {}>(
    actionTypeMetadata: DoppeActionTypeMetadata<PROPS>,
    actionTypeViewerMetadata: DoppeActionTypeViewerMetadata<PROPS>
) : DoppeActionType<PROPS> {
    return {
        ...actionTypeMetadata,
        ...actionTypeViewerMetadata
    }
}

export function doppeActionTypeDefineMetadata<PROPS extends {} = {}>(actionType: DoppeActionTypeMetadata<PROPS>) {
    return actionType;
}

export function doppeActionTypeDefineViewerMetadata<PROPS extends {} = {}, VIEWER_COMP = unknown>(
    _actionType: DoppeActionTypeMetadata<PROPS>,
    viewerMetadata: DoppeActionTypeViewerMetadata<PROPS, VIEWER_COMP>
) {
    return viewerMetadata;
}

export function doppeActionTypeInstantiate<ACTION_TYPE extends DoppeActionType<any>>(
    actionType: ACTION_TYPE,
    actionData: DoppeActionType.InstantiationData<ACTION_TYPE>
): DoppeDtoAction<DoppeActionType.InferProps<ACTION_TYPE>> {

    const actionDefaultProps = doppeDtoActionGetDefaultProps();

    const action = {
        id: actionData.id ?? uuidv4(),
        actionTypeId: actionType.id,
        name: actionData.name,
        callToActionDescription: actionData.callToActionDescription ?? doppeHideableValueCreateHidden(''),
        enabled: actionData.enabled ?? true,
        icon: actionData.icon ?? actionDefaultProps.icon,
        widgetOpeningMode: actionData.widgetOpeningMode ?? actionDefaultProps.widgetOpeningMode,
        settings: {
            ...actionData.settings
        },
        viewType: actionData.viewType,
        showActionHeader: actionData.showActionHeader ?? true,
        tag: actionData.tag,
    } satisfies DoppeDtoAction;

    return {
        ...action,
        settings: doppeActionTypeResolveSettingsFromAction(actionType, action)
    }
}

export interface IDoppeActionFactory {

    createAction<ACTION_TYPE extends DoppeActionType<any>>(
        actionType: ACTION_TYPE,
        actionData: {
            name: DoppeDtoAction['name'];
            enabled?: DoppeDtoAction['enabled'];
            viewType?: DoppeDtoAction['viewType'];
            showActionHeader?: DoppeDtoAction['showActionHeader'];
            settings: DoppeActionType.InferProps<ACTION_TYPE>
        }
    ): DoppeDtoAction<DoppeActionType.InferProps<ACTION_TYPE>>;

}

export function getActionTitleByCount(actionType: DoppeActionTypeFullMetadata<any>, count: number) {
    if (actionType.actionTitle) {
        return count === 1 ? actionType.actionTitle.single : actionType.actionTitle.multiple;
    }

    return ''
}