import type {DoppeActionType, DoppeActionTypeMetadata} from '../doppe-action-types/doppe-action-type';
import {assertType, Values} from '@wix/devzai-utils-common';
import {assertWixMediaResource, WixMediaResource} from '@wix/devzai-common-wix';
import {assertDoppeHideableValue, DoppeHideableValue} from './doppe-hideable-value';
import {DoppeActionWidgetOpeningMode} from "./doppe-action-widget-opening-mode";

export const DoppeDtoActionViewType = {
    ActionButton: 'ActionButton',
    InPage: 'InPage',
    InPageFrameless: 'InPageFrameless'
} as const;

export type DoppeDtoActionViewType = Values<typeof DoppeDtoActionViewType>;

export interface DoppeDtoAction<SETTINGS extends {} = any> {
    id: string;
    actionTypeId: DoppeActionType.Id;
    name: string;
    callToActionDescription: DoppeHideableValue<string>;
    enabled: boolean;
    icon: DoppeHideableValue<WixMediaResource | null>;
    settings: SETTINGS;
    widgetOpeningMode: DoppeActionWidgetOpeningMode;
    viewType?: DoppeDtoActionViewType;
    showActionHeader?: boolean;
    isDummyAction?: boolean;
    tag?: string;
}

const doppeDtoActionDefaultProps = {
    widgetOpeningMode: DoppeActionWidgetOpeningMode.Inline,
    icon: null,
    tag: undefined
}

export type DoppeDtoActionDefaultPropsNames = keyof typeof doppeDtoActionDefaultProps;

export function doppeDtoActionGetDefaultProps () {
    return doppeDtoActionDefaultProps;
}

export namespace DoppeDtoAction {
    export type Id = DoppeDtoAction['id'];
}

export function assertDoppeDtoAction(action: DoppeDtoAction): asserts action is DoppeDtoAction;
export function assertDoppeDtoAction(action: unknown): asserts action is DoppeDtoAction;
export function assertDoppeDtoAction(action: unknown): asserts action is DoppeDtoAction {
    assertType<DoppeDtoAction<{}>>(action, assert => {
        assert.isObject({
            id: assert => assert.isString(),
            actionTypeId: assert => assert.isString(),
            name: assert => assert.isString(),

            enabled: assert => assert.isBoolean(),
            widgetOpeningMode: assert => assert.isString(),
            callToActionDescription: assert => assert.isUnion(
                assert => assert.isString(),
                assert => assert.usingAssertionFunction(value => {
                    return assertDoppeHideableValue<string>(value, assert => assert.isString())
                }),
            ),
            icon: assert => assert.usingAssertionFunction(value => {
                assertDoppeHideableValue<WixMediaResource | null>(value, assert => {
                    assert.isUnion(
                        assert => assert.isNull(),
                        assert => assert.usingAssertionFunction(assertWixMediaResource)
                    )
                })
            }),
            settings: assert => assert.isObject({}, {
                assertNoUnknownKeys: false
            }),
            viewType: assert => assert.optional.isOneOfValues(Object.values(DoppeDtoActionViewType)),
            showActionHeader: assert => assert.optional.isBoolean(),
            isDummyAction: assert => assert.optional.isBoolean(),
            tag: assert => assert.optional.isString()
        })
    })
}

export function doppeActionIsOfType<T extends {}> (
    doppeAction: DoppeDtoAction,
    doppeActionType: DoppeActionTypeMetadata<T>
) : doppeAction is DoppeDtoAction<T> {
    return doppeAction.actionTypeId === doppeActionType.id;
}

export function doppeActionResolveSettings<T extends {}> (
    doppeAction: DoppeDtoAction<T>,
    actionTypeMetadata: DoppeActionTypeMetadata<T>
) {
    return actionTypeMetadata.resolveActionSettings(doppeAction)
}