import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeHideableValue} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeActionPriceData} from "../../client-server-common";

export namespace DoppeActionTypePatreon {
    export interface ActionSettings {
        patreonTierlink: string;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        items: string[];
        linkButtonText: string;
    }
}

export const doppeActionTypePatreon = doppeActionTypeDefineMetadata<DoppeActionTypePatreon.ActionSettings>({
    id: 'io.bymo.action/patreon',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        patreonTierlink: '',
        priceData: {
            actualPrice: '10$ Per Month',
        },
        items: ['Membership Benefit'],
        linkButtonText: 'See More',
    }),
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
})