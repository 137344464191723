import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {Values} from '@wix/devzai-utils-common';
import {DoppeHideableValue} from '../../client-server-common';


export const SpotifyWidgetSize = {
    Normal: 'Normal',
    Compact: 'Compact',
} as const;

export const SpotifyWidgetTheme = {
    Default: 'Default',
    Dark: 'Dark',
} as const;


export namespace DoppeActionTypeSpotify {

    export type Size = Values<typeof SpotifyWidgetSize>;
    export type Theme = Values<typeof SpotifyWidgetTheme>;

    export interface ActionSettings {
        url: string;
        theme: Theme;
        size: Size;
        buttonText: DoppeHideableValue<string>;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeSpotify = doppeActionTypeDefineMetadata<DoppeActionTypeSpotify.ActionSettings>({
    id: 'io.bymo.action/spotify',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        url: '',
        theme: SpotifyWidgetTheme.Default,
        size: SpotifyWidgetSize.Normal,
        buttonText: 'Listen on Spotify',
        title: 'Title',
        description: 'Description',
    }),
    hasNoGoalMeasuring: false,
    isWidgetOpener: true,
    isModalOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
})

