import {
    assertWixImageResource,
    assertWixVideoInfo,
    WixImageResource,
    wixImageResourceGetUrl,
    wixImageResourceIsExternalImage,
    wixImageResourceResolveImageWidthHeightRatio,
    WixVideoInfo,
    WixVideoResource
} from "@wix/devzai-common-wix";
import {assertType} from "@wix/devzai-utils-common";

export type WixMediaResource = WixImageResource | WixVideoResource;

export function wixMediaResourceIsWixVideoResource (mediaResource: WixMediaResource) : mediaResource is WixVideoResource {

    if (typeof mediaResource === 'string') {
        return false;
    }

    return (mediaResource as WixVideoInfo).quality !== undefined;
}

export function wixMediaResourceIsWixImageResource (mediaResource: WixMediaResource) : mediaResource is WixImageResource {
    return !wixMediaResourceIsWixVideoResource(mediaResource);
}

export function wixMediaResourceResolveMediaWidthHeightRatio (mediaResource: WixMediaResource) {
    if (wixMediaResourceIsWixImageResource(mediaResource)) {
        return wixImageResourceResolveImageWidthHeightRatio(mediaResource);
    } else {
        return null;
    }
}

export function assertWixMediaResource (wixMediaResource: WixMediaResource) : asserts wixMediaResource is WixMediaResource;
export function assertWixMediaResource (wixMediaResource: unknown) : asserts wixMediaResource is WixMediaResource;
export function assertWixMediaResource (wixMediaResource: unknown) : asserts wixMediaResource is WixMediaResource {
    assertType<WixMediaResource>(wixMediaResource, assert => {
        assert.isUnion(
            assert => assert.usingAssertionFunction(assertWixVideoInfo),
            assert => assert.usingAssertionFunction(assertWixImageResource),
        )
    })
}

export function wixMediaResourceReplaceExternalImage<V extends WixMediaResource> (
    wixMediaResource: V,
    externalImagesUrlToImageResourceMap: Map<string, WixImageResource>
) {
    if (wixMediaResourceIsWixImageResource(wixMediaResource)) {
        if (wixImageResourceIsExternalImage(wixMediaResource)) {
            const targetImageResource = externalImagesUrlToImageResourceMap.get(wixImageResourceGetUrl(wixMediaResource));

            if (targetImageResource) {
                return targetImageResource
            }
        }
    }

    return wixMediaResource;
}