import {DoppeActionType, DoppeActionTypeMetadata} from '../doppe-action-types/doppe-action-type';
import {doppeActionTypeLink} from '../../doppe-action-types/doppe-action-type-link/doppe-action-type-link';
import {
    doppeActionTypeSubscribeMetaData
} from '../../doppe-action-types/doppe-action-type-subscribe/doppe-action-type-subscribe-metadata';
import {doppeActionTypeVideo} from '../../doppe-action-types/doppe-action-type-video/doppe-action-type-video';
import {
    doppeActionTypeSubscribeEmbed
} from '../../doppe-action-types/doppe-action-type-embed/doppe-action-type-subscribe-embed';
import {doppeActionTypePatreon} from '../../doppe-action-types/doppe-action-type-patreon/doppe-action-type-patreon';
import {doppeActionTypeProduct} from '../../doppe-action-types/doppe-action-type-product/doppe-action-type-product';
import {doppeActionTypeProducts} from '../../doppe-action-types/doppe-action-type-products/doppe-action-type-products';
import {
    doppeActionTypeMembership
} from '../../doppe-action-types/doppe-action-type-membership/doppe-action-type-membership';
import {doppeActionTypeFollow} from '../../doppe-action-types/doppe-action-type-follow/doppe-action-type-follow';
import {doppeActionTypeService} from '../../doppe-action-types/doppe-action-type-service/doppe-action-type-service';
import {
    doppeActionTypeBlogPost
} from '../../doppe-action-types/doppe-action-type-blog-post/doppe-action-type-blog-post';
import {doppeActionTypeEvent} from '../../doppe-action-types/doppe-action-type-event/doppe-action-type-event';
import {
    doppeActionTypeContactFormMetadata
} from '../../doppe-action-types/doppe-action-type-contact-form/doppe-action-type-contact-form-metadata';
import {
    doppeActionTypeDonateMetadata
} from '../../doppe-action-types/doppe-action-type-donate/doppe-action-type-donate-metadata';
import {doppeActionTypeShare} from '../../doppe-action-types/doppe-action-type-share/doppe-action-type-share';
import {
    doppeActionTypeSpeedDial
} from '../../doppe-action-types/doppe-action-type-speed-dial/doppe-action-type-speed-dial';
import {doppeActionTypeCoupon} from '../../doppe-action-types/doppe-action-type-coupon/doppe-action-type-coupon';
import {
    doppeActionTypeCouponsList
} from '../../doppe-action-types/doppe-action-type-coupons-list/doppe-action-type-coupons-list';
import {
    doppeActionTypeLinksList
} from '../../doppe-action-types/doppe-action-type-links-list/doppe-action-type-links-list';
import {doppeActionTypeOutfit} from '../../doppe-action-types/doppe-action-type-outfit/doppe-action-type-outfit';
import {
    doppeActionTypeArticlesList
} from '../../doppe-action-types/doppe-action-type-articles-list/doppe-action-type-articles-list';
import {
    doppeActionTypeServicesList
} from '../../doppe-action-types/doppe-action-type-services-list/doppe-action-type-services-list';
import {
    doppeActionTypeVideosList
} from '../../doppe-action-types/doppe-action-type-videos-list/doppe-action-type-videos-list';
import {
    doppeActionTypeSocialIcon
} from '../../doppe-action-types/doppe-action-type-social-icon/doppe-action-type-social-icon';
import {
    doppeActionTypeDebugApi
} from '../../doppe-action-types/doppe-action-type-debug-api/doppe-action-type-debug-api';
import {doppeActionTypeSkip} from '../../doppe-action-types/doppe-action-type-skip/doppe-action-type-skip';
import {
    doppeActionTypeEmbedIframe
} from '../../doppe-action-types/doppe-action-type-embed-iframe/doppe-action-type-embed-iframe';
import {
    doppeActionTypeAutoBlogPost
} from '../../doppe-action-types/doppe-action-type-auto-blog-post/doppe-action-type-auto-blog-post';
import {
    doppeActionTypeDevMediaView
} from '../../doppe-action-types/doppe-action-type-dev-media-view/doppe-action-type-dev-media-view';
import {doppeActionTypeEvents} from '../../doppe-action-types/doppe-action-type-events-list/doppe-action-type-events';
import {
    doppeActionTypeMembershipList
} from '../../doppe-action-types/doppe-action-type-membership-list/doppe-action-type-membership-list';
import {
    doppeActionTypeDevModals
} from '../../doppe-action-types/doppe-action-type-dev-modals/doppe-action-type-dev-modals';
import {doppeActionTypeRequests} from '../../doppe-action-types/doppe-action-type-requests/doppe-action-type-requests';
import {doppeActionTypeRequest} from '../../doppe-action-types/doppe-action-type-request/doppe-action-type-request';
import {
    doppeActionTypePaidMessage
} from '../../doppe-action-types/doppe-action-type-paid-message/doppe-action-type-paid-message';
import {doppeActionTypeCalendly} from '../../doppe-action-types/doppe-action-type-calendly/doppe-action-type-calendly';
import {
    doppeActionTypeDevViewerStorybook
} from '../../doppe-action-types/doppe-action-type-dev-viewer-storybook/doppe-action-type-dev-viewer-storybook';
import {doppeActionTypeIframe} from '../../doppe-action-types/doppe-action-type-iframe/doppe-action-type-iframe';
import {
    doppeActionTypeLinkView
} from '../../doppe-action-types/doppe-action-type-link-view/doppe-action-type-link-view';
import {
    doppeActionTypeEmbedPageList
} from '../../doppe-action-types/doppe-action-type-embed-page-list/doppe-action-type-embed-page-list';
import {doppeActionTypeContent} from '../../doppe-action-types/doppe-action-type-content/doppe-action-type-content';
import {doppeActionTypeSpotify} from '../../doppe-action-types/doppe-action-type-spotify/doppe-action-type-spotify';
import {
    doppeActionTypeSoundcloud
} from '../../doppe-action-types/doppe-action-type-soundcloud/doppe-action-type-sound-cloud';
import {
    doppeActionTypeAppleMusic
} from '../../doppe-action-types/doppe-action-type-apple-music/doppe-action-type-apple-music';

export class DoppeActionTypesMetadataStore {

    private registeredActionTypes = new Map<DoppeActionType.Id, DoppeActionTypeMetadata<any>>();
    
    public registerActionTypeMetadata<PROPS extends {} = {}> (actionTypeMetadata: DoppeActionTypeMetadata<PROPS>) {

        if (this.registeredActionTypes.has(actionTypeMetadata.id)) {
            throw new Error(`Action type with id '${actionTypeMetadata.id}' is already registered.`)
        }
        
        this.registeredActionTypes.set(actionTypeMetadata.id, actionTypeMetadata);
    }
    
    public getActionTypeMetadataByActionTypeId (actionTypeId: DoppeActionType.Id) {
        return this.registeredActionTypes.get(actionTypeId);
    }
}

export function doppeActionTypesMetadataStoreCreate() {

    const doppeActionTypesStore = new DoppeActionTypesMetadataStore();

    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeLink);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeLinkView);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeSubscribeMetaData);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeVideo);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeSubscribeEmbed);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypePatreon);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeProduct);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeProducts);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeMembership);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeFollow);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeService);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeBlogPost);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeAutoBlogPost);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeEvent);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeContactFormMetadata);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeDonateMetadata);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeShare);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeSpeedDial);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeCoupon);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeCouponsList);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeLinksList);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeOutfit);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeArticlesList);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeServicesList);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeVideosList);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeEvents);

    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeRequests);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeRequest);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypePaidMessage);

    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeMembershipList);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeCalendly);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeIframe);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeEmbedPageList);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeContent);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeSpotify);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeSoundcloud);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeAppleMusic);


    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeSocialIcon);


    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeDebugApi);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeSkip);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeEmbedIframe);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeDevMediaView);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeDevModals);
    doppeActionTypesStore.registerActionTypeMetadata(doppeActionTypeDevViewerStorybook);

    return doppeActionTypesStore;
}
