import {
    stringIsNotNullableOrWhiteSpace,
    urlRemoveQueryString,
    validateIsHexColor,
    validateIsUrl,
    validateIsUuid
} from '@wix/devzai-utils-common';
import {
    DoppeSubdomainMaxLength,
    DoppeSubdomainMinLength,
    DoppeUserDisplayNameMaxLength,
    DoppeUserDisplayNameMinLength
} from '../constants/doppe-constants';

export function doppeValidateUuid (value: string) {
    return validateIsUuid(value, 4);
}

export function doppeValidatePaletteColor (value: string) {
    return validateIsHexColor(value);
}

export function doppeValidateLinkUrl (value: string) {
    return validateIsUrl(value, {
        protocols: ['http', 'https'],
        require_protocol: true
    })
}

export function doppeValidateReferencedExternalAssetUrl (value: string) {
    return validateIsUrl(value, {
        protocols: ['http', 'https'],
        require_protocol: true
    })
}

export function doppeValidateLinkPath (value: string) {
    return !!value && value !== '';
}

export function doppeValidateSpeedDialCode (value: string) {
    return stringIsNotNullableOrWhiteSpace(value);
}

export function doppeSanitizeSpeedDialCode (value: string) {
    return value.trim();
}

export function doppeValidateUserSubdomain (value: string) {
    const regexp = new RegExp(`^[a-z0-9][a-z0-9-]{${DoppeSubdomainMinLength - 2},${DoppeSubdomainMaxLength - 2}}[a-z0-9]$`);

    return regexp.test(value)
}

export function doppeSanitizeUserSubdomain (value: string) {
    return value.trim().toLowerCase()
}

export function doppeValidateLinkDisplayName (_value: string) {
    return true;
}

export function doppeValidateUserDisplayName (value: string) {
    return value.length >= DoppeUserDisplayNameMinLength && value.length <= DoppeUserDisplayNameMaxLength;
}

export function doppeSanitizeUserDisplayName (value: string) {
    return value.trim();
}

export function doppeValidateBymoPageName (_value: string) {
    return true;
}

export function doppeValidateListDisplayName (_value: string) {
    return true;
}

export function doppeValidateCalednlyCalendarURL(url: string) {
    const pattern = /^https:\/\/calendly\.com\/[^/]+(\/[^/]+)+\/?$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateCalednlyProfileURL(url: string) {
    const profilePattern = /^https:\/\/calendly\.com\/[^/]+\/?$/;
    return profilePattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyPlaylistURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/playlist\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:playlist|embed\/playlist)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyTrackURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/track\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:track|embed\/track)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}


export function doppeValidateSpotifyShowURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/show\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:show|embed\/show)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}


export function doppeValidateSpotifyEpisodeURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/episode\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:episode|embed\/episode)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyArtistURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/artist\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:artist|embed\/artist)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyURL(url: string) {
    const pattern = /^https:\/\/open\.spotify\.com\/([A-Za-z]+)\/([A-Za-z0-9_-]+)(\?.*)?$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSoundcloudURL(url: string) {
    return doppeValidateSoundcloudTrackURL(url) || doppeValidateSoundcloudPlaylistURL(url);
}


export function doppeValidateSoundcloudTrackURL(url: string) {
    const trackPattern = /^https:\/\/soundcloud\.com\/[\w-]+\/[\w-]+$/;
    return trackPattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSoundcloudPlaylistURL(url: string) {
    const playlistPattern = /^https:\/\/soundcloud\.com\/[\w-]+\/sets\/[\w-]+$/;
    return playlistPattern.test(urlRemoveQueryString(url));
}


export function doppeValidateAppleMusicURL(url: string) {
    return url.startsWith("https://music.apple.com/")
}

