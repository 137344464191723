import {Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity} from './doppe-business-entity';
import {DoppeAutoEventType} from './doppe-auto-event-type';

export const DoppeEventsExternalDataSourceType = {
    LatestWixEvents: 'LatestWixEvents'
} as const;

export type DoppeEventsExternalDataSourceType = Values<typeof DoppeEventsExternalDataSourceType>;

export type DoppeEventsExternalDataSource =
    | {
    type: typeof DoppeEventsExternalDataSourceType.LatestWixEvents;
};

export function doppeEventsExternalDataSourceResolveBusinessEntityReferences(
    externalDataSource: DoppeEventsExternalDataSource
) {
    switch (externalDataSource.type) {
        case DoppeEventsExternalDataSourceType.LatestWixEvents: {
            return {businessEntity: DoppeBusinessEntity.WixAutoEventsList, id: DoppeAutoEventType.Latest}
        }
    }
}