import React from 'react';
import {style, classes} from './doppe-page-async-value-renderer.st.css';
import {AsyncValueRenderer, reactCreateMemo} from "@wix/devzai-utils-react";
import {DoppeViewerSpinner} from "../doppe-viewer-spinner/doppe-viewer-spinner";
import {FlexLayout} from "@wix/devzai-common-client";

export const DoppePageAsyncValueRenderer = reactCreateMemo(function DoppePageAsyncValueRenderer<T> (
    props: DoppePageAsyncValueRenderer.Props<T>
) {
    const {
        asyncValue,
        children,

        className,
        ...htmlAttributes
    } = props;

    return (
        <AsyncValueRenderer
            asyncValue={asyncValue}
            renderResolvedState={children}
            renderPendingState={() => {
                return (
                    <div
                        {...htmlAttributes}
                        className={style(classes.root, {asyncState: 'pending'}, className, FlexLayout.centerContent)}
                    >
                        <DoppeViewerSpinner/>
                    </div>
                );
            }}
            renderRejectedState={() => {
                return (
                    <div
                        {...htmlAttributes}
                        className={style(classes.root, {asyncState: 'rejected'}, className)}
                    >
                        error
                    </div>
                );
            }}
        />
    )
});

export namespace DoppePageAsyncValueRenderer {

    export interface Props<T> extends React.HTMLAttributes<any> {
        asyncValue: AsyncValueRenderer.Props<T, unknown>['asyncValue'];
        children: AsyncValueRenderer.Props<T, unknown>['renderResolvedState']
    }
}
