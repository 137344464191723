export type LongNumber = {
    /**
     * The high 32 bits as a signed value.
     */
    high: number;

    /**
     * The low 32 bits as a signed value.
     */
    low: number;

    /**
     * Whether unsigned or not.
     */
    unsigned: boolean;
}

export function numberParseInt (str: string | undefined, defaultValue = 0) : number {
    return str !== undefined ? parseInt(str) : defaultValue;
}

export function numberParseFloat (str: string | undefined, defaultValue = 0) : number {
    return str !== undefined ? parseFloat(str) : defaultValue;
}

export function numberIsBetween (num: number, minValue: number, maxValue: number) {
    return num >= minValue && num <= maxValue;
}

export function numberNormalizeToRange(value: number, minValue: number, maxValue: number) {
    return Math.max(minValue, Math.min(value, maxValue));
}

export function numberIsInteger (value: number) {
    return Math.floor(value) === value;
}

export function numberRandomInRange(from: number, to: number) {
    return Math.floor(Math.random() * (to - from + 1)) + from;
}

export function numberNormalizeToStep(value: number, step: number, baseNumber = 0) {
    return Math.floor(value / step) * step + baseNumber;
}

export function numberNormalizeToRangeWithStep(value: number, minValue: number, maxValue: number, step: number) {
    // return numberNormalizeToStep(numberNormalizeToRange(value, minValue, maxValue), step, minValue)
    return numberNormalizeToRange(numberNormalizeToStep(value, step), minValue, maxValue);
}

export function numberDefaultValueWhenNan (value: number, defaultValue: number) {
    return isNaN(value) ? defaultValue : value
}

const TWO_PWR_16_DBL = 1 << 16;
const TWO_PWR_32_DBL = TWO_PWR_16_DBL * TWO_PWR_16_DBL;

export function numberConvertLongToNumber (long: LongNumber) {
    if (long.unsigned)
        return ((long.high >>> 0) * TWO_PWR_32_DBL) + (long.low >>> 0);
    return long.high * TWO_PWR_32_DBL + (long.low >>> 0);
}

export function numberConvertLongToInteger (long: LongNumber) {
    return long.unsigned ? long.low >>> 0 : long.low;
}

export function numberCreateUnsignedLong (value: number) : LongNumber {
    value >>>= 0;

    return {
        low: value,
        high: 0,
        unsigned: true
    }
}