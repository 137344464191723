import React from 'react';
import {style, classes} from './doppe-viewer-spinner.st.css';
import {DoubleRingSpinner} from "@wix/devzai-utils-react";
import {Values} from "@wix/devzai-utils-common";

export const DoppeViewerSpinnerSkin = {
    Popup: 'popup'
} as const;

export type DoppeViewerSpinnerSkin = Values<typeof DoppeViewerSpinnerSkin>;

export const DoppeViewerSpinnerSize = {
    Custom: 'custom',
    Tiny: 'tiny',
    Small: 'small',
    Medium: 'medium',
    Large: 'large'
} as const;

export type DoppeViewerSpinnerSize = Values<typeof DoppeViewerSpinnerSize>;

export const DoppeViewerSpinner = React.memo(function DoppeViewerSpinner (props: DoppeViewerSpinner.Props) {
    const {
        size = DoppeViewerSpinnerSize.Medium,
        skin = DoppeViewerSpinnerSkin.Popup,

        className,
        ...htmlAttributes
    } = props;

    return (
        <DoubleRingSpinner
            {...htmlAttributes}
            className={style(classes.root, {size: size ?? undefined}, className)}
        />
    );
});

export namespace DoppeViewerSpinner {

    export interface Props extends DoubleRingSpinner.Props {
        skin?: DoppeViewerSpinnerSkin;
        size?: DoppeViewerSpinnerSize;
    }
}
