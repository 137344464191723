import React from 'react';
import {style, classes} from './double-ring-spinner.st.css';

export const DoubleRingSpinner = React.memo(function DoubleRingSpinner (props: DoubleRingSpinner.Props) {
    const {
        className,
        ...htmlAttributes
    } = props;
    
    return (
        <div
            {...htmlAttributes}
            className={style(classes.root, className)}
        >
            <div
                className={style(classes.layersContainer, {})}
            >
                <LayerSvg
                    className={classes.secondaryLayer}
                />
                <LayerSvg
                    className={classes.mainLayer}
                />
            </div>
        </div>
    );
});

export namespace DoubleRingSpinner {

    export interface Props extends React.HTMLAttributes<any> {
    }

    export interface LayerSvgProps {
        className: string;
    }
}

const LayerSvg = React.memo(function LoaderSvg (props: DoubleRingSpinner.LayerSvgProps) {

    const {
        className,
    } = props;

    const size = 30;
    const viewBox = `-${size/2} -${size/2} ${size} ${size}`;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            className={className}
        >
            <path
                d={'M -7.641208279802149 10.517220926874316 A 13 13 0 1 0 7.960204194457795e-16 -13'}
            />
        </svg>
    );
});