import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeVideo} from "./doppe-action-type-video";

export const doppeActionTypeVideoViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeVideo, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeVideoWidget: (await import(
                './doppe-action-type-video-widget/doppe-action-type-video-widget'
            )).DoppeActionTypeVideoWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeVideoWidget
        } = viewerComponents

        return (
            <DoppeActionTypeVideoWidget
                {...renderProps}
            />
        )
    },

    activateCTA: actionContext => {
        actionContext.navigateToUrl(actionContext.action.settings.externalVideoUrl, true);
    }
})