import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {
    convertDoppeWixBookingServiceToServiceSettings,
    DoppeActionTypeService
} from '../doppe-action-type-service/doppe-action-type-service';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    DoppeDtoBookingService,
    doppeHideableValueCreateHidden,
    DoppeListActionSettings
} from '../../client-server-common';
import {
    doppeExternalServiceProviderResolveBusinessEntityReference
} from '../../client-server-common/types/doppe-external-services';
import {
    DoppeServicesExternalDataSource,
    doppeServicesExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-services-external-data-source';


export namespace DoppeActionTypeServicesList {

    export interface ServiceSettings extends DoppeActionTypeService.ActionSettings {
        id: string;
        serviceTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeServicesExternalDataSource> {
        services: ServiceSettings[];
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowCoupon: boolean;
        previewShowPrice: boolean;
        previewServiceButtonText: string;
    }
}


export const doppeActionTypeServicesList = doppeActionTypeDefineMetadata<DoppeActionTypeServicesList.ActionSettings>({
    id: 'io.bymo.action/services-list',
    resolveActionSettings: action => {

        const {
            services = [],
            ...restSettings
        } = action.settings;

        return {
            services: services.map(service => {
                return {
                    serviceProviderId: null,
                    serviceProvider: null,
                    ribbon: doppeHideableValueCreateHidden('SALE'),
                    ...service as PartiallyOptional<DoppeActionTypeServicesList.ServiceSettings, 'serviceProvider' | 'serviceProviderId' | 'ribbon'>
                }
            }),
            layout: DoppeActionLayout.Column,
            showButton: false,
            buttonText: 'Visit My Website',
            buttonUrl: 'https://www.website.com',
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
            externalDataSource: null,
            externalDataSourceItemsCount: 4,
            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewServiceButtonText: 'Book Now',
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.services.map(service => {
            return service.serviceImages ?? []
        }));
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeServicesExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.services, (plan, skip) => {
                return plan.serviceProvider !== null && plan.serviceProviderId !== null ?
                    doppeExternalServiceProviderResolveBusinessEntityReference(
                        plan.serviceProvider,
                        plan.serviceProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : actionSettings.services.length;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const service of actionSettings.services) {
                if (service.serviceImages) {
                    service.serviceImages = service.serviceImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
});

export function convertDoppeWixServiceToServicesSettings(
    service: DoppeDtoBookingService,
    settings?: {
        serviceButtonText?: string,
    }
): DoppeActionTypeServicesList.ServiceSettings {
    return {
        ...convertDoppeWixBookingServiceToServiceSettings(service),
        id: service.id,
        couponCode: doppeHideableValueCreateHidden('CouponCode'),
        ribbon: doppeHideableValueCreateHidden('SALE'),
        serviceButtonText: settings?.serviceButtonText ?? 'Book Now',
    }
}