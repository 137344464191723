import {DoppeHideableValue, doppeHideableValueCreateHidden} from './doppe-hideable-value';

export interface DoppeCheckoutActionSettings {
    showEmailInput: boolean;
    showAddressInput: boolean;
    showPhoneInput: boolean;
    sendReceipt: boolean;
    withEmailNotification: boolean;
    emailConfirmationMessage: DoppeHideableValue<string>;
    customConfirmationMail: DoppeHideableValue<string>;
    confirmationPhone: DoppeHideableValue<string>;
    showConsent: boolean;
    showConsentCheckbox: boolean;
    customConsentText: DoppeHideableValue<string>;
    fulfillmentDetails: DoppeHideableValue<string>;
}

export function getDefaultDoppeCheckoutActionSettings() {
    return {
        showEmailInput: true,
        showAddressInput: false,
        showPhoneInput: true,

        sendReceipt: true,
        withEmailNotification: true,
        customConfirmationMail: doppeHideableValueCreateHidden(''),
        confirmationPhone: doppeHideableValueCreateHidden(''),
        emailConfirmationMessage: doppeHideableValueCreateHidden(''),
        showConsentCheckbox: false,
        showConsent: true,
        customConsentText: doppeHideableValueCreateHidden(''),
        fulfillmentDetails: doppeHideableValueCreateHidden(''),
    }
}
