import {
    arrayRemoveNullValues,
    ObjectFieldsAssertion,
    objectRemoveEntriesWithUndefinedValues
} from '@wix/devzai-utils-common';
import {WixBlogCoverMedia, WixBlogImage, WixBlogPageUrl, WixImageInfo} from '@wix/devzai-common-wix';


export interface DoppeDtoBlogPost {
    id: string;
    title: string;
    excerpt: string;
    media: WixBlogCoverMedia | null;
    blogPostPageUrl?: string;
    firstPublishedDate: Date | null
}

export namespace DoppeDtoBlogPost {
    export type Id = DoppeDtoBlogPost['id'];

    export type ForUpdate = Pick<DoppeDtoBlogPost, 'id' | 'title'>

    export type ForCreation = Pick<DoppeDtoBlogPost, 'title'>
}


export function doppeBlogPostResolveUrl(blogPostPageUrl: WixBlogPageUrl) {
    if (blogPostPageUrl?.base && blogPostPageUrl?.path) {
        return `${blogPostPageUrl.base}${blogPostPageUrl.path}`;
    }

    return undefined;
}

export const DoppeDtoBlogPostForCreationFieldsAssertions: ObjectFieldsAssertion<DoppeDtoBlogPost.ForCreation> = {
    title: assert => assert.isString(),
}

export const DoppeDtoBlogPostForUpdateFieldsAssertions: ObjectFieldsAssertion<DoppeDtoBlogPost.ForUpdate> = {
    id: assert => assert.isString(),
    title: assert => assert.isString(),
}

export function convertWixBlogCommonImageToWixImageInfo(
    wixBlogCommonImage: WixBlogImage
): WixImageInfo {
    return objectRemoveEntriesWithUndefinedValues({
        url: wixBlogCommonImage.url,
        title: wixBlogCommonImage.altText ?? undefined,
        width: wixBlogCommonImage.width,
        height: wixBlogCommonImage.height,
    })
}

export function doppeDtoBlogPostGetMedia (blogPost: DoppeDtoBlogPost) {
    return arrayRemoveNullValues([
        blogPost.media?.image ? convertWixBlogCommonImageToWixImageInfo(blogPost.media.image) : null,
        blogPost.media?.video?.thumbnail ? convertWixBlogCommonImageToWixImageInfo(blogPost.media.video.thumbnail) : null
    ])
}
