import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {
    arrayRemoveNullValues,
    asArray,
    stringTrimPrefix,
    urlGetHostname,
    urlNormalizeHttpUrl,
    Values
} from '@wix/devzai-utils-common';
import {DoppeActionTypeMembershipViewerLang} from './doppe-action-type-membership-viewer.lang';
import {
    DoppeActionPriceData,
    DoppeExternalMembershipPlanProvider,
    doppeExternalMembershipPlanProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {
    DoppeDtoMembershipPlan,
    doppeDtoMembershipPlanPaymentConvertToActionFormattedPrice
} from '../../client-server-common/types/doppe-dto-membership-plan';
import MembershipType = DoppeActionTypeMembership.MembershipType;


export namespace DoppeActionTypeMembership {
    export interface ActionSettings {
        membershipPageLink: string;
        membershipImages: WixMediaResource[] | null;
        membershipTitle: DoppeHideableValue<string>;
        membershipDescription: DoppeHideableValue<string>;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        membershipBenefits: string[];
        linkButtonText: DoppeHideableValue<string>;
        membershipType: MembershipType;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
        membershipProvider: DoppeExternalMembershipPlanProvider | null;
        membershipProviderId: string | null;
    }

    export const MembershipType = {
        Other: 'Other',
        Patreon: 'Patreon',
        Deviantart: 'Deviantart',
        Youtube: 'Youtube',
        Twitter: 'Twitter',
        Twitch: 'Twitch',
        Memberful: 'Memberful',
    } as const;

    export type MembershipType = Values<typeof MembershipType>;

}

export const doppeActionTypeMembership = doppeActionTypeDefineMetadata<DoppeActionTypeMembership.ActionSettings>({
    id: 'io.bymo.action/membership',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        membershipPageLink: '',
        priceData: {
            actualPrice: DoppeActionTypeMembershipViewerLang.defaultTexts.price,
        },
        membershipBenefits: [DoppeActionTypeMembershipViewerLang.defaultTexts.membershipBenefit],
        membershipType: DoppeActionTypeMembership.MembershipType.Other,
        linkButtonText: DoppeActionTypeMembershipViewerLang.defaultTexts.linkButtonText,
        couponCode: doppeHideableValueCreateHidden('CouponCode'),
        membershipProvider: null,
        membershipProviderId: null,
        membershipImages: null,
        membershipTitle: doppeHideableValueCreateHidden(''),
        membershipDescription: doppeHideableValueCreateHidden(''),
        ribbon: doppeHideableValueCreateHidden(''),
    }),
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.membershipProvider !== null && actionSettings.membershipProviderId !== null ?
                doppeExternalMembershipPlanProviderResolveBusinessEntityReference(
                    actionSettings.membershipProvider,
                    actionSettings.membershipProviderId
                ) :
                null
        ])
    },
});


export function doppeActionTypeMembershipResolveTypeFromUrl(url: string) {

    const hostname = stringTrimPrefix(urlGetHostname(urlNormalizeHttpUrl(url)), 'www.')

    switch (hostname) {
        case 'patreon.com':
            return DoppeActionTypeMembership.MembershipType.Patreon;
        case 'twitch.tv':
            return DoppeActionTypeMembership.MembershipType.Twitch;
        case 'twitter.com':
            return DoppeActionTypeMembership.MembershipType.Twitter;
        case 'youtube.com':
            return DoppeActionTypeMembership.MembershipType.Youtube;
        case 'deviantart.com':
            return DoppeActionTypeMembership.MembershipType.Deviantart;
        default:
            return DoppeActionTypeMembership.MembershipType.Other;
    }

}

export function doppeActionTypeMembershipPlanResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeMembership.ActionSettings,
    _doppeDtoMembershipPlan: DoppeDtoMembershipPlan,
): DoppeActionTypeMembership.ActionSettings {

    // const location = arrayFirst(doppeDtoService.locations);

    return {
        ...actionSettings,
        // membershipPageLink: stringIsNotNullableOrWhiteSpace(_doppeDtoMembershipPlan.membershipPageUrl) ?
        //     doppeDtoService.membershipPageUrl : 'about:blank',
    }
}

export function convertDoppeWixMembershipPlanToPlanSettings(plan: DoppeDtoMembershipPlan, websiteUrl?: string): DoppeActionTypeMembership.ActionSettings {

    return {
        membershipImages: plan.media ? asArray(plan.media) : null,
        membershipPageLink: websiteUrl ?? '',
        priceData: plan.pricing ? {
            actualPrice: doppeDtoMembershipPlanPaymentConvertToActionFormattedPrice(plan.pricing),
            discountPrice: ''
        } : doppeHideableValueCreateHidden({
            actualPrice: '$25',
            discountPrice: ''
        }),
        membershipTitle: plan?.name ?? 'Membership name',
        membershipDescription: plan.description ?? doppeHideableValueCreateHidden(''),
        membershipProvider: DoppeExternalMembershipPlanProvider.Wix,
        membershipProviderId: plan.id,
        linkButtonText: 'Subscribe Now',
        membershipType: DoppeActionTypeMembership.MembershipType.Other,
        membershipBenefits: plan.perks ? plan.perks : [],
        ribbon: doppeHideableValueCreateHidden('SALE'),
        couponCode: doppeHideableValueCreateHidden('CouponCode'),
    }
}

export function doppeActionTypeMembershipResolveDefaultSettingByType(membershipType: MembershipType) {
    switch (membershipType) {
        case DoppeActionTypeMembership.MembershipType.Deviantart:
            return {
                title: 'Support me on DeviantArt',
                membershipBenefits: ['Monthly Sketch Dumps & W.I.P. posts', 'Plush & Product Previews', 'Support my work 🙏'],
                membershipPageLink: 'https://www.deviantart.com/username'
            }
        case DoppeActionTypeMembership.MembershipType.Patreon:
            return {
                title: 'Support me on Patreon',
                membershipBenefits: ['Ad-free content', 'Exclusive Content', 'Behind-the-scenes content', 'Live chat'],
                membershipPageLink: 'https://www.patreon.com/username'
            }
        case DoppeActionTypeMembership.MembershipType.Youtube:
            return {
                title: 'Support me on Youtube',
                membershipBenefits: ['Ad-free content', 'Exclusive Content', 'Behind-the-scenes content', 'Live chat'],
                membershipPageLink: 'https://www.youtube.com/username'
            }
        case DoppeActionTypeMembership.MembershipType.Twitter:
            return {
                title: 'Super Follow me on Twitter',
                membershipBenefits: ['Ad-free content', 'Exclusive Content', 'Behind-the-scenes content', 'Live chat'],
                membershipPageLink: 'https://www.twitter.com/username',
                linkButtonText: 'Super Follow'
            }
        case DoppeActionTypeMembership.MembershipType.Twitch:
            return {
                title: 'Support me on Twitch',
                membershipBenefits: ['Ad-free content', 'Channel Points 1.2x', 'Sub-Only Chat', 'Subscriber Streams'],
                membershipPageLink: 'https://www.twitch.tv/username',
                linkButtonText: 'Subscribe'
            }
        case DoppeActionTypeMembership.MembershipType.Memberful:
            return {
                title: 'My Membership Benefits',
                membershipBenefits: ['Ad-free content', 'Exclusive Content', 'Behind-the-scenes content', 'Live chat'],
                membershipPageLink: 'https://www.your-memberful-page-url.com',
                linkButtonText: 'Become a Member'
            }
        case DoppeActionTypeMembership.MembershipType.Other:
            return {
                title: 'Support my Membership Page',
                membershipBenefits: ['Ad-free content', 'Exclusive Content', 'Behind-the-scenes content', 'Live chat'],
                membershipPageLink: 'https://www.your-memebership-page-url.com'
            }
    }
}