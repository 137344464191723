import {DoppeDtoBymoLinkViewerData} from "../types/doppe-dto-bymo-link-viewer-data";

interface DoppeViewerPageData<PAGE_TYPE extends string> {
    pageType: PAGE_TYPE;
}

export type DoppeViewerPagesData =
    | DoppeViewerPageData<'Home'>
    | DoppeViewerPageData<'Bypass'> & {
        bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithTargetUrl;
    }
    | DoppeViewerPageData<'Blocked'> & {
        bymoLinkViewerData: DoppeDtoBymoLinkViewerData;
    }
    | DoppeViewerPageData<'BymoPage'> & {
        bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithConnectedPage;
    }
    | DoppeViewerPageData<'InAppBymoPage'> & {
        bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithConnectedPage;
    }
;

export type DoppeViewerPageTypes = DoppeViewerPagesData['pageType'];

export const DoppeViewerPageTypes: {[page in DoppeViewerPageTypes]: page} = new Proxy({}, {
    get: (_obj: any, propName: string) => propName
})
