import {
    assertNotNullable,
    asyncCachedValueCreate, asyncWithFallback,
    InitializableValue,
    RestfulApiHttpClient,
    restfulApiHttpClientCreate,
    urlModifyQueryParams
} from '@wix/devzai-utils-common';
import React, {useContext} from 'react';
import {BymoTemplatesStore} from "../../client-server-common/bymo-templates-store/bymo-templates-store";
import {
    DoppeActionTypesStore,
    doppeActionTypesStoreCreate
} from "../common-client-services/doppe-action-types-store/doppe-action-types-store";
import {DoppeViewerAppAnalyticsClient} from "../doppe-viewer-app-client-services/doppe-viewer-app-analytics-client";
import {IDoppeViewerApi} from "../../client-server-common/api/doppe-viewer-api";
import {DoppeUserAppData} from "../app-data/doppe-user-app-data";
import {DoppeViewerAppUiService} from "../doppe-viewer-app-client-services/doppe-viewer-app-ui-service";
import {DoppeViewerAppBymoService} from "../doppe-viewer-app-client-services/doppe-viewer-app-bymo-service";
import {DoppeViewerAppStripeService} from "../doppe-viewer-app-client-services/doppe-viewer-app-stripe-service";
import {DoppeViewerClientData} from "../doppe-viewer-app-client-services/doppe-viewer-client-data";
import {
    DoppeClientErrorsReportingService
} from "../common-client-services/doppe-client-errors-reporting-service/doppe-client-errors-reporting-service";
import {doppeClientHttpClientCreate} from "../../doppe-client-http-client/doppe-client-http-client";
import {WixAppsIds, WixDynamicModelV2, wixDynamicModelV2ResolveSignedInstance} from "@wix/devzai-common-wix";
import {DoppeViewerAppCookieConsentService} from "../doppe-viewer-app-client-services/doppe-viewer-app-cookie-consent-service";

export class DoppeViewerClientServices {

    private apiClient;
    public readonly bymoTemplatesStore;
    public readonly actionTypesStore;
    public readonly viewerAnalyticsClient;
    public readonly uiService;
    public readonly bymoService;
    public readonly stripeService;
    public readonly doppeViewerClientData;
    public readonly doppeClientErrorsReportingService;
    public readonly doppeViewerAppCookieConsentService;
    public readonly doppeUserAppData;

    constructor (
        services: {
            doppeUserAppData: DoppeUserAppData;
            apiClient: RestfulApiHttpClient<IDoppeViewerApi>;
            bymoTemplatesStore: BymoTemplatesStore;
            doppeActionTypesStore: DoppeActionTypesStore;
            doppeViewerAppAnalyticsClient: DoppeViewerAppAnalyticsClient;
            doppeViewerAppCookieConsentService: DoppeViewerAppCookieConsentService;
            uiService: DoppeViewerAppUiService;
            bymoService: DoppeViewerAppBymoService;
            stripeService: DoppeViewerAppStripeService;
            doppeViewerClientData: DoppeViewerClientData;
            doppeClientErrorsReportingService: DoppeClientErrorsReportingService;
        }
    ) {
        this.apiClient = services.apiClient;
        this.bymoTemplatesStore = services.bymoTemplatesStore;
        this.actionTypesStore = services.doppeActionTypesStore;
        this.viewerAnalyticsClient = services.doppeViewerAppAnalyticsClient;
        this.doppeUserAppData = services.doppeUserAppData;
        this.uiService = services.uiService;
        this.bymoService = services.bymoService;
        this.stripeService = services.stripeService;
        this.doppeViewerClientData = services.doppeViewerClientData;
        this.doppeClientErrorsReportingService = services.doppeClientErrorsReportingService;
        this.doppeViewerAppCookieConsentService = services.doppeViewerAppCookieConsentService;
    }

    public getApiClient () {
        return this.apiClient;
    }
}

export const DoppeViewerClientServicesReactContext = React.createContext<DoppeViewerClientServices | null>(null);

export function useDoppeViewerClientServices () {
    return assertNotNullable(useContext(DoppeViewerClientServicesReactContext));
}

export function doppeViewerClientServicesInitialize (
    options: Partial<ConstructorParameters<typeof DoppeViewerClientServices>[0]> = {}
) {

    const doppeUserAppData = options.doppeUserAppData ?? new DoppeUserAppData();

    const deferredDoppeViewerAppClientServices = new InitializableValue<DoppeViewerClientServices>()

    const asyncCachedSignedInstance = asyncCachedValueCreate(async () => {

        if (doppeUserAppData.dynamicModelApiUrl) {
            const httpClient = await doppeClientHttpClientCreate();

            return wixDynamicModelV2ResolveSignedInstance(
                (await httpClient.get<WixDynamicModelV2>(doppeUserAppData.dynamicModelApiUrl)).data,
                WixAppsIds.MetaSite
            );
        } else {
            return undefined;
        }
    })

    const clientServices = new DoppeViewerClientServices({
        doppeUserAppData: doppeUserAppData,
        doppeViewerClientData: options.doppeViewerClientData ?? new DoppeViewerClientData(),
        apiClient: options.apiClient ?? restfulApiHttpClientCreate(
            async () => {

                const {DoppeViewerApiRoutesManager} = await import(/* webpackChunkName: "doppe-viewer-api-routes-manager" */ '../../client-server-common/api/doppe-viewer-api-routes-manager');

                return new DoppeViewerApiRoutesManager({
                    basePath: doppeUserAppData.viewerApiBasePath
                })
            },
            {
                httpClient: doppeClientHttpClientCreate,
                baseUrl: doppeUserAppData.apiBaseUrl,
                withCredentials: false,
                processEndpointUrl: async url => {
                    const signedInstance = await asyncWithFallback(asyncCachedSignedInstance.getValue(), undefined);

                    if (signedInstance) {
                        return urlModifyQueryParams(url, queryParams => ({
                            ...queryParams,
                            'instance': signedInstance
                        }));
                    } else {
                        return url;
                    }
                }
            }),
        uiService: new DoppeViewerAppUiService(deferredDoppeViewerAppClientServices),
        bymoTemplatesStore: options.bymoTemplatesStore ?? new BymoTemplatesStore(),
        doppeActionTypesStore: options.doppeActionTypesStore ?? doppeActionTypesStoreCreate(),
        doppeViewerAppAnalyticsClient: options.doppeViewerAppAnalyticsClient ?? new DoppeViewerAppAnalyticsClient(deferredDoppeViewerAppClientServices),
        bymoService: options.bymoService ?? new DoppeViewerAppBymoService(deferredDoppeViewerAppClientServices),
        stripeService: options.stripeService ?? new DoppeViewerAppStripeService(deferredDoppeViewerAppClientServices),
        doppeClientErrorsReportingService: options.doppeClientErrorsReportingService ?? new DoppeClientErrorsReportingService(),
        doppeViewerAppCookieConsentService: options.doppeViewerAppCookieConsentService ?? new DoppeViewerAppCookieConsentService(deferredDoppeViewerAppClientServices)
    })

    deferredDoppeViewerAppClientServices.initialize(clientServices);

    clientServices.doppeClientErrorsReportingService.initialize({
        environment: doppeUserAppData.appRuntimeEnvironment,
        appType: 'DoppeAppEditor'
    })
        .catch(error => {
            console.error(`Failed initializing doppeClientErrorsReportingService`, error)
        })

    return clientServices;
}
