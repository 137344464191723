export type DateTimeValue = Date | string | number;
export type SerializedDateTimeValue = string | number;

export function dateAddDays (date: Date, numberOfDays: number) {
    const result = new Date(date);

    result.setDate(date.getDate() + numberOfDays);

    return result;
}

export function dateGetCurrentDateTime () {
    return new Date(Date.now());
}

export function dateGetCurrentUnixTimeSeconds(): number {
    return Math.floor(Date.now() / 1000);
}

export function dateGetCurrentDate () {
    return new Date(new Date().toISOString().slice(0, 10) + 'T00:00:00.000Z')
}

export function dateResolveFromDateTimeValue (date: DateTimeValue) : Date {
    return typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;
}

export function dateGetUnixTimeMilliseconds (date: DateTimeValue) {
    return (new Date(date)).getTime();
}

export function dateTryParse (dateStr: string) : Date | undefined {
    const result = new Date(dateStr);

    return isNaN(result.getTime()) ? undefined : result;
}

export function dateGetCurrentDateTimeString () {
    return (new Date()).toJSON();
}






