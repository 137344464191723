import React from 'react';
import {style, classes} from './doppe-viewer-horizontal-button.st.css';
import {OmitStrict, Values} from "@wix/devzai-utils-common";
import {HorizontalButton} from "@wix/devzai-utils-react";

export const DoppeViewerHorizontalButtonSize = {
    Medium: 'medium',
    Small: 'small',
} as const;

export type DoppeViewerHorizontalButtonSize = Values<typeof DoppeViewerHorizontalButtonSize>;

export const DoppeViewerHorizontalButton = React.memo(function DoppeViewerHorizontalButton (props: DoppeViewerHorizontalButton.Props) {
    const {
        size = DoppeViewerHorizontalButtonSize.Medium,
        textual = false,

        className,
        ...htmlAttributes
    } = props;

    return (
        <HorizontalButton
            {...htmlAttributes}
            size={size}
            className={style(classes.root, {size: size, textual: textual}, className)}
        />
    );
});

export namespace DoppeViewerHorizontalButton {

    export interface Props extends OmitStrict<HorizontalButton.Props<DoppeViewerHorizontalButtonSize>, 'loader' | 'size'> {
        size?: DoppeViewerHorizontalButtonSize;
        textual?: boolean;
    }
}
