import {assertDefined} from "@wix/devzai-utils-common";
import {BymoTemplateMetadata} from "../../doppe-sdk";
import {BymoTemplatesIds} from "../doppe-templates/doppe-templates";
import {
    BymoTemplateBasicTemplateMetadata
} from "../../components/bymo-templates/bymo-template-basic/bymo-template-basic.metadata";
import {
    BymoTemplatePreRollTemplateMetadata
} from "../../components/bymo-templates/bymo-template-pre-roll/bymo-template-pre-roll.metadata";
import {
    BymoTemplateSpeedDialTemplateMetadata
} from "../../components/bymo-templates/bymo-template-speed-dial/bymo-template-speed-dial.metadata";
import {
    BymoTemplateActionPreviewTemplateMetadata
} from "../../components/bymo-templates/bymo-template-action-preview/bymo-template-action-preview.metadata";

export class DoppeBymoTemplatesMetadataStore {

    private bymoPagesTemplatesMetadata: Record<BymoTemplatesIds, BymoTemplateMetadata<any>> = {
        [BymoTemplatesIds.ActionPreview]: BymoTemplateActionPreviewTemplateMetadata,
        [BymoTemplatesIds.BasicForActionPreview]: BymoTemplateBasicTemplateMetadata,
        [BymoTemplatesIds.Basic]: BymoTemplateSpeedDialTemplateMetadata,
        [BymoTemplatesIds.PreRoll]: BymoTemplatePreRollTemplateMetadata,
        [BymoTemplatesIds.SpeedDial]: BymoTemplateSpeedDialTemplateMetadata,
    }

    public getBymoPageTemplateMetadata (templateId: string) {
        return assertDefined<BymoTemplateMetadata<{}>>(this.bymoPagesTemplatesMetadata[templateId as BymoTemplatesIds], `Could not find a page template with id ${templateId}`)
    }
}