import {Values} from "@wix/devzai-utils-common";

export const DoppeViewerLang = {
    English: 'en',
    Hebrew: 'he'
} as const;

export type DoppeViewerLang = Values<typeof DoppeViewerLang>;

export function doppeViewerLangIsRtl (lang: DoppeViewerLang) {
    switch (lang) {
        case DoppeViewerLang.Hebrew: {
            return true;
        }
        default: {
            return false;
        }
    }
}