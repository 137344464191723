import React, {useLayoutEffect} from 'react';
import {
    asyncValueIsPending,
    asyncValueIsResolved,
    AsyncValueResource,
    AsyncValueState,
    AsyncValueStates
} from '@wix/devzai-utils-common';
import {reactCreateMemo, useObservable} from '../index';

export namespace AsyncValueRenderer {

    export interface Props<T, R> {
        asyncValue: AsyncValueState<T, R> | AsyncValueResource<T>;
        renderResolvedState?: (value: T) => React.ReactElement;
        renderPendingState?: () => React.ReactElement;
        renderRejectedState?: (reason?: R) => React.ReactElement;
        children?: undefined;
    }
}

export const AsyncValueRenderer = reactCreateMemo(function AsyncValueRenderer<T, R> (props: AsyncValueRenderer.Props<T, R>) {
    const {
        asyncValue,
        renderResolvedState,
        renderPendingState,
        renderRejectedState
    } = props;

    useObservable(asyncValue instanceof AsyncValueResource ? asyncValue : undefined);
    
    const asyncValueState = asyncValue instanceof AsyncValueResource ? asyncValue.getValue() : asyncValue;

    useLayoutEffect(() => {
        if (asyncValue instanceof AsyncValueResource && asyncValue.getAsyncValue()?.state === AsyncValueStates.Rejected) {
            setTimeout(() => {
                asyncValue.invalidateValue();
            }, 0);
        }
    }, [asyncValue])

    if (asyncValueIsResolved(asyncValueState)) {
        return renderResolvedState ? renderResolvedState(asyncValueState.value) : null;
    } else if (asyncValueIsPending(asyncValueState)) {
        return renderPendingState ? renderPendingState() : null;
    } else {
        return renderRejectedState ? renderRejectedState(asyncValueState.reason) : null;
    }
});
