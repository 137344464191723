import urlJoinExternal from 'url-join';
import Url from 'url-parse';
import QueryStringify from 'querystringify';
import {
    stringExtractSubstringFromMatchEnd, stringReplaceEmptyOrWhiteSpaceValue,
    stringSplitAtFirstMatch,
    stringTrimPrefix,
    stringTrimSuffix
} from '../string-utils';
import {objectCreateFromEntries, objectMapValues, objectRemoveEntriesWithValue} from '../object-utils';
import {MatchingKeys, OmitByValueExact} from '../common-types';
import {Evaluable, evaluateWhenFunction} from '../evaluable';
import {iterableAny, iterableMapToArray} from "../iterable-utils/iterable-utils";

export function urlJoin(...parts: string[]) : string;
export function urlJoin(parts: string[]) : string;
export function urlJoin(...args: any[]) : string {
    return urlJoinExternal(...args);
}

export function urlNormalizeUtmParam (utmParam: string) {
    return utmParam.trim() === '' ? '' : utmParam.replace(/\s/g, '+')
}

export function urlNormalizeHttpUrl (url: string, defaultProtocol = 'https') : string {
    const trimmedUrl = stringTrimPrefix(stringTrimPrefix(url.trim(), ':'), '//');

    if (trimmedUrl === '') {
        return trimmedUrl;
    }

    const parsedUrl = urlParse(trimmedUrl);

    const protocol = parsedUrl.protocol;

    if (protocol === '') {
        return urlNormalizeHttpUrl(`${defaultProtocol}://${trimmedUrl}`)
    } else {
        const hostname = parsedUrl.hostname;

        const normalizedQuery = parsedUrl.query as unknown as string === '?' ? '' : parsedUrl.query;
        const normalizedHash = parsedUrl.hash === '#' ? '' : parsedUrl.hash;

        return `${protocol !== 'http:' && protocol !== 'https:' ? `${defaultProtocol}:` : protocol}//${hostname}${urlGetPathname(trimmedUrl)}${normalizedQuery}${normalizedHash}`;
    }
}

export function urlNormalize (url: string, defaultProtocol = 'https') : string {

    const trimmedUrl = stringTrimPrefix(stringTrimPrefix(url.trim(), ':'), '//');

    const parsedUrl = urlParse(trimmedUrl);

    const protocol = parsedUrl.protocol;

    if (protocol === '') {
        return urlNormalize(`${defaultProtocol}://${trimmedUrl}`)
    } else {
        const hostname = parsedUrl.hostname;

        return `${protocol}//${hostname}${urlGetPathname(trimmedUrl)}`;
    }
}

export function urlIsAbsolute (url: string) {
    return url.startsWith('blob:') || /^(?:[a-z]+:)?\/\//i.test(url);
}

export function urlPathJoin (...parts: string[]) : string;
export function urlPathJoin (parts: string[]) : string;
export function urlPathJoin (...args: any[]) : string {

    const parts = args.length === 1 && typeof args[0] !== 'string' ?
        args[0] :
        args;

    return parts.join('/').replace(/\/{1,}/g, '/');
}

export function urlTrimProtocol (url: string) {
    return stringExtractSubstringFromMatchEnd(stringTrimPrefix(url, '//'), '://')
}

export function urlParse (url: string) : ParsedUrl {

    if (url.startsWith('//')) {
        const parsedUrl = new Url(`http:${url}`, {});
        parsedUrl.set('protocol', '');
        return parsedUrl;
    } else {
        return new Url(url, {});
    }
}

export function urlGetPathname (url: string) : string {
    return urlParse(url).pathname;
}

export function urlGetBasename (url: string) : string {
    const pathname = urlGetPathname(url);

    return pathname.substring(pathname.lastIndexOf('/'))
}

export function urlGetHostname (url: string) : string {
    return urlParse(url).hostname;
}

export function urlHostnameInSecondLevelDomain (hostname: string, sld: string) {
    return hostname === sld || hostname.endsWith(`.${stringTrimPrefix(sld, '.')}`);
}

export function urlGetProtocol (url: string) : string {
    return urlParse(url).protocol;
}

export function urlGetBaseUrl (url: string) {
    return `${urlGetProtocol(url)}//${urlGetHostname(url)}`;
}

export function urlIsValidHostname (hostname: string) {
    return /^([\p{L}\d-]+\.)+\p{L}+$/u.test(hostname);
}

export function urlIsValidAbsoluteUrl (url: string, allowedProtocols: string[]) {

    if (iterableAny(allowedProtocols, protocol => url.startsWith(`${protocol}://`))) {
        const parsedUrl = urlParse(url);

        return urlIsValidHostname(parsedUrl.hostname);
    }

    return false
}

export function urlIsHttpOrHttps (url: string) : boolean {
    return urlIsHttp(url) || urlIsHttps(url);
}

export function urlIsHttp (url: string) : boolean {
    return url.startsWith('http://');
}

export function urlIsHttps (url: string) : boolean {
    return url.startsWith('https://');
}

export function urlStringifyParsedUrl (parsedUrl: ParsedUrl, options: {
    includePathname?: boolean
} = {}) {

    const {
        includePathname = true
    } = options;

    const protocol = parsedUrl.protocol;

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return (protocol ? protocol + '//' : (parsedUrl.href.startsWith('//') ? '//' : '')) + parsedUrl.host + (includePathname ? parsedUrl.pathname : '');
}

export function urlModify (
    url: string,
    modification: Evaluable<(parsedUrl: ParsedUrl) => Partial<ParsedUrl>>
) : string {
    const parsedUrl = urlParse(url);

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return urlStringifyParsedUrl({
        ...parsedUrl,
        ...evaluateWhenFunction(modification, parsedUrl)
    }) + parsedUrl.query
}

export function urlRemoveTrailingSlash (url: string) {
    const parsedUrl = urlParse(url);
    const pathname = parsedUrl.pathname;

    if (pathname !== '/' && pathname.endsWith('/')) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return urlStringifyParsedUrl({
            ...parsedUrl,
            pathname: stringTrimSuffix(pathname, '/')
        }) + parsedUrl.query
    } else {
        return url;
    }
}

export function urlAddMissingTrailingSlash (url: string) {
    const parsedUrl = urlParse(url);
    const pathname = parsedUrl.pathname;

    if (pathname !== '/' && !pathname.endsWith('/')) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return urlStringifyParsedUrl({
            ...parsedUrl,
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            pathname: pathname + '/'
        }) + parsedUrl.query
    } else {
        return url;
    }
}

export function urlGetQueryString (url: string, trimPrefix = false) {

    const queryStr = urlParse(url).query as unknown as string;

    if (trimPrefix) {
        return stringTrimPrefix(queryStr, '?');
    } else {
        return queryStr === '?' ? '' : queryStr;
    }
}

type QueryParamsParsingRules<
    T extends Record<string, unknown>,
    STRING_KEYS extends MatchingKeys<T, string> = MatchingKeys<T, string>,
    NON_STRING_PARAMS extends Omit<T, STRING_KEYS> = Omit<T, STRING_KEYS>,
    STRING_PARAMS extends Pick<T, STRING_KEYS> = Pick<T, STRING_KEYS>,
> = {
    [key in keyof NON_STRING_PARAMS]: (value: string) => NON_STRING_PARAMS[key]
} & {
    [key in keyof STRING_PARAMS]?: (value: string) => STRING_PARAMS[key]
};

export function urlParseQueryString<T extends Record<string, unknown>> (
    queryString: string,
    parser: QueryParamsParsingRules<T>
) : Partial<T>;
export function urlParseQueryString (
    queryString: string
) : Record<string, string>;
export function urlParseQueryString<T extends Record<string, unknown>> (
    queryString: string,
    parser?: QueryParamsParsingRules<T>
) : Partial<T> {

    const parsedQuery = QueryStringify.parse(queryString) as {
        [key in keyof T]?: string;
    };

    if (!parser) {
        return parsedQuery as T;
    } else {
        return objectMapValues(parsedQuery, (value, key) => {
            const parsingFunc = (parser as any)[key];

            return parsingFunc !== undefined ?
                parsingFunc(value) :
                value
        }) as T;
    }
}

export function urlRemoveQueryString (url: string) {
    return urlStringifyParsedUrl(urlParse(url));
}

export function urlAddQueryParams<T extends Record<string, unknown>> (url: string, queryParams: T) {
    const parsedUrl = urlParse(url);

    const parsedQueryString = urlParseQueryString(parsedUrl.query as unknown as string)

    return urlStringifyParsedUrl(parsedUrl) + urlCreateQueryString({
        ...parsedQueryString,
        ...queryParams
    }, true)
}

export type UrlQueryParamsSpec<K extends string = string> = Record<K, string | null>;

export function urlCreateNormalizedQueryParamsSpec<
    T extends Record<string, unknown>,
    M = OmitByValueExact<OmitByValueExact<T, boolean>, string>,
> (
    obj: T,
    valuesMapping: {
        [key in keyof M]: (value: M[key]) => string | null
    }
) : UrlQueryParamsSpec<keyof T & string> {

    return objectMapValues(obj, (value, key) => {
        const converter = valuesMapping[key as keyof M] as any;

        if (converter) {
            return converter(value);
        } else {
            if (typeof value === 'boolean') {
                return value ? 'true' : null
            } else if (typeof value === 'string') {
                return stringReplaceEmptyOrWhiteSpaceValue(value, null);
            } else {
                return null;
            }
        }
    })
}

export function urlModifyQueryParams (
    url: string,
    queryParamsModifier: Evaluable<(queryParams: Record<string, string>) => UrlQueryParamsSpec>,
    encodeQueryParams = false
) {

    const [urlWithoutQueryStr, queryStr] = stringSplitAtFirstMatch(url, '?', true) ?? [url, ''];

    const queryParams = objectCreateFromEntries(iterableMapToArray(
        queryStr.split('&'),
        (entryStr, skip) => stringSplitAtFirstMatch(entryStr, '=', true) ?? skip));

    const encodeQueryParamPart = (part: string) => {
        return encodeQueryParams ?
            encodeURIComponent(part) :
            part
                .replace('&', '%26')
                .replace('?', '%3F')
                .replace('=', '%3D')
    }

    const modifiedQueryStr = iterableMapToArray(Object.entries(evaluateWhenFunction(queryParamsModifier, queryParams)), ([key, value], skip) => {
        return value === null ? skip : `${encodeQueryParamPart(key)}=${encodeQueryParamPart(value)}`
    }).join('&')

    return `${urlWithoutQueryStr}${modifiedQueryStr ? '?' : ''}${modifiedQueryStr}`;
}

export function urlCreateQueryString<T extends Record<string, unknown>> (obj: T, prefix: string | boolean = true) {
    return QueryStringify.stringify(objectRemoveEntriesWithValue(obj as any, undefined), prefix);
}

/**
 * Note: Prefer to use urlModifyQueryParams when you have a URL
 */
export function urlCreateQueryStringFromRecord (
    obj: Record<string, string>,
    options: {
        encodeParams?: boolean;
        addPrefixWhenNotEmpty?: boolean;
    } = {}
) {

    const {
        encodeParams = false,
        addPrefixWhenNotEmpty = true
    } = options;

    const queryString = Object.entries(obj)
        .map(([key, value]) => {
            return `${encodeParams ? encodeURIComponent(key) : key}=${encodeParams ? encodeURIComponent(value) : value}`;
        })
        .join('&');

    return addPrefixWhenNotEmpty && queryString !== '' ? `?${queryString}` : queryString;
}

export type ParsedUrl = import('url-parse');
