import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeCheckoutActionSettings,
    getDefaultDoppeCheckoutActionSettings
} from '../../client-server-common/types/doppe-checkout-action-settings';
import {
    DoppeListActionSettings,
    getDefaultDoppeListActionSettings
} from '../../client-server-common/types/doppe-list-action-settings';
import {DoppeActionPrice} from '../../client-server-common/types/doppe-action-price';
import {arrayFlatten, asArray, immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {DoppeActionTypeRequest} from '../doppe-action-type-request/doppe-action-type-request';


export namespace DoppeActionTypeRequests {

    export interface RequestOption {
        title: string;
        description: DoppeHideableValue<string>;
        priceData: DoppeActionPrice;
        images: WixMediaResource[] | null;
        fulfillmentDetails: DoppeHideableValue<string>;
        requestsInstructions: DoppeHideableValue<string>;
        showNotesInput: boolean;
        id: string;
    }

    export interface ActionSettings extends DoppeListActionSettings, DoppeCheckoutActionSettings {
        requestOptions: DoppeActionTypeRequest.RequestOption[];
        requestsInstructions: DoppeHideableValue<string>;
        previewCheckoutButtonText: string;
        itemShowPrice: boolean;
    }

}


export const doppeActionTypeRequests = doppeActionTypeDefineMetadata<DoppeActionTypeRequests.ActionSettings>({
    id: 'co.hopp.action/request',
    resolveActionSettings: action => {

        const {
            requestOptions = [],
            ...restSettings
        } = action.settings;


        return {
            requestOptions: requestOptions.map(requestOption => {
                return {
                    requestsInstructions: doppeHideableValueCreateHidden(''),
                    ribbon: doppeHideableValueCreateHidden('SALE'),
                    showNotesInput: true,
                    ...requestOption as PartiallyOptional<DoppeActionTypeRequest.RequestOption, 'requestsInstructions' | 'showNotesInput'>
                }
            }),
            requestsInstructions: doppeHideableValueCreateHidden(''),
            ...getDefaultDoppeListActionSettings(),
            ...getDefaultDoppeCheckoutActionSettings(),
            previewCheckoutButtonText: 'Checkout',
            itemShowPrice: true,
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: true,
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(iterableMapToArray(actionSettings.requestOptions, (requestOption, skip) => {
            return requestOption.images ? asArray(requestOption.images) : skip
        }));
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.requestOptions.length;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.requestOptions) {
                if (item.images) {
                    const images = asArray(item.images);
                    item.images = images.map(image => {
                        return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                    });
                }
            }
        })
    },
});
