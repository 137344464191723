import {CurrencyCode, currencyCodes} from "./currency-data";
import {proxyCreateEnumFromValues} from "../proxy-utils/proxy-utils";

export type {CurrencyCode} from './currency-data'

export function currencyGetAllCodes () {
    return currencyCodes;
}

/**
 * ISO4217 currency codes.
 */
export const CurrencyCodes = proxyCreateEnumFromValues<CurrencyCode>();

export function currencyGetSymbolForDisplay (code: CurrencyCode) {

    const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: code,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(0);

    return formatted.replace('0', '');
}

export function currencyFormatPrice (
    options: {
        code: CurrencyCode;
        price: number;
        removeFractionWhenPossible?: boolean
    }
) {
    const {
        code,
        price,
        removeFractionWhenPossible = true
    } = options;

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: code,
        ...(removeFractionWhenPossible && Math.round(price) === price ? {minimumFractionDigits: 0, maximumFractionDigits: 0} : undefined)
    }).format(price)
}

