import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeDtoBlogPost,
    doppeDtoBlogPostGetMedia,
    DoppeExternalBlogPostProvider,
    doppeExternalBlogPostProviderResolveBusinessEntityReference,
    DoppeHideableValue
} from '../../client-server-common';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeActionTypeBlogPostViewerLang} from './doppe-action-type-blog-post-viewer.lang';
import {arrayRemoveNullValues, immerProduce} from '@wix/devzai-utils-common';

export namespace DoppeActionTypeBlogPost {
    export interface ActionSettings {
        blogPostLink: string;
        blogPostTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<string>;
        blogPostButtonText: DoppeHideableValue<string>;
        blogPostImages: WixImageResource[] | null;
        blogPostDate: DoppeHideableValue<Date>;
        showBlogWebsite: boolean;
        blogPostProvider: DoppeExternalBlogPostProvider | null;
        blogPostProviderId: DoppeDtoBlogPost.Id | null;
    }
}

export const doppeActionTypeBlogPost = doppeActionTypeDefineMetadata<DoppeActionTypeBlogPost.ActionSettings>({
    id: 'io.bymo.action/blogPost',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        blogPostLink: '',
        blogPostTitle: DoppeActionTypeBlogPostViewerLang.defaultTexts.blogPostTitle,
        description: DoppeActionTypeBlogPostViewerLang.defaultTexts.description,
        blogPostButtonText: DoppeActionTypeBlogPostViewerLang.defaultTexts.blogPostButtonText,
        blogPostImages: null,
        blogPostDate: new Date(),
        showBlogWebsite: true,
        blogPostProvider: null,
        blogPostProviderId: null,
    }),
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.blogPostImages ?? [];
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.blogPostProvider !== null && actionSettings.blogPostProviderId !== null ?
                doppeExternalBlogPostProviderResolveBusinessEntityReference(
                    actionSettings.blogPostProvider,
                    actionSettings.blogPostProviderId
                ) : null
        ])
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.blogPostImages) {
                actionSettings.blogPostImages = actionSettings.blogPostImages.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },

});

export function convertDoppeWixBlogPostToBlogPostSettings(blogPost: DoppeDtoBlogPost): DoppeActionTypeBlogPost.ActionSettings {

    return {
        blogPostImages: doppeDtoBlogPostGetMedia(blogPost),
        blogPostLink: blogPost.blogPostPageUrl ?? '',
        blogPostTitle: blogPost?.title ?? 'Blog Post name',
        description: blogPost.excerpt,
        blogPostProvider: DoppeExternalBlogPostProvider.Wix,
        blogPostProviderId: blogPost.id,
        blogPostButtonText: 'Read Now',
        blogPostDate: blogPost.firstPublishedDate ?? new Date(Date.now()),
        showBlogWebsite: true,
    }
}