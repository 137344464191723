import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeActionTypeServiceViewerLang} from './doppe-action-type-service-viewer.lang';
import {arrayRemoveNullValues, immerProduce, stringIsNotNullableOrWhiteSpace} from '@wix/devzai-utils-common';
import {
    BymoPageProps,
    DoppeActionPriceData,
    DoppeDtoBookingService,
    doppeDtoBookingServiceCapacityConvertToActionFormattedCapacity,
    doppeDtoBookingServiceLocationConvertToActionFormattedLocation,
    doppeDtoBookingServicePaymentConvertToActionFormattedPrice,
    doppeDtoBookingServiceScheduleConvertToActionFormattedDuration,
    doppeDtoBookingServiceScheduleConvertToActionFormattedSchedule,
    doppeExternalServiceProviderResolveBusinessEntityReference,
    DoppeExternalServiceProvider
} from '../../client-server-common';
import {BymoPageResource} from "../../client/resources/bymo-page-resource/bymo-page-resource";

export namespace DoppeActionTypeService {
    export interface ActionSettings {
        servicePageLink: string;
        serviceTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<string>;
        serviceButtonText: string;
        serviceImages: WixImageResource[] | null;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        servicePeriod: DoppeHideableValue<string>;
        serviceSchedule: DoppeHideableValue<string>;
        serviceParticipants: DoppeHideableValue<string>;
        location: DoppeHideableValue<string>;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
        serviceProvider: DoppeExternalServiceProvider | null;
        serviceProviderId: string | null;
    }
}

export const doppeActionTypeService = doppeActionTypeDefineMetadata<DoppeActionTypeService.ActionSettings>({
    id: 'io.bymo.action/service',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        servicePageLink: '',
        priceData: {
            actualPrice: DoppeActionTypeServiceViewerLang.defaultTexts.price,
        },
        serviceTitle: DoppeActionTypeServiceViewerLang.defaultTexts.serviceTitle,
        description: DoppeActionTypeServiceViewerLang.defaultTexts.description,
        serviceButtonText: DoppeActionTypeServiceViewerLang.defaultTexts.serviceButtonText,
        serviceImages: null,
        location: '',
        servicePeriod: '',
        serviceSchedule: doppeHideableValueCreateHidden(''),
        serviceParticipants: doppeHideableValueCreateHidden(''),
        couponCode: doppeHideableValueCreateHidden('CouponCode'),
        ribbon: doppeHideableValueCreateHidden('SALE'),
        serviceProvider: null,
        serviceProviderId: null,
    }),
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.serviceImages ?? [];
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.serviceProvider !== null && actionSettings.serviceProviderId !== null ?
                doppeExternalServiceProviderResolveBusinessEntityReference(
                    actionSettings.serviceProvider,
                    actionSettings.serviceProviderId
                ) :
                null
        ])
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.serviceImages) {
                actionSettings.serviceImages = actionSettings.serviceImages.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    }
});

export function doppeActionTypeServiceResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeService.ActionSettings,
    doppeDtoService: DoppeDtoBookingService,
    _bymoPageProps: BymoPageProps,
): DoppeActionTypeService.ActionSettings {

    // const location = arrayFirst(doppeDtoService.locations);

    return {
        ...actionSettings,
        // serviceSchedule: doppeDtoService.scheduale ? doppeHideableValueModifyValue(actionSettings.serviceSchedule, doppeDtoBookingServiceScheduleConvertToActionFormattedSchedule({
        //     serviceType: doppeDtoService.type,
        //     schedule: doppeDtoService.scheduale,
        //     bymoPageProps: bymoPageProps,
        // }) ?? '') : actionSettings.serviceSchedule,
        // servicePeriod: doppeDtoService.scheduale ? doppeHideableValueModifyValue(actionSettings.servicePeriod, doppeDtoBookingServiceScheduleConvertToActionFormattedDuration({
        //     serviceType: doppeDtoService.type,
        //     schedule: doppeDtoService.scheduale
        // })?.[0] ?? '') : actionSettings.servicePeriod,
        // serviceParticipants: doppeDtoService.capacity ? doppeHideableValueModifyValue(actionSettings.serviceParticipants, doppeDtoBookingServiceCapacityConvertToActionFormattedCapacity(doppeDtoService.capacity)) : actionSettings.serviceParticipants,
        // location: location ? doppeHideableValueModifyValue(actionSettings.location, doppeDtoBookingServiceLocationConvertToActionFormattedLocation(location)) : actionSettings.location,
        // priceData: doppeDtoService.payment ? doppeHideableValueModifyValue(actionSettings.priceData, {
        //     actualPrice: doppeDtoBookingServicePaymentConvertToActionFormattedPrice(doppeDtoService.payment),
        //     discountPrice: ''
        // }) : actionSettings.priceData,
        servicePageLink: stringIsNotNullableOrWhiteSpace(doppeDtoService.servicePageUrl) ?
            doppeDtoService.servicePageUrl : 'about:blank',
    }
}

export function convertDoppeWixBookingServiceToServiceSettings(service: DoppeDtoBookingService, bymoPageResource?: BymoPageResource) {

    const durations = service.scheduale ? doppeDtoBookingServiceScheduleConvertToActionFormattedDuration({
        schedule: service.scheduale,
        serviceType: service.type
    }) : null;

    const schedule = service.scheduale ? doppeDtoBookingServiceScheduleConvertToActionFormattedSchedule({
        schedule: service.scheduale,
        serviceType: service.type,
        bymoPageProps: bymoPageResource ? bymoPageResource.getBymoPageProps() : undefined
    }) : null;

    return {
        serviceImages: service.media,
        servicePageLink: service.servicePageUrl ?? '',
        priceData: service.payment ? {
            actualPrice: doppeDtoBookingServicePaymentConvertToActionFormattedPrice(service.payment),
            discountPrice: ''
        } : doppeHideableValueCreateHidden({
            actualPrice: '$25',
            discountPrice: ''
        }),
        serviceParticipants: service.capacity ? doppeDtoBookingServiceCapacityConvertToActionFormattedCapacity(service.capacity) : doppeHideableValueCreateHidden(''),
        serviceSchedule: schedule ?? doppeHideableValueCreateHidden(''),
        location: service.locations[0] ? doppeDtoBookingServiceLocationConvertToActionFormattedLocation(service.locations[0]) : doppeHideableValueCreateHidden(''),
        servicePeriod: durations ? durations[0] : doppeHideableValueCreateHidden(''),
        serviceTitle: service?.name ?? 'Service name',
        description: service.description,
        serviceProvider: DoppeExternalServiceProvider.Wix,
        serviceProviderId: service.id
    }
}