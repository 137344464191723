export enum AsyncValueStates {
    Resolved = 'resolved',
    Rejected = 'rejected',
    Pending = 'pending'
}

export interface IAsyncValueResolvedState<T> {
    state: AsyncValueStates.Resolved;
    value: T;
}

export interface IAsyncValuePendingState {
    state: AsyncValueStates.Pending;
    promise: Promise<any>
}

export interface IAsyncValueRejectedState<R> {
    state: AsyncValueStates.Rejected;
    reason?: R;
}

export type AsyncValueState<T, R = any> = IAsyncValueResolvedState<T> | IAsyncValuePendingState | IAsyncValueRejectedState<R>;

export function isAsyncValueState (value: unknown) : value is AsyncValueState<any> {
    const asyncValueState = (value as AsyncValueState<any>).state;

    return (
        asyncValueState === AsyncValueStates.Resolved ||
        asyncValueState === AsyncValueStates.Pending ||
        asyncValueState === AsyncValueStates.Rejected
    );
}

export function asyncValueGetResolvedValue<T, R>(
    asyncStateInfo: AsyncValueState<T>,
    valueSelector: (value: T) => R,
    defaultValue: R
) {
    return asyncStateInfo.state === AsyncValueStates.Resolved ? valueSelector(asyncStateInfo.value) : defaultValue
}

export function asyncValueIsResolved<T>(
    asyncStateInfo: AsyncValueState<T>
): asyncStateInfo is IAsyncValueResolvedState<T> {
    return asyncStateInfo.state === AsyncValueStates.Resolved;
}

export function asyncValueIsRejected<R = any>(
    asyncStateInfo: AsyncValueState<any, R>
): asyncStateInfo is IAsyncValueRejectedState<R> {
    return asyncStateInfo.state === AsyncValueStates.Rejected;
}

export function asyncValueIsPending(asyncStateInfo: AsyncValueState<any>): asyncStateInfo is IAsyncValuePendingState {
    return asyncStateInfo.state === AsyncValueStates.Pending;
}

export function asyncValueCreatePendingState(promise: Promise<any>): IAsyncValuePendingState {
    return {
        state: AsyncValueStates.Pending,
        promise: promise
    };
}

export function asyncValueCreateRejectedState<R>(reason?: any): IAsyncValueRejectedState<R> {
    return {
        state: AsyncValueStates.Rejected,
        reason: reason
    };
}

export function asyncValueCreateResolvedState<T>(value: T): IAsyncValueResolvedState<T> {
    return {
        state: AsyncValueStates.Resolved,
        value: value
    };
}


const AsyncOperationIdle = asyncValueCreateResolvedState<void>(undefined);

export const asyncOperationCreateIdleState = () => AsyncOperationIdle;
export const asyncOperationCreatePendingState = asyncValueCreatePendingState;
export const asyncOperationCreateRejectedState = asyncValueCreateRejectedState;

export type IAsyncOperationIdleState = IAsyncValueResolvedState<void>;
export type IAsyncOperationPendingState = IAsyncValuePendingState;
export type IAsyncOperationRejectedState<REJECT_VALUE> = IAsyncValueRejectedState<REJECT_VALUE>;
export type IAsyncOperationState<REJECT_VALUE = any> = IAsyncOperationIdleState | IAsyncOperationPendingState | IAsyncOperationRejectedState<REJECT_VALUE>

export function asyncOperationIsIdle (asyncOperationState: IAsyncOperationState)
    : asyncOperationState is IAsyncOperationIdleState
{
    return asyncOperationState.state === AsyncValueStates.Resolved;
}

export function asyncOperationIsPending(asyncOperationState: IAsyncOperationState)
    : asyncOperationState is IAsyncOperationPendingState
{
    return asyncOperationState.state === AsyncValueStates.Pending;
}

export function asyncOperationIsRejected<REJECT_VALUE = any>(asyncOperationState: IAsyncOperationState<REJECT_VALUE>)
    : asyncOperationState is IAsyncOperationRejectedState<REJECT_VALUE>
{
    return asyncOperationState.state === AsyncValueStates.Rejected;
}

export function asyncValueSwitch<VALUE, REJECT, RETURN> (
    asyncValue: AsyncValueState<VALUE, REJECT>,
    cases: {
        resolved: (value: VALUE) => RETURN;
        rejected: (reason: REJECT | undefined) => RETURN;
        pending: () => RETURN
    }
) : RETURN {
    switch (asyncValue.state) {
        case AsyncValueStates.Resolved:
            return cases.resolved(asyncValue.value);
        case AsyncValueStates.Rejected:
            return cases.rejected(asyncValue.reason);
        case AsyncValueStates.Pending:
            return cases.pending();
    }
}

export async function asyncValueAwait<V, R> (asyncValue: AsyncValueState<V, R>) : Promise<V> {
    switch (asyncValue.state) {
        case AsyncValueStates.Resolved:
            return asyncValue.value;
        case AsyncValueStates.Rejected:
            throw asyncValue.reason;
        case AsyncValueStates.Pending:
            return await asyncValue.promise;
    }
}

export function asyncValueGetResolvedValueOrDefault<V, D> (asyncValue: AsyncValueState<V>, defaultValue: D) {
    return asyncValue.state === AsyncValueStates.Resolved ? asyncValue.value : defaultValue;
}

export function asyncValueMapResolvedValueOrDefault<V, R, D> (
    asyncValue: AsyncValueState<V>,
    mappingFunction: (resolvedValue: V) => R,
    defaultValue: D
) {
    return asyncValue.state === AsyncValueStates.Resolved ? mappingFunction(asyncValue.value) : defaultValue;
}