import {WixAppRuntimeEnvironment} from "@wix/devzai-common-wix";

export class DoppeClientErrorsReportingService {

    async initialize (
        options: {
            environment: WixAppRuntimeEnvironment;
            appType: string;
            appVersion?: string;
        }
    ) {
        const {
            environment,
            appType,
            appVersion
        } = options;

        const sentry = await import('@sentry/browser');
        const appVersionSuffix = appVersion ? `@${appVersion}` : '';

        sentry.init({
            dsn: "https://4c0cc8d5132e4f4cb9fc806fcb739d9d@sentry-next.wixpress.com/1289",
            environment: environment,
            release: `${appType}${appVersionSuffix}`,
            autoSessionTracking: false,
            ...(environment === 'local' && {
                integrations: [
                    new sentry.Integrations.Breadcrumbs({
                        console: false,
                    }),
                ],
            }),
        });
    }
}
