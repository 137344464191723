import React, {useContext} from "react";
import {DoppeViewerLang, doppeViewerLangIsRtl} from "../../client-server-common/doppe-viewer/doppe-viewer-lang";
import {DoppeViewerLangDictionaryEn} from "./doppe-viewer-lang-dictionary.en";
import {DoppeViewerLangDictionaryHe} from "./doppe-viewer-lang-dictionary.he";
import {useBymoPageProps} from "../../client-server-common";

export type DoppeViewerLangDictionary = typeof DoppeViewerLangDictionaryEn;

export const DoppeViewerLangDictionaryContext = React.createContext<DoppeViewerLangDictionary | null>(null);

export function doppeViewerLangDictionaryCreate (doppeViewerLang: DoppeViewerLang) : DoppeViewerLangDictionary {
    switch (doppeViewerLang) {
        case DoppeViewerLang.Hebrew: return DoppeViewerLangDictionaryHe;
        default: return DoppeViewerLangDictionaryEn;
    }
}

export function useDoppeViewerLangDictionary () {
    return useContext(DoppeViewerLangDictionaryContext) ?? DoppeViewerLangDictionaryEn;
}

export function useDoppViewerIsRtl () {
    return doppeViewerLangIsRtl(useBymoPageProps().lang);
}